import React from 'react'
import useTableSorting from '../../Hooks/useTableSorting'
import useCategory from './Hooks/useCategory'
import { SelectBox } from '../../Component/Inputs'
import {  activeStatusOptionWithAll } from '../../Context/constants'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { IconButton, Table, TableBody, TableContainer, TableRow, TableCell } from '@mui/material'
import { FiRefreshCcw } from 'react-icons/fi'
import { NoRecordFound } from '../../Component/NoRecordFound'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import Loading from '../../Component/Loading'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from 'react-bootstrap'
import { Errors } from '../../Component/Error'
const TemplateCategory = () => {
    const {
        handleChangeFilter,
        HandleSearchList,
        handleOpenModal,
        handleClosedModal,
        setController,
        handleDeleteCategory,
        reset,
        handleToggleStatus,
        headCells,
        controller,
        Filter,
        TotalCount,
        categoryList,
        loader,
        openModal,
        formik,
    } = useCategory()
    const { CustomButton, GreenSwitch } = useMuiCompontent()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div>
                <header className="body_header d-flex justify-content-between align-items-center py-2 ">
                    <ul className="headerlist px-0">
                        <li className='phone_hide me-2'>
                            <input type="text" onChange={handleChangeFilter} value={Filter?.category} placeholder="Search" className="form-control filter_input" name="category" />
                        </li>
                        <li className="me-2" style={{ width: 150 }}>
                            <SelectBox
                                placeholder="Select"
                                value={Filter?.archive}
                                name="archive"
                                keys="value"
                                label="label"
                                menuValeu="value"
                                handleChange={handleChangeFilter}
                                option={activeStatusOptionWithAll}
                            />
                        </li>
                        <li className='phone_hide me-2'>
                            <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
                        </li>
                        <li className="me-2 d-flex justify-content-center align-items-center" >
                            <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                                <FiRefreshCcw />
                            </IconButton>
                        </li>
                        <li className='me-2'>
                            <CustomButton variant="contained" onClick={() => handleOpenModal("insertCategory")} >add category</CustomButton>
                        </li>
                    </ul>
                </header>
                <TableContainer   >
                    {categoryList.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                    <Table
                        id="table_mui"
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table" >
                        {
                            categoryList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={categoryList?.length} />
                        }
                        <TableBody className="table_overflow_scroll" >
                            {stableSort(categoryList, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={index}><TableRow id="hover_tr" style={{ cursor: 'pointer' }}>
                                        <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                            {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                        </TableCell>
                                        <TableCell id={labelId} align="left" >{row?.name}</TableCell>
                                        <TableCell id={labelId} align="right"  >
                                            <GreenSwitch checked={row?.activeStatus} size='small' onChange={(e) => handleToggleStatus(e, row)} />
                                        </TableCell>
                                        <TableCell id={labelId} align="right">
                                            <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu dropdown-menu-end me-3">
                                                <li><button className="dropdown-item" onClick={() => handleOpenModal("insertCategory",row)}><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Category</button></li>
                                                <li><button className="dropdown-item" onClick={() => handleOpenModal("deleteCategory", row)}>  <i title="Delete Category" className="fa-solid fa-trash-can all_icons_color me-2"></i>Delete Category</button></li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <nav className=" w-100  " aria-label="pagination">
                {TotalCount > 10 && <Peginationcomponent count={TotalCount} controller={controller} setController={setController} />}
            </nav>


            <Modal show={openModal?.insertCategory} onHide={() => { handleClosedModal("insertCategory") }} centered>
                <Modal.Body>
                    <div className="text-center">
                        <p className="modal-title mb-0"> {openModal.data ? "Update Category" : "Add Category"} </p>
                    </div>
                    <div className="modal-body  cus-i">
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Category<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-9  pe-0">
                                <input maxLength={50} type="text" name='category' className="form-control" id="exampleInputEmail1"
                                    value={formik?.values.category} onChange={(e) => formik.setFieldValue("category", e.target.value)} aria-describedby="emailHelp"
                                    onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                                />
                                <Errors formikfun={formik} name={"category"} />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-3 pe-1">
                        <CustomButton variant="contained" className='mui_button_color' onClick={formik.handleSubmit}>{openModal.data ? "update" : "Save"}</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openModal?.deleteCategory} onHide={() => handleClosedModal("deleteCategory")} centered>
                <Modal.Body className='px-0'>
                    <div className="modal-body text-center cus-i px-0">
                        <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
                        <p className='mb-0'>Are you sure?</p>
                        <p>You want to delete this category</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleDeleteCategory("YES")} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleDeleteCategory("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TemplateCategory
