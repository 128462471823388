import React, { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useReduxData from '../../Hooks/useReduxData'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { CreateCategoryValidation, importVaccinationformValidation } from '../../Context/Validation'
import { Errors } from '../../Component/Error'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import Loading from '../../Component/Loading'
import { NoRecordFound } from '../../Component/NoRecordFound'
import { activeStatusOption, dailogAllMessage, RoleTitle } from '../../Context/constants'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import useTableSorting from '../../Hooks/useTableSorting'
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { SearchAbleSelectBox, SelectBox } from '../../Component/Inputs'
import { FiRefreshCcw } from 'react-icons/fi'
import { EDIT_VACCINATION_CATEGORY, IMPORT_VACCINATION_FORM, INSERT_VACCINATION_CATEGORY, VACCINATION_CATEGORY_LIST, VACCINATION_FORM_LIST } from '../Repository/VaccinationRepo'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { BiArchiveIn } from 'react-icons/bi'
const VaccinationCategory = () => {
    const [loader, setLoader] = useState(true)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [importVaccinationList, setImportVaccinationList] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [vaccinationCategoryList, setVaccinationCategoryList] = useState([])
    const [Filter, setFilter] = useState({
        archive: true,
        country: "",
        category: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        insertCategory: false,
        archive: false,
        importform: false,
        data: null
    })
    const navigate = useNavigate()
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "vaccinationCategoryName", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

    // vaccination category list
    async function getCategoryList() {
        setLoader(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, category } = Filter;
        const keyword = category.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        };
        try {
            let response = await VACCINATION_CATEGORY_LIST(payload)
            setVaccinationCategoryList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            if (error.message !== "No vaccination category found") toast.error(error.message)
            setVaccinationCategoryList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }
    }
    /////////////////////////// list vaccination form list //////////////////////////
    async function getImportVaccinationFormList() {
        const payload = {
            activeStatus: true,
            providerId: 1,
            publishStatus: true
        };
        setLoader(true)
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            setImportVaccinationList(response?.data)
            setLoader(false)
        } catch (error) {
            if (error.message !== "No vaccination form found") toast.error(error.message)
            setImportVaccinationList([])
            setLoader(false)
        }
    }
    //////////////////////////// insert category /////////////////////////////
    const formik = useFormik({
        initialValues: {
            category: ""
        },
        validationSchema: CreateCategoryValidation,
        onSubmit: async (values) => {
            setLoader(true)
            let payload = {
                vaccinationCategoryName: values?.category,
                providerId: openModal?.data ? undefined : (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: openModal?.data ? undefined : userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                vaccinationCategoryId: openModal?.data ? openModal?.data?.vaccinationCategoryId : undefined
            }
            try {
                let response = openModal?.data ? await EDIT_VACCINATION_CATEGORY(payload) : await INSERT_VACCINATION_CATEGORY(payload)
                toast.success(response.message)
                setLoader(false)
                getCategoryList()
                handleClosedModal("insertCategory")
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    // get vaccination category by id
    const getVaccinationCategoryDetails = async (data) => {
        const payload = {
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            activeStatus: data?.activeStatus,
            vaccinationCategoryId: data?.vaccinationCategoryId
        };
        setLoader(true)
        try {
            let response = await VACCINATION_CATEGORY_LIST(payload)
            formik.setValues(response?.data)
            formik.setFieldValue("category", response?.data?.vaccinationCategoryName)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    ////////////////////////////// archive/unarchive //////////////////////////
    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                activeStatus: !data.activeStatus,
                vaccinationCategoryId: data?.vaccinationCategoryId
            }
            try {
                let response = await EDIT_VACCINATION_CATEGORY(payload)
                toast.success(response.message)
                setLoader(false)
                if (vaccinationCategoryList?.length === 1 && controller.page === 1) {
                    getCategoryList()
                }
                else  if (vaccinationCategoryList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getCategoryList()
                handleClosedModal("archive")
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
        else {
            handleClosedModal("archive")
        }
    }
    const ImportVaccinationFormFun = useFormik({
        initialValues: {
            form: []
        },
        validationSchema: importVaccinationformValidation,
        onSubmit: async (values, { resetForm }) => {
            let payload = {
                roleTitle: userdata?.roleTitle,
                providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                formDetails: values?.form
            }
            setLoader(true)
            try {
                let response = await IMPORT_VACCINATION_FORM(payload)
                toast.success(response.message)
                resetForm()
                getCategoryList()
                handleClosedModal("importform")
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }

        }
    })
    const handleOpenModal = (type, data = null) => {
        if (type === "insertCategory" && data) {
            getVaccinationCategoryDetails(data)
        }
        if (type === "importform") {
            getImportVaccinationFormList()
        }
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        if (type === "insertCategory") {
            formik.resetForm()
        }
        if (type === "importform") {
            ImportVaccinationFormFun.resetForm()
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        ImportVaccinationFormFun.setFieldValue(name, newValue);
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, category: "", disable_button: true })
    }

    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }

    useEffect(() => {
        getCategoryList()
        navigate({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-between align-items-center w-100 ">
                        <h2 className='page_name'>Vaccination Category </h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0 shadow px-2 pt-3 d-flex justify-content-between flex-column" >
                    <div>
                        <header className="body_header d-flex justify-content-between align-items-center py-2 ps-2">
                            <ul className="headerlist px-0">
                                <li className='phone_hide me-2'>
                                    <input type="text" onChange={handleChangeFilter} value={Filter?.category} placeholder="Search" className="form-control filter_input" name="category" />
                                </li>
                                <li className="me-2" style={{ width: 150 }}>
                                    <SelectBox
                                        placeholder="Select"
                                        value={Filter?.archive}
                                        name="archive"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={handleChangeFilter}
                                        option={activeStatusOption}
                                    />
                                </li>
                                <li className='phone_hide me-2'>
                                    <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
                                </li>
                                <li className="me-2 d-flex justify-content-center align-items-center" >
                                    <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                                        <FiRefreshCcw />
                                    </IconButton>
                                </li>
                                <li className='me-2'>
                                    <CustomButton variant="contained" onClick={() => handleOpenModal("insertCategory")} >add category</CustomButton>
                                </li>
                            </ul>
                            <ul className="headerlist px-0">
                                <li className='me-2'>
                                    <CustomButton variant="contained" onClick={() => handleOpenModal('importform')} >import Form</CustomButton>
                                </li>
                                {/* <li className='phone_hide'>
                                    <CustomButton variant="contained"
                                        style={{ background: "#f5f5f5", color: "black" }}
                                        // onClick={() => { setFiltershow(!filtershow) }}
                                        startIcon={<ExitToAppIcon />}
                                    >export</CustomButton>
                                </li> */}
                            </ul>
                        </header>

                        <TableContainer   >
                            {vaccinationCategoryList.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                            <Table
                                id="table_mui"
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table" >
                                {
                                    vaccinationCategoryList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={vaccinationCategoryList?.length} />
                                }
                                <TableBody className="table_overflow_scroll" >
                                    {stableSort(vaccinationCategoryList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow id="hover_tr" style={{ cursor: 'pointer' }}>
                                                <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                    {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{row?.vaccinationCategoryName}</TableCell>
                                                <TableCell id={labelId} align="right"  >
                                                    <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu dropdown-menu-end me-3">
                                                        <li><button className={`dropdown-item`} onClick={() => handleOpenModal("insertCategory", row)}><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Vaccination Category</button></li>
                                                        <li><button className="dropdown-item"
                                                            onClick={() => handleOpenModal("archive", row)}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Vaccination Category`}</button>
                                                        </li>
                                                        <li><button className="dropdown-item"
                                                            onClick={() => navigate(`${row?.vaccinationCategoryId}`)}><i className="fa-regular fa-calendar all_icons_color me-2"></i> Vaccination Calendar</button>
                                                        </li>
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <nav className=" w-100  " aria-label="pagination">
                        {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
                    </nav>
                </div>
            </div>
            {/* Add or edit Category  */}
            <Modal show={openModal?.insertCategory} onHide={() => { handleClosedModal("insertCategory") }} centered>
                <Modal.Body>
                    <div className="text-center">
                        <p className="modal-title mb-0"> {openModal.data ? "Update Category" : "Add Category"} </p>
                    </div>
                    <div className="modal-body  cus-i">
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Category<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-9 px-0">
                                <input maxLength={50} type="text" name='category' className="form-control" id="exampleInputEmail1"
                                    value={formik?.values.category} onChange={(e) => formik.setFieldValue("category", e.target.value)} aria-describedby="emailHelp"
                                    onInput={(e) => { if (e.target.value.length > 50) e.target.value = e.target.value.slice(0, 50); }}
                                />
                                <Errors formikfun={formik} name={"category"} />
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-3 pe-1">
                        <CustomButton variant="contained" className='mui_button_color' onClick={formik.handleSubmit}>{openModal.data ? "update" : "Save"}</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.archive} onHide={() => handleClosedModal("archive")} centered>
                <Modal.Body>
                    <div className="modal-body text-center">
                        <BiArchiveIn className="all_react_icons_color " />
                        <p className="mb-0">Are you sure? </p>
                        <p>{openModal?.data?.activeStatus ? dailogAllMessage?.vaccinationCategory?.Archive : dailogAllMessage?.vaccinationCategory?.UnArchive}</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES", openModal?.data)} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.importform} onHide={() => handleClosedModal("importform")} centered>
                <Modal.Body>
                    <div className="modal-body ">
                        <h6 className="mb-0 text-center">Import Vaccination Form </h6>
                        <div className="col-sm-12  py-4 " >
                            <div className="row">
                                <div className="col-4">
                                    <label className="form-label mb-0">Vaccination Form<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-8">
                                    <SearchAbleSelectBox
                                        multiple={false}
                                        option={importVaccinationList}
                                        getOptionLabel="formName"
                                        isOptionEqualToValue="vaccinationFormId"
                                        value={ImportVaccinationFormFun?.values?.form}
                                        name="form"
                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                    />
                                    <Errors formikfun={ImportVaccinationFormFun} name={"form"} />
                                </div>
                            </div>
                        </div>
                        <div className='text-end'>
                            <CustomButton variant="outlined" className="me-3" onClick={() => handleClosedModal("importform")} > Cancel</CustomButton>
                            <CustomButton variant="contained" onClick={ImportVaccinationFormFun.handleSubmit} >save</CustomButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default VaccinationCategory
