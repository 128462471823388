import React, { useEffect } from "react";
import "moment-timezone";
import "rc-time-picker/assets/index.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const TemplateLibrary = () => {
  const navigate = useNavigate()
  const {pathname}=useLocation()
  const splitLocation=pathname?.split("/")
  useEffect(() => {
    document.title = "Template Library"
  }, [])
  return (
    <>

      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="page_name">Template Library</h2>
          </div>
        </header>
        <div className="row common-white-bg-box mx-0 shadow">
          <div className="col-sm-12" style={{ height: "100%" }}>
            <div className="d-flex justify-content-between">
              <ul className="nav  mb-0  " >
                <li className="nav-item" role="presentation">
                  <button className={`teams_frofile_stepper_btn me-2 ${splitLocation[2] === "template-list" && "active_teams_frofile"}`} id="personal" type="button" onClick={() => navigate("template-list")} >Template</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`teams_frofile_stepper_btn ${splitLocation[2] === "template-category" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("template-category")} >Category</button>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateLibrary;

