/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import useReduxData from '../../../Hooks/useReduxData';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { addNewAllergiesValidation, addNewChronicDiseaseValidation, addNewSymptomsValidation, addNewTestsValidation } from '../../../Context/Validation';
import useMinimumDate from '../../../Hooks/useMinimumDate';
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { INSERT_CHECKOUT_APPOINTMENT, INSERT_SETUP, SETUP_LIST } from '../../Repository/AppointmentsRepo';
import { LIFESTYLE_LIST } from '../../Repository/SettingRepo';
import { GET_EPRESCRIPTION_DETAILS } from '../../Repository/PatientRepo';
import { RoleTitle } from '../../../Context/constants';
const useCheckOut = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});
  const [symptonData, setSymptonData] = useState([])
  const [chronicData, setChronicData] = useState([])
  const [testRequestData, setTestRequestData] = useState([])
  const [listSelectedMedicines, setListSelectedMedicines] = useState([])
  const [allergiesList, setAllergiesList] = useState([])
  const [LifeStyleList, setLifeStyleList] = useState([])
  const [Type] = useState({
    ALLERGY: "ALLERGY",
    DISEASE: "DISEASE",
    SYMPTOM: "SYMPTOM",
    TESTL: "TEST"
  })
  const [loader, setLoader] = useState(false)
  const [modal, setModal] = useState({ Symptoms: false, ChronicDisease: false, Tests: false, Allergies: false })
  const [EPrescriptionViewModal, setEPrescriptionViewModal] = useState({
    open: false,
    data: null
  })
  const { reduxData: userdata } = useReduxData("userDataRDX")
  const { reduxData: AgendaDetails } = useReduxData("AgendaDetailsRDX")
  const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
  const { minimumdate } = useMinimumDate()
  const { CustomButton } = useMuiCompontent()
  const { id } = useParams()
  let dateTime = moment().utc()
  const navigate = useNavigate()
  const headCells = [
    // { id: "Patient Name", numeric: true, disablePadding: false, label: "Patient Name" },
    // { id: "Age", numeric: true, disablePadding: false, label: "Age", showIcon: true },
    // { id: "Gender", numeric: true, disablePadding: false, label: "Gender" },
    { id: "Prescription Number", numeric: true, disablePadding: false, label: "Prescription Number", showIcon: true },
    { id: "date", numeric: true, disablePadding: false, label: "Date", showIcon: true },
    { id: "Medicine", numeric: true, disablePadding: false, label: "Medicine", showIcon: true },
    { id: "medicineType", numeric: true, disablePadding: false, label: "Medicine type", showIcon: true },
    { id: "Dose", numeric: true, disablePadding: false, label: "Standard", showIcon: true },
    { id: "Strength", numeric: true, disablePadding: false, label: "Strength", showIcon: true },
    { id: "Duration", numeric: true, disablePadding: false, label: "Duration", showIcon: true },
    { id: "medicineIntakeTime", numeric: true, disablePadding: false, label: "Dosage Instructions", showIcon: true },
    { id: "Qty", numeric: true, disablePadding: false, label: "Qty", showIcon: true },
    { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
  ];
  const steps = ['Vitals', 'Others', 'RX (E-Prescription)', 'Follow Up',];
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
          '#33B0A7',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
          '#33B0A7',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ededed',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      background:
        '#33B0A7',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      background:
        '#33B0A7',
    }),
  }));
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
      1: <SettingsIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
      4: <DateRangeIcon />
    };
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  useEffect(() => {
    if (activeStep === 1) {

      getSetupList(Type?.ALLERGY)
      getSetupList(Type?.DISEASE)
      getSetupList(Type?.SYMPTOM)
      getSetupList(Type?.TESTL)
    }
    if (activeStep === 2) {
      getListSelectedMedicines()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])
  useEffect(() => {
    getLifeStyleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //List Life Style
  const getLifeStyleList = async () => {
    setLoader(true)
    const payload = {
      activeStatus: true,
      userId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
    };
    try {
      let response = await LIFESTYLE_LIST(payload)
      let LifeStylearray = []
      response.data?.forEach((cur) => {
        LifeStylearray.push({
          [`habit_${cur?.lifeStyleId}`]: true,
          [`pastHabit_${cur?.lifeStyleId}`]: true,
          record: "",
          consumption: "",
          TimeFrame: "days",
          ...cur
        })
      })
      setLifeStyleList(LifeStylearray)
    } catch (error) {
      toast.error(error.message)
      setLifeStyleList([])
    }
    finally {
      setLoader(false)
    }
  }
  async function getSetupList(type) {
    const payload = {
      activeStatus: true,
      providerId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.providerId : userdata?.userId,
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      type,
    };
    try {
      let response = await SETUP_LIST(payload)
      if (type === Type?.ALLERGY) setAllergiesList(response?.data)
      if (type === Type?.DISEASE) setChronicData(response?.data)
      if (type === Type?.SYMPTOM) setSymptonData(response?.data)
      if (type === Type?.TESTL) setTestRequestData(response?.data)
    } catch (error) {
      // toast.error(error.message)
      if (type === Type?.ALLERGY) setAllergiesList([])
      if (type === Type?.DISEASE) setChronicData([])
      if (type === Type?.SYMPTOM) setSymptonData([])
      if (type === Type?.TESTL) setTestRequestData([])
    }
  }
  const getListSelectedMedicines = async () => {
    let payload = {
      // prescriptionNumber: AgendaDetails?.prescriptionNumber,
      appointmentId: AgendaDetails?.apptId,
    }
    try {
      let response = await GET_EPRESCRIPTION_DETAILS(payload)
      setListSelectedMedicines(response.data)
      setLoader(false)
    } catch (error) {
      setListSelectedMedicines([])
      setLoader(false)
    }
  }
  const addVitals = useFormik({
    initialValues: {
      bp: '',
      pulse: '',
      weight: '',
      height: '',
      temperature: ''
    },
    validationSchema: "",
    onSubmit: (values) => {
    }
  })
  const handleChangeLifeStyle = (e, index, checked, data) => {
    const { value, name, type } = e?.target
    const tempLifeStyleList = [...LifeStyleList]
    if (type === "textarea" || type === "select-one" || type === "number") {
      if ("consumption" === name && value < 0) {
        tempLifeStyleList[index][name] = 0
      } else {
        tempLifeStyleList[index][name] = value
      }

    } else {
      tempLifeStyleList[index][name] = checked
      if (data?.[`habit_${data?.lifeStyleId}`] == true) {
        tempLifeStyleList[index][`pastHabit_${data?.lifeStyleId}`] = true
        tempLifeStyleList[index][`record`] = ""
      } else {
        tempLifeStyleList[index][`consumption`] = ""
        tempLifeStyleList[index][`TimeFrame`] = "days"
        tempLifeStyleList[index][`record`] = ""
      }
      if (data?.[`pastHabit_${data?.lifeStyleId}`] == false) {
        tempLifeStyleList[index][`record`] = ""
      }

    }
    setLifeStyleList(tempLifeStyleList)
  }
  const LifeStyleTimeFrame = (TimeFrame, index) => {
    const tempLifeStyleList = [...LifeStyleList]
    tempLifeStyleList[index][`TimeFrame`] = TimeFrame
    setLifeStyleList(tempLifeStyleList)
  }
  const AddAllergies = useFormik({
    initialValues: {
      Allergies: [],
    },
  })
  const addSymptoms = useFormik({
    initialValues: {
      symptoms: [],
    },
  })
  const addChronicDisease = useFormik({
    initialValues: {
      chronicDisease: [],
    },
  })
  const addTestsRequested = useFormik({
    initialValues: {
      tests: [],
    },
  })
  const addFollowUpDate = useFormik({
    initialValues: {
      dateTime: "",
    },
  })
  const addDiagnosis = useFormik({
    initialValues: {
      diagnosis: "",
    },
  })
  const LifeStyleData = (iteam) => {

    return iteam?.map((cur, index) => {
      return {
        lifeStyleName: cur?.lifeStyleName,
        habit: cur?.[`habit_${cur?.lifeStyleId}`],
        pastHabit: cur?.[`pastHabit_${cur?.lifeStyleId}`],
        consumption: cur?.consumption,
        record: cur?.record
      }
    })

  }
  const saveAllData = async () => {
    const data = {
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      roleTitle: userdata?.roleTitle,
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      patientId: id,
      appointmentId: AgendaDetails?.apptId,
      dateTime: dateTime,
      bp: addVitals?.values?.bp,
      pulse: addVitals?.values?.pulse,
      weight: addVitals?.values?.weight,
      weightType: "KG",
      height: addVitals?.values?.height,
      heightType: "CM",
      temperature: addVitals?.values?.temperature,
      temperatureType: "F",
      diagnosis: addDiagnosis?.values?.diagnosis,
      followUpDate: addFollowUpDate?.values?.dateTime,
      lifestyles: LifeStyleData(LifeStyleList),
      allergies: AddAllergies?.values?.Allergies?.map((cur) => cur?.name),
      symptoms: addSymptoms?.values?.symptoms?.map((cur, index) => cur?.name),
      chronicDisease: addChronicDisease?.values?.chronicDisease?.map((cur, index) => cur?.name),
      tests: addTestsRequested?.values?.tests?.map((cur, index) => cur?.name),
    }
    setLoader(true)
    try {
      let response = await INSERT_CHECKOUT_APPOINTMENT(data)
      toast.success(response.message)
      navigate("/appointment/today-agenda")
    } catch (error) {
      toast.error(error.message)
    }
    finally {
      setLoader(false)
    }

  }
  const newAddSymptoms = useFormik({
    initialValues: {
      Symptoms: ""
    },
    validationSchema: addNewSymptomsValidation,
    onSubmit: (values) => {
      newAdd_Symptoms_Chronic_TestsFun(values?.Symptoms, Type?.SYMPTOM, "Symptoms")
      newAddSymptoms.resetForm()
    }
  })
  const newAddChronicDisease = useFormik({
    initialValues: {
      ChronicDisease: ""
    },
    validationSchema: addNewChronicDiseaseValidation,
    onSubmit: (values) => {
      newAdd_Symptoms_Chronic_TestsFun(values?.ChronicDisease, Type?.DISEASE, "ChronicDisease")
      newAddChronicDisease.resetForm()
    }
  })
  const newAddTestsRequested = useFormik({
    initialValues: {
      Tests: ""
    },
    validationSchema: addNewTestsValidation,
    onSubmit: (values) => {
      newAdd_Symptoms_Chronic_TestsFun(values?.Tests, Type.TESTL, "Tests")
      newAddTestsRequested.resetForm()
    }
  })
  const newAddallergies = useFormik({
    initialValues: {
      allergies: ""
    },
    validationSchema: addNewAllergiesValidation,
    onSubmit: (values) => {
      newAdd_Symptoms_Chronic_TestsFun(values?.allergies, Type.ALLERGY, "Allergies")
      newAddallergies.resetForm()
    }
  })
  const newAdd_Symptoms_Chronic_TestsFun = async (value, type, modalKey) => {
    setLoader(true)
    let payload = {
      name: value,
      type,
      providerId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.providerId : userdata?.userId,
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
    }
    try {
      let response = await INSERT_SETUP(payload)
      toast.success(response.message)
      setModal((pre) => ({ ...pre, [modalKey]: false }))
      if (type === Type?.SYMPTOM) getSetupList(Type?.SYMPTOM)
      if (type === Type?.DISEASE) getSetupList(Type?.DISEASE)
      if (type === Type?.TESTL) getSetupList(Type?.TESTL)
      if (type === Type?.ALLERGY) getSetupList(Type?.ALLERGY)
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }
  const openModalFun = (type) => {
    setModal((pre) => ({ ...pre, [type]: true }))
  }
  const handalSearchAbleSelectBox = (event, newValue, name) => {
    if (name === "Allergies") AddAllergies.setFieldValue(name, newValue);
    if (name === "symptoms") addSymptoms.setFieldValue(name, newValue);
    if (name === "chronicDisease") addChronicDisease.setFieldValue(name, newValue);
    if (name === "tests") addTestsRequested.setFieldValue(name, newValue);
  }
  return {
    handleBack,
    handleNext,
    handleChangeLifeStyle,
    LifeStyleTimeFrame,
    openModalFun,
    setModal,
    saveAllData,
    handalSearchAbleSelectBox,
    handleStep,
    ColorlibStepIcon,
    navigate,
    setEPrescriptionViewModal,
    userdata,
    EPrescriptionViewModal,
    CustomButton,
    loader,
    activeStep,
    addVitals,
    LifeStyleList,
    AddAllergies,
    allergiesList,
    addSymptoms,
    symptonData,
    addChronicDisease,
    chronicData,
    listSelectedMedicines,
    addTestsRequested,
    testRequestData,
    modal,
    newAddChronicDisease,
    addDiagnosis,
    minimumdate,
    addFollowUpDate,
    newAddSymptoms,
    newAddTestsRequested,
    newAddallergies,
    headCells,
    completed,
    ColorlibConnector,
    steps,
    isFrontDeskPermission,
  }
}

export default useCheckOut
