import { useEffect, useState } from "react";
import { commonMessages } from "../../../Context/constants";
import { toast } from "react-toastify";
import { listoffers } from "../../../Context/api";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../Context/CraeteAxios";
import useReduxData from "../../../Hooks/useReduxData";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";

const useOffersList = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [offerListData, setOffersListData] = useState([])
    const [active, setactive] = useState("")
    const [loader, setLoader] = useState(true)
    const [paginationTotalCount, setPaginationTotalCount] = useState(null)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [searchvalue, setSerchVlaue] = useState('')
    const [sortdata, setSort] = useState({ active: false, inActive: false })
    const [disable, setdisable] = useState(true)
    const [action, setAction] = useState(false)
    const navigate = useNavigate()
    const openMenu = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    useEffect(() => {
        getListOffers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, controller, action])
    useEffect(() => {
        document.title = "Offers"
    }, [])
    // filter list
    const sortlist = (sort) => {
        let sortnewObject = { ...sortdata }
        switch (sort) {
            case "active":
                sortnewObject.active = !sortnewObject.active
                if (sortnewObject.active === true) {
                    setactive(true)
                }
                if (sortnewObject.active === true && sortnewObject.inActive === true) {
                    setactive("")
                }
                if (sortnewObject.active === false && sortnewObject.inActive === false) {
                    setactive("")
                }
                if (sortnewObject.active === true && sortnewObject.inActive === false) {
                    setactive(true)
                }
                if (sortnewObject.active === false && sortnewObject.inActive === true) {
                    setactive(false)
                }
                break;
            case "inactive":
                sortnewObject.inActive = !sortnewObject.inActive
                if (sortnewObject.inActive === true) {
                    setactive(false)
                }
                if (sortnewObject.active === false && sortnewObject.inActive === false) {
                    setactive("")
                }
                if (sortnewObject.active === true && sortnewObject.inActive === true) {
                    setactive("")
                }
                if (sortnewObject.active === true && sortnewObject.inActive === false) {
                    setactive(true)
                }
                break;

            default:
                break;
        }
        setSort({ ...sortnewObject })
    }
    //get offers list 
    const getListOffers = () => {
        let data = {
            userId: userdata.userId,
            active: active,
            page: controller?.page,
            keyword: searchvalue,
            count: controller.rowsPerPage
        }
        setLoader(true)
        axiosInstance.post(listoffers(), data).then((res) => {
            if (res.data.status === "200") {
                setOffersListData(res.data.data)
                setPaginationTotalCount(res.data?.totalCount)
            } else if (res.data.status === "500") {
                toast.error(commonMessages.NetworkError);
            } else {
                setOffersListData([])
            }
            setLoader(false)
        }).catch(() => {
            toast.error(commonMessages.NetworkError)
            setLoader(false)
            setOffersListData([])
        })
    }
    // search box 
    const handlSearchbox = (value) => {
        setSerchVlaue(value)
        setdisable(false)
    }
    const search = () => {
        getListOffers()
    }
    const reset = () => {
        setdisable(true)
        setAction((pre) => !pre)
        setSerchVlaue("")
        setactive("")
    }
    const headCells = [
        { id: "Name", numeric: false, disablePadding: false, label: "Name", showLeft: false },
        { id: "Coupon Code", numeric: false, disablePadding: false, label: "Coupon Code", showLeft: false, showIcon: true },
        { id: "Discount Type", numeric: false, disablePadding: false, label: "Discount Type", showLeft: false },
        { id: "Valid From", numeric: false, disablePadding: false, label: "Valid From", showLeft: false },
        { id: "Valid From", numeric: false, disablePadding: false, label: "Valid From", showLeft: false },
        { id: "Status", numeric: false, disablePadding: false, label: "Status", showLeft: true, padding: true, showIcon: true },
        { id: "Action", numeric: false, disablePadding: false, label: "Action", showLeft: true, padding: true, showIcon: true },
    ];
    return {
        anchorEl,
        openMenu,
        ITEM_HEIGHT,
        setAnchorEl,
        sortlist,
        handlSearchbox,
        setController,
        navigate,
        CustomButton,
        sortdata,
        offerListData,
        loader,
        paginationTotalCount,
        userdata,
        controller,
        searchvalue,
        disable, search, reset, headCells
    }
}

export default useOffersList
