import { Fab } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from "react-icons/fa";
import moment from 'moment-timezone';
import useReduxData from '../../../Hooks/useReduxData';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ChatAttachmentFilePath } from '../../../Context/api';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { RxCross2 } from 'react-icons/rx';
import { useDownloadImage } from '../../../Hooks/useDownloadImage';
import { AiOutlineFilePdf } from "react-icons/ai";

const ChatArea = ({ messageList, userInfo, Tab, handleDeleteMessage, setEditMessage, setSendMessage, editMessage, setAttchament }) => {
    const chatAreaRef = useRef(null);
    // const [currentDate, setCurrentDate] = useState('');
    const [showScrollDownIcon, setShowScrollDownIcon] = useState(false);
    const { reduxData: UserData } = useReduxData("userDataRDX");
    const { downloadImage } = useDownloadImage()
    const scrollToBottom = () => {
        if (chatAreaRef.current) {
            chatAreaRef.current.scrollTo({
                top: chatAreaRef.current.scrollHeight,
            });
            setShowScrollDownIcon(false);
        }
    };

    const checkScrollPosition = () => {
        if (chatAreaRef.current) {
            const threshold = 100;
            if (chatAreaRef.current.scrollHeight - chatAreaRef.current.scrollTop > chatAreaRef.current.clientHeight + threshold) {
                setShowScrollDownIcon(true);
            } else {
                setShowScrollDownIcon(false);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (chatAreaRef.current) {
                const scrollTop = chatAreaRef.current.scrollTop;
                const clientHeight = chatAreaRef.current.clientHeight;

                for (let i = messageList?.messagesData?.length - 1; i >= 0; i--) {
                    // const message = messageList?.messagesData?.[i];
                    const messageElement = document.getElementById(`message-${i}`);
                    if (messageElement && messageElement.offsetTop < scrollTop + clientHeight) {
                        // setCurrentDate(message?.messageTime);
                        break;
                    }
                }
            }
        };
        const chatContainer = chatAreaRef.current;
        if (chatContainer) {
            chatContainer.addEventListener('scroll', checkScrollPosition);
            chatContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chatContainer) {
                chatContainer.removeEventListener('scroll', checkScrollPosition);
                chatContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [messageList]);

    useEffect(() => {
        scrollToBottom();
    }, [messageList, userInfo]);

    return (
        <>
            {userInfo ? (
                <div className='CHATAREA position-relative px-3' ref={chatAreaRef}>
                    <div className='position-sticky text-center pt-3' style={{ top: 0, background: "#fff", zIndex: 1000 }}>
                        {/* {currentDate ? <h4>{moment(currentDate).calendar(null, { sameDay: '[Today]' })}</h4> : ''} */}
                    </div>
                    <div>
                        {messageList?.messagesData?.map((cur, index) => {
                            const currentMessageDate = moment(cur?.messageTime).format('YYYY-MM-DD');
                            const previousMessageDate = index > 0 ? moment(messageList?.messagesData[index - 1]?.messageTime).format('YYYY-MM-DD') : null;
                            const showDateSeparator = currentMessageDate !== previousMessageDate;

                            return (
                                <React.Fragment key={index}>
                                    {showDateSeparator && (
                                        <div className='text-center my-2'>
                                            <span className='DATE_SEPARATOR'>{moment(currentMessageDate).calendar(null, { sameDay: '[Today]', })}</span>
                                        </div>
                                    )}
                                    <div id={`message-${index}`} className={`MESSAGE_CONTAINER d-flex justify-content-${UserData?.userId === cur?.senderId ? "end" : 'start'} mb-2`}>
                                        <div className={`${UserData?.userId === cur?.senderId ? "SEND" : 'RECEIVE'} w-50`}>
                                            <div className='MESSAGE'>
                                                {cur?.messageType === "TEXT" ? (
                                                    <>
                                                        {Tab === "GROUP" && UserData?.userId !== cur?.senderId && (
                                                            <p className='m-0' style={{ color: "#38C4B9" }}>
                                                                {(cur?.userData?.firstName ? cur?.userData?.firstName : "") + " " + (cur?.userData?.lastName ? cur?.userData?.lastName : "")}
                                                            </p>
                                                        )}
                                                        <p className='m-0'>{cur?.message}</p>

                                                    </>
                                                ) : (
                                                    <div>
                                                        <>
                                                            {Tab === "GROUP" && UserData?.userId !== cur?.senderId && (
                                                                <p className='m-0' style={{ color: "#38C4B9" }}>
                                                                    {(cur?.userData?.firstName ? cur?.userData?.firstName : "") + " " + (cur?.userData?.lastName ? cur?.userData?.lastName : "")}
                                                                </p>
                                                            )}
                                                            {/* {
                                                                !cur?.deletedStatus &&
                                                                <p className='m-0'>
                                                                    {cur?.document}
                                                                    <FaCloudDownloadAlt onClick={() => downloadImage(ChatAttachmentFilePath + cur?.document, cur?.document)} className='ms-2 cursor' />
                                                                </p>
                                                            } */}
                                                        </>
                                                        {
                                                            !cur?.deletedStatus && cur?.document && ['jpg', 'jpeg', 'png'].includes(cur?.document?.split(".").pop()) && <div className='CHAT_UPLOAD_IMAGE_BOX'>
                                                                <img src={ChatAttachmentFilePath + cur?.document} alt="" />
                                                                <span className='DOWNLOAD_FILE'> <FaCloudDownloadAlt style={{ fontSize: "30px" }} onClick={() => downloadImage(ChatAttachmentFilePath + cur?.document, cur?.document)} className='ms-2 cursor' /></span>
                                                            </div>
                                                        }
                                                        {
                                                            !cur?.deletedStatus && cur?.document && ['pdf'].includes(cur?.document?.split(".").pop()) && <div><p className='m-0'>
                                                                <AiOutlineFilePdf style={{ fontSize: "20px" }} />
                                                                {cur?.document}
                                                                <FaCloudDownloadAlt onClick={() => downloadImage(ChatAttachmentFilePath + cur?.document, cur?.document)} className='ms-2 cursor' />
                                                            </p></div>
                                                        }

                                                        <p className='m-0'>{cur?.message}</p>

                                                    </div>
                                                )}

                                                {UserData?.userId === cur?.senderId && !cur?.deletedStatus && (
                                                    <div className="dropdown DROUPDOUN_ICON">
                                                        <MoreHorizIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            {
                                                                !cur?.editedStatus && <li><button className="dropdown-item"
                                                                    onClick={() => {
                                                                        setEditMessage(cur);
                                                                        setSendMessage(cur?.message ? cur?.message : "")
                                                                        setAttchament(cur?.messageType === "DOCUMENT" ? cur?.document : null)
                                                                    }} >Edit</button></li>
                                                            }


                                                            <li><button className="dropdown-item" onClick={() => {
                                                                handleDeleteMessage(messageList?.conversationId, cur?.messageId)
                                                            }}>Delete</button></li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {
                                                    editMessage && editMessage?.messageId === cur?.messageId ? <div className='EDIT_MESSAGE'>
                                                        <RxCross2 className='CROSS_ICONS' onClick={() => { setEditMessage(null); setSendMessage(""); setAttchament(null) }} />
                                                    </div> : null
                                                }

                                            </div>
                                            {
                                                !cur?.deletedStatus && cur?.editedStatus && <p className='m-0 text' style={{ fontSize: "12px" }}><i>Edited</i></p>
                                            }
                                            <p className='m-0'>{cur?.messageTime ? moment(cur?.messageTime).format('hh:mm A') : ""}</p>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {showScrollDownIcon && (
                        <div id="scrollDownIcon" className='position-sticky w-100 text-end' style={{ bottom: "20px", right: '10px' }} onClick={scrollToBottom}>
                            <Fab size="small" style={{ background: "#38C4B9", color: "#fff", width: "35px", height: "35px", minHeight: "0px" }}>
                                <FaChevronDown style={{ fontSize: "20px" }} />
                            </Fab>
                        </div>
                    )}
                </div>
            ) : (
                <div className='CHATAREA d-flex justify-content-center align-items-center'>
                    <p>Click the + icon to initiate a conversation with the people.</p>
                </div>
            )}
        </>
    );
}

export default ChatArea;
