import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { RoleTitle } from "../../../Context/constants";
import moment from "moment-timezone";
import { UpdateAvailabilityValidation } from "../../../Context/Validation";
import { useNavigate, useParams } from "react-router-dom";
import useReduxData from "../../../Hooks/useReduxData";
import useMinimumDate from "../../../Hooks/useMinimumDate";
import { AVAILABILITY_LIST, UPDATE_AVAILABILITY } from "../../Repository/AvailabilityRepo";
import { TIME_ZONE_LIST } from "../../Repository/SettingRepo";

const useUpdateAvailability = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loader, setloader] = useState(false)
    const [listTimeZone, setListTimeZone] = useState([])
    const [availabilityDefault, updateDefaultAvailability] = useState([
        {
            id: 0,
            name: "Sunday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
        {
            id: 1,
            name: "Monday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
        {
            id: 2,
            name: "Tuesday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
        {
            id: 3,
            name: "Wednesday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
        {
            id: 4,
            name: "Thursday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
        {
            id: 5,
            name: "Friday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
        {
            id: 6,
            name: "Saturday",
            isChecked: false,
            arrayOfTimings: [
            ],
        },
    ]);
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { minimumdate } = useMinimumDate()
    useEffect(() => {
        getdataAvailabilityView()
        getlistTimeZone()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // get time zone
    const getlistTimeZone = async () => {
        setloader(true)
        const payload = {
            activeStatus: true,
        };
        try {
            let response = await TIME_ZONE_LIST(payload)
            setListTimeZone(response.data)
        } catch (error) {
            toast.error(error.message)
            setListTimeZone([])
        }
        finally {
            setloader(false)
        }
    }

    //view update data api
    const getdataAvailabilityView = async () => {
        setloader(true)
        let payload = {
            roleTitle: RoleTitle?.SERVICE_PROVIDER,
            availabilityId: +id
        }
        try {
            let response = await AVAILABILITY_LIST(payload)
            AvailabilityAdd.setFieldValue("timeZone", [response?.data?.timezone])
            AvailabilityAdd.setFieldValue("fromDate", moment.tz(response?.data?.startDate, response?.data?.timezone?.timezone))
            AvailabilityAdd.setFieldValue("toDate", moment.tz(response?.data?.EndDate, response?.data?.timezone?.timezone))
            AvailabilityAdd.setFieldValue("clinicId", response?.data?.clinicId)
            updateDefaultAvailability(response?.data.availableDays)
            setloader(false)
            for (let i = 0; i < response?.data?.availableDays.length; i++) {
                if (response?.data?.availableDays[i].name === "Sunday") {
                    response?.data?.availableDays[0].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`sunStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`sunEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }
                if (response?.data?.availableDays[i].name === "Monday") {
                    response?.data?.availableDays[1].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`monStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`monEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }
                if (response.data?.availableDays[i].name === "Tuesday") {
                    response?.data?.availableDays[2].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`tuesStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`tuesEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }
                if (response.data?.availableDays[i].name === "Wednesday") {
                    response?.data?.availableDays[3].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`wedStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`wedEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }
                if (response.data?.availableDays[i].name === "Thursday") {
                    response?.data?.availableDays[4].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`thursStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`thursEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }
                if (response.data?.availableDays[i].name === "Friday") {
                    response?.data?.availableDays[5].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`friStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`friEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }
                if (response.data?.availableDays[i].name === "Saturday") {
                    response?.data?.availableDays[6].arrayOfTimings?.forEach((cur, indexs) => {
                        AvailabilityAdd.setFieldValue(`satStartTime${indexs}`, moment(cur?.startTime, "HH:mm"))
                        AvailabilityAdd.setFieldValue(`satEndTime${indexs}`, moment(cur?.endTime, "HH:mm"))
                    })
                }


                // if (response?.data?.availableDays[i].name === "Sunday") {
                //     response?.data?.availableDays[0].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`sunStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`sunEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }
                // if (response.data?.availableDays[i].name === "Monday") {
                //     response?.data?.availableDays[1].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`monStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`monEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }
                // if (response.data?.availableDays[i].name === "Tuesday") {
                //     response?.data?.availableDays[2].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`tuesStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`tuesEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }
                // if (response.data?.availableDays[i].name === "Wednesday") {
                //     response?.data?.availableDays[3].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`wedStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`wedEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }
                // if (response.data?.availableDays[i].name === "Thursday") {
                //     response?.data?.availableDays[4].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`thursStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`thursEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }
                // if (response.data?.availableDays[i].name === "Friday") {
                //     response?.data?.availableDays[5].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`friStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`friEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }
                // if (response.data?.availableDays[i].name === "Saturday") {
                //     response?.data?.availableDays[6].arrayOfTimings?.forEach((cur, indexs) => {
                //         AvailabilityAdd.setFieldValue(`satStartTime${indexs}`, moment(cur?.startTime))
                //         AvailabilityAdd.setFieldValue(`satEndTime${indexs}`, moment(cur?.endTime))
                //     })
                // }

            }
        } catch (error) {
            setloader(false)
        }

    }

    // add time  button
    const addtimes = (day) => {
        let tempArr = availabilityDefault;
        if (tempArr[day].arrayOfTimings.length > 2)
            return toast.warn("Timings cannot be more than 3");
        tempArr[day].arrayOfTimings.push({
            startTime: "",
            endTime: "",
        });
        updateDefaultAvailability([...tempArr]);
    };
    // close time button
    const removetime = (day, index) => {
        let tempArr = availabilityDefault;
        tempArr[day].arrayOfTimings.splice(index, 1);
        updateDefaultAvailability([...tempArr]);
    };
    // remove section
    const removeSection = (day, index) => {
        switch (day) {
            case "Sunday":
                AvailabilityAdd.values[`sunStartTime${index}`] = undefined
                AvailabilityAdd.values[`sunEndTime${index}`] = undefined
                break;
            case "Monday":
                AvailabilityAdd.values[`monStartTime${index}`] = undefined
                AvailabilityAdd.values[`monEndTime${index}`] = undefined
                break;
            case "Tuesday":
                AvailabilityAdd.values[`tuesStartTime${index}`] = undefined
                AvailabilityAdd.values[`tuesEndTime${index}`] = undefined
                break;
            case "Wednesday":
                AvailabilityAdd.values[`wedStartTime${index}`] = undefined
                AvailabilityAdd.values[`wedEndTime${index}`] = undefined
                break;
            case "Thursday":
                AvailabilityAdd.values[`thursStartTime${index}`] = undefined
                AvailabilityAdd.values[`thursEndTime${index}`] = undefined
                break;
            case "Friday":
                AvailabilityAdd.values[`friStartTime${index}`] = undefined
                AvailabilityAdd.values[`friEndTime${index}`] = undefined
                break;
            case "Saturday":
                AvailabilityAdd.values[`satStartTime${index}`] = undefined
                AvailabilityAdd.values[`satEndTime${index}`] = undefined
                break;
            default:
                break;
        }
    }
    //  Switch  button
    const showHideTimings = (day) => {
        let tempArr = availabilityDefault;
        let check = !tempArr[day].isChecked
        tempArr[day].isChecked = check;
        if (check) {
            tempArr[day].arrayOfTimings = [{ startTime: "", endTime: "" }]
            switch (day) {
                case 0:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`sunStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`sunEndTime${i}`, undefined)
                    }
                    break;
                case 1:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`monStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`monEndTime${i}`, undefined)
                    }
                    break;
                case 2:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`tuesStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`tuesEndTime${i}`, undefined)
                    }
                    break;
                case 3:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`wedStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`wedEndTime${i}`, undefined)
                    }
                    break;
                case 4:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`thursStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`thursEndTime${i}`, undefined)
                    }
                    break;
                case 5:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`friStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`friEndTime${i}`, undefined)
                    }
                    break;
                case 6:
                    for (let i = 0; i <= 3; i++) {
                        AvailabilityAdd.setFieldValue(`satStartTime${i}`, undefined)
                        AvailabilityAdd.setFieldValue(`satEndTime${i}`, undefined)
                    }
                    break;
                default:

                    break;
            }
        }

        updateDefaultAvailability([...tempArr]);
    };
    const setTime = (Start_Time, End_Time, timeZone) => {
        let startTime = moment(Start_Time).format('HH:mm').split(':')
        let endTime = moment(End_Time).format('HH:mm').split(':')
        let startDateTime = moment.tz(Start_Time, timeZone).set({ h: startTime[0], minute: startTime[1], second: 0 })
        let endDateTime = moment.tz(End_Time, timeZone).set({ h: endTime[0], minute: endTime[1], second: 0 })
        // let startDateTime = Start_Time
        // let endDateTime = End_Time
        return { startDateTime, endDateTime }
    }
    const AvailabilityAdd = useFormik({
        initialValues: {
            //Select Clinic
            // Timezone
            timeZone: [],
            fromDate: "",
            toDate: "",
            // sun
            sunStartTime0: "",
            sunEndTime0: "",
            sunStartTime1: "",
            sunEndTime1: "",
            sunStartTime2: "",
            sunEndTime2: "",

            //mon
            monStartTime0: "",
            monEndTime0: "",
            monStartTime1: "",
            monEndTime1: "",
            monStartTime2: "",
            monEndTime2: "",
            // tues
            tuesStartTime0: "",
            tuesEndTime0: "",
            tuesStartTime1: "",
            tuesEndTime1: "",
            tuesStartTime2: "",
            tuesEndTime2: "",
            // wed
            wedStartTime0: "",
            wedEndTime0: "",
            wedStartTime1: "",
            wedEndTime1: "",
            wedStartTime2: "",
            wedEndTime2: "",
            //thurs

            thursStartTime0: "",
            thursEndTime0: "",
            thursStartTime1: "",
            thursEndTime1: "",
            thursStartTime2: "",
            thursEndTime2: "",
            //fri

            friStartTime0: "",
            friEndTime0: "",
            friStartTime1: "",
            friEndTime1: "",
            friStartTime2: "",
            friEndTime2: "",

            //sat

            satStartTime0: "",
            satEndTime0: "",
            satStartTime1: "",
            satEndTime1: "",
            satStartTime2: "",
            satEndTime2: "",
        },
        validationSchema: UpdateAvailabilityValidation,
        onSubmit: async (values) => {
            if ((values.fromDate > values.toDate) && (values.fromDate !== values.toDate)) return toast.warn(" Start date can not be greater than end date");
            let data = {
                clinicId: values?.clinicId,
                // startDate: moment(values.fromDate).startOf('day').utc(),
                // endDate: moment(values.toDate).endOf('day').utc(),

                startDate: moment.tz(values.fromDate, values?.timeZone?.[0]?.timezone).startOf('day').utc(),
                endDate: moment.tz(values.toDate, values?.timeZone?.[0]?.timezone).endOf('day').utc(),

                staffId: userdata.roleTitle === RoleTitle?.STAFF ? userdata.userId : undefined,
                roleTitle: userdata.roleTitle,
                providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                timeZone: values?.timeZone?.[0]?.timezone,
                availabilityId: +id,
                availableDays: [
                    {
                        id: 0,
                        name: "Sunday",
                        isChecked: availabilityDefault[0].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 1,
                        name: "Monday",
                        isChecked: availabilityDefault[1].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 2,
                        name: "Tuesday",
                        isChecked: availabilityDefault[2].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 3,
                        name: "Wednesday",
                        isChecked: availabilityDefault[3].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 4,
                        name: "Thursday",
                        isChecked: availabilityDefault[4].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 5,
                        name: "Friday",
                        isChecked: availabilityDefault[5].isChecked,
                        arrayOfTimings: [],
                    },
                    {
                        id: 6,
                        name: "Saturday",
                        isChecked: availabilityDefault[6].isChecked,
                        arrayOfTimings: [],
                    },
                ],
            };

            console.log(values, "values")
            //  sunday condition
            if (new Date(values?.sunStartTime0).getTime() === new Date(values?.sunEndTime0).getTime())
                return toast.warn("Sunday start time and  end time can not be equal");
            if (values?.sunStartTime0 > values?.sunEndTime0)
                return toast.warn("Sunday start time  can not be greater than end time");
            if (values?.sunStartTime0 && values?.sunEndTime0) {
                const { startDateTime, endDateTime } = setTime(values?.sunStartTime0, values?.sunEndTime0, values?.timeZone?.[0]?.timezone)
                data.availableDays[0].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });

            }
            if (new Date(values?.sunStartTime1).getTime() === new Date(values?.sunEndTime1).getTime())
                return toast.warn("Sunday start time and  end time can not be equal");
            if (values?.sunStartTime1 > values?.sunEndTime1)
                return toast.warn(
                    "Sunday second start time  can not be greater than end  time"
                );
            if (values?.sunStartTime1 && values?.sunEndTime1) {
                const { startDateTime, endDateTime } = setTime(values?.sunStartTime1, values?.sunEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[0].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.sunStartTime2).getTime() === new Date(values?.sunEndTime2).getTime())
                return toast.warn("Sunday start time and  end time can not be equal");
            if (values?.sunStartTime2 > values?.sunEndTime2)
                return toast.warn(
                    "Sunday third start time  can not be greater than end time"
                );
            if (values?.sunStartTime2 && values?.sunEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.sunStartTime2, values?.sunEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[0].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[0].isChecked === false) {
                data.availableDays[0].arrayOfTimings.splice(0, data.availableDays[0].arrayOfTimings.length)
            }
            if (data.availableDays[0].isChecked === true) {
                if (data.availableDays[0].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }

            // console.log(values?.monStartTime0.format("LT"), values?.monEndTime0.format("LT"))
            // console.log(values?.monEndTime0.diff(values?.monStartTime0))
            //  monday condition
            if (new Date(values?.monStartTime0).getTime() === new Date(values?.monEndTime0).getTime())
                return toast.warn("Monday start time and  end time can not be equal");
            if (values?.monStartTime0 > values?.monEndTime0) return toast.warn(
                "Monday  start time  can not be greater than end "
            );

            if (values?.monStartTime0 < values?.monEndTime0) {
                // console.log(values?.monStartTime0.format() ,)
                // console.log(values?.monStartTime0.tz(values?.timeZone?.[0]?.timezone,true).format() )
                //console.log('values?.timeZone?.[0]?.timezone', values?.timeZone?.[0]?.timezone,values?.monStartTime0 ,
                //values?.monStartTime0 .tz('America/Los_Angeles').format('HH mm')
                //)
                // console.log(values?.timeZone?.[0]?.timezone, "values?.timeZone?.[0]?.timezone")
                // console.log('values?.monStartTime0', moment(values?.monStartTime0).format('LLL'), '============', moment.tz(values?.monStartTime0, values?.timeZone?.[0]?.timezone).format('LLL'))
                const { startDateTime, endDateTime } = setTime(values?.monStartTime0, values?.monEndTime0, values?.timeZone?.[0]?.timezone)

                data.availableDays[1].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.monStartTime1).getTime() === new Date(values?.monEndTime1).getTime())
                return toast.warn("Monday start time and  end time can not be equal");
            if (values?.monStartTime1 > values?.monEndTime1)
                return toast.warn(
                    "Monday second end  time can not be greater than start time"
                );
            if (values?.monStartTime1 && values?.monEndTime1) {
                // console.log(values?.timeZone?.[0]?.timezone,"values?.timeZone?.[0]?.timezone")
                // console.log('values?.monStartTime1', moment(values?.monStartTime1).utc(), '============', moment(values?.monStartTime1).tz(values?.timeZone?.[0]?.timezone))
                const { startDateTime, endDateTime } = setTime(values?.monStartTime1, values?.monEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[1].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.monStartTime2).getTime() === new Date(values?.monEndTime2).getTime())
                return toast.warn("Monday start time and  end time can not be equal");
            if (values?.monStartTime2 > values?.monEndTime2)
                return toast.warn(
                    "Monday third  start time can not be greater than  end  time"

                );
            if (values?.monStartTime2 && values?.monEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.monStartTime2, values?.monEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[1].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[1].isChecked === false) {
                data.availableDays[1].arrayOfTimings.splice(0, data.availableDays[1].arrayOfTimings.length)
            }
            if (data.availableDays[1].isChecked === true) {
                if (data.availableDays[1].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }
            //  tuesday condition
            if (new Date(values?.tuesStartTime0).getTime() === new Date(values?.tuesEndTime0).getTime())
                return toast.warn("Tuesday start time and  end time can not be equal");
            if (values?.tuesStartTime0 > values?.tuesEndTime0)
                return toast.warn(
                    "Tuesday  start time  can not be greater than end  time "
                );
            if (values?.tuesStartTime0 && values?.tuesEndTime0) {
                const { startDateTime, endDateTime } = setTime(values?.tuesStartTime0, values?.tuesEndTime0, values?.timeZone?.[0]?.timezone)
                data.availableDays[2].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.tuesStartTime1).getTime() === new Date(values?.tuesEndTime1).getTime())
                return toast.warn("Tuesday start time and  end time can not be equal");
            if (values?.tuesStartTime1 > values?.tuesEndTime1)
                return toast.warn(
                    "Tuesday second start time  can not be greater than end  time "
                );
            if (values?.tuesStartTime1 && values?.tuesEndTime1) {
                const { startDateTime, endDateTime } = setTime(values?.tuesStartTime1, values?.tuesEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[2].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.tuesStartTime2).getTime() === new Date(values?.tuesEndTime2).getTime())
                return toast.warn("Tuesday start time and  end time can not be equal");
            if (values?.tuesStartTime2 > values?.tuesEndTime2)
                return toast.warn(
                    "Tuesday third start time  can not be greater than end  time "
                );
            if (values?.tuesStartTime2 && values?.tuesEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.tuesStartTime2, values?.tuesEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[2].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[2].isChecked === false) {
                data.availableDays[2].arrayOfTimings.splice(0, data.availableDays[2].arrayOfTimings.length)
            }
            if (data.availableDays[2].isChecked === true) {
                if (data.availableDays[2].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }
            //  wednesday condition
            if (new Date(values?.wedStartTime0).getTime() === new Date(values?.wedEndTime0).getTime())
                return toast.warn("Wednesday start time and  end time can not be equal");
            if (values?.wedStartTime0 > values?.wedEndTime0)
                return toast.warn(
                    " Wednesday   start time  can not be greater than end  time "
                );
            if (values?.wedStartTime0 && values?.wedEndTime0) {
                const { startDateTime, endDateTime } = setTime(values?.wedStartTime0, values?.wedEndTime0, values?.timeZone?.[0]?.timezone)
                data.availableDays[3].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.wedStartTime1).getTime() === new Date(values?.wedEndTime1).getTime())
                return toast.warn("Wednesday start time and  end time can not be equal");
            if (values?.wedStartTime1 > values?.wedEndTime1)
                return toast.warn(
                    " Wednesday second  start time  can not be greater than end  time "
                );
            if (values?.wedStartTime1 && values?.wedEndTime1) {
                const { startDateTime, endDateTime } = setTime(values?.wedStartTime1, values?.wedEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[3].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.wedStartTime2).getTime() === new Date(values?.wedEndTime2).getTime())
                return toast.warn("Wednesday start time and  end time can not be equal");
            if (values?.wedStartTime2 > values?.wedEndTime2)
                return toast.warn(
                    " Wednesday third  start time  can not be greater than end  time "
                );
            if (values?.wedStartTime2 && values?.wedEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.wedStartTime2, values?.wedEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[3].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[3].isChecked === false) {
                data.availableDays[3].arrayOfTimings.splice(0, data.availableDays[3].arrayOfTimings.length)
            }
            if (data.availableDays[3].isChecked === true) {
                if (data.availableDays[3].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }

            //  thursday  condition
            if (new Date(values?.thursStartTime0).getTime() === new Date(values?.thursEndTime0).getTime())
                return toast.warn("Thursday start time and  end time can not be equal");
            if (values?.thursStartTime0 > values?.thursEndTime0)
                return toast.warn(
                    " Thursday  start time  can not be greater than end  time "
                );
            if (values?.thursStartTime0 && values?.thursEndTime0) {
                const { startDateTime, endDateTime } = setTime(values?.thursStartTime0, values?.thursEndTime0, values?.timeZone?.[0]?.timezone)
                data.availableDays[4].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.thursStartTime1).getTime() === new Date(values?.thursEndTime1).getTime())
                return toast.warn("Thursday start time and  end time can not be equal");
            if (values?.thursStartTime1 > values?.thursEndTime1)
                return toast.warn(
                    " Thursday second start time  can not be greater than end  time "
                );
            if (values?.thursStartTime1 && values?.thursEndTime1) {
                const { startDateTime, endDateTime } = setTime(values?.thursStartTime1, values?.thursEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[4].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.thursStartTime2).getTime() === new Date(values?.thursEndTime2).getTime())
                return toast.warn("Thursday start time and  end time can not be equal");
            if (values?.thursStartTime2 > values?.thursEndTime2)
                return toast.warn(
                    " Thursday third start time  can not be greater than end  time "
                );
            if (values?.thursStartTime2 && values?.thursEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.thursStartTime2, values?.thursEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[4].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[4].isChecked === false) {
                data.availableDays[4].arrayOfTimings.splice(0, data.availableDays[4].arrayOfTimings.length)
            }
            if (data.availableDays[4].isChecked === true) {
                if (data.availableDays[4].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }
            //  frisday  condition
            if (new Date(values?.friStartTime0).getTime() === new Date(values?.friEndTime0).getTime())
                return toast.warn("Friday start time and  end time can not be equal");
            if (values?.friStartTime0 > values?.friEndTime0)
                return toast.warn(
                    " Friday  start time  can not be greater than end  time "
                );
            if (values?.friStartTime0 && values?.friEndTime0) {
                const { startDateTime, endDateTime } = setTime(values?.friStartTime0, values?.friEndTime0, values?.timeZone?.[0]?.timezone)
                data.availableDays[5].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.friStartTime1).getTime() === new Date(values?.friEndTime1).getTime())
                return toast.warn("Friday start time and  end time can not be equal");
            if (values?.friStartTime1 > values?.friEndTime1)
                return toast.warn(
                    " Friday second start time  can not be greater than end  time "
                );
            if (values?.friStartTime1 && values?.friEndTime1) {
                const { startDateTime, endDateTime } = setTime(values?.friStartTime1, values?.friEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[5].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.friStartTime2).getTime() === new Date(values?.friEndTime2).getTime())
                return toast.warn("Friday start time and  end time can not be equal");
            if (values?.friStartTime2 > values?.friEndTime2)
                return toast.warn(
                    " Friday third start time  can not be greater than end time "
                );
            if (values?.friStartTime2 && values?.friEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.friStartTime2, values?.friEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[5].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[5].isChecked === false) {
                data.availableDays[5].arrayOfTimings.splice(0, data.availableDays[5].arrayOfTimings.length)
            }
            if (data.availableDays[5].isChecked === true) {
                if (data.availableDays[5].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }
            //  saturday  condition
            if (new Date(values?.satStartTime0).getTime() === new Date(values?.satEndTime0).getTime())
                return toast.warn("Saturday start time and  end time can not be equal");
            if (values?.satStartTime0 > values?.satEndTime0)
                return toast.warn(
                    " Saturday  start time  can not be greater than end  time "
                );
            if (values?.satStartTime0 && values?.satEndTime0) {
                const { startDateTime, endDateTime } = setTime(values?.satStartTime0, values?.satEndTime0, values?.timeZone?.[0]?.timezone)
                data.availableDays[6].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.satStartTime1).getTime() === new Date(values?.satEndTime1).getTime())
                return toast.warn("Saturday start time and  end time can not be equal");
            if (values?.satStartTime1 > values?.satEndTime1)
                return toast.warn(
                    " Saturday second start time  can not be greater than end  time "
                );
            if (values?.satStartTime1 && values?.satEndTime1) {
                const { startDateTime, endDateTime } = setTime(values?.satStartTime1, values?.satEndTime1, values?.timeZone?.[0]?.timezone)
                data.availableDays[6].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (new Date(values?.satStartTime2).getTime() === new Date(values?.satEndTime2).getTime())
                return toast.warn("Saturday start time and  end time can not be equal");
            if (values?.satStartTime2 > values?.satEndTime2)
                return toast.warn(
                    " Saturday third start time  can not be greater than end  time "
                );
            if (values?.satStartTime2 && values?.satEndTime2) {
                const { startDateTime, endDateTime } = setTime(values?.satStartTime2, values?.satEndTime2, values?.timeZone?.[0]?.timezone)
                data.availableDays[6].arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (data.availableDays[6].isChecked === false) {
                data.availableDays[6].arrayOfTimings.splice(0, data.availableDays[6].arrayOfTimings.length)
            }
            if (data.availableDays[6].isChecked === true) {
                if (data.availableDays[6].arrayOfTimings.length === 0) {
                    return toast.warn("Please enter time")
                }
            }
            if (data.availableDays[0].isChecked === false && data.availableDays[1].isChecked === false &&
                data.availableDays[2].isChecked === false && data.availableDays[3].isChecked === false &&
                data.availableDays[4].isChecked === false && data.availableDays[5].isChecked === false &&
                data.availableDays[5].isChecked === false) return toast.warn("Please   set availability")
            setloader(true)
            try {
                let response = await UPDATE_AVAILABILITY(data)
                toast.success(response?.message)
                setloader(false)
                navigate("/availability")
            } catch (error) {
                setloader(false)
                toast.error(error?.message)
            }
        },
    });

    const handalSearchAbleSelectBox = (event, newValue, name) => {
        AvailabilityAdd.setFieldValue(name, newValue)
    }
    return {
        loader,
        AvailabilityAdd,
        availabilityDefault,
        listTimeZone,
        addtimes,
        removetime,
        removeSection,
        showHideTimings, minimumdate, userdata, handalSearchAbleSelectBox
    }
}

export default useUpdateAvailability
