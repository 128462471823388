import React from "react";
import "moment-timezone";
import moment from "moment-timezone";
import Loading from "../../Component/Loading";
import { SearchAbleSelectBox } from "../../Component/Inputs";
import { Errors } from "../../Component/Error";
import TimePicker from "rc-time-picker";
import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import useAvailability from "../Availability/Hooks/useAvailability";
import { FaArrowLeft } from "react-icons/fa";

const UnAvailabilityAppointment = ({ type }) => {
    const {
        CustomButton,
        loader,
        addUnavailabililty,
        unAvailabilityDefault,
        listTimeZone,
        userdata,
        removeSelection,
        removetimeUnavailability,
        addtimesUnavailability,
        handalSearchAbleSelectBox,
        navigate,
    } = useAvailability(type)
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center ">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate("/appointment/calendar") }} />
                        <h2 className="page_name " >Set Unavailability</h2>
                    </div>
                </header>
                <div className="row common-white-bg-box shadow position-relative d d-flex align-content-start mx-0 p-2  ">
                    <div className="h-100 d-flex justify-content-between flex-column ">
                        <div>
                            <div className="col-12" >
                                <div className="row px-2">
                                    <div className="col-sm-2 mb-3 ps-0">
                                        <label className="form-label mb-0"> Clinic<span className='Star_Mark'>*</span></label>
                                        <input type="text" className="form-control"
                                            maxLength={50}
                                            value={userdata?.location?.clinicName}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-sm-2 mb-3 ps-0">
                                        <label className="form-label mb-0">Timezone<span className='Star_Mark'>*</span></label>
                                        <SearchAbleSelectBox
                                            multiple={false}
                                            option={listTimeZone}
                                            getOptionLabel="text"
                                            isOptionEqualToValue="id"
                                            value={addUnavailabililty.values.timeZone}
                                            name="timeZone"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={addUnavailabililty} name={"timeZone"} />
                                    </div>

                                    <div className="col-sm-2 ps-0 ">
                                        <label className="form-label mb-0">Start Date<span className='Star_Mark'>*</span></label>
                                        <input type="date" className="form-control position-relative" name="fromDate" id="datefield" value={addUnavailabililty.values.fromDate ? moment(addUnavailabililty.values.fromDate).format("YYYY-MM-DD") : ""}
                                            onChange={(e) => addUnavailabililty.setFieldValue("fromDate", e.target.value)} />
                                        <Errors formikfun={addUnavailabililty} name={"fromDate"} />
                                    </div>
                                    <div className="col-sm-2 ps-0">
                                        <label className="form-label mb-0">End Date<span className='Star_Mark'>*</span></label>
                                        <input type="date" className="form-control position-relative" name="toDate" id="datefield" value={addUnavailabililty.values.toDate ? moment(addUnavailabililty.values.toDate).format("YYYY-MM-DD") : ""}
                                            onChange={(e) => addUnavailabililty.setFieldValue("toDate", e.target.value)} />
                                        <Errors formikfun={addUnavailabililty} name={"toDate"} />
                                    </div>
                                    <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}></div>
                                </div>
                            </div>
                            <label className="form-label mb-0 mt-3">Timing<span className='Star_Mark'>*</span></label>
                            <div className="col-5  rounded py-3">
                                {
                                    unAvailabilityDefault.arrayOfTimings.map((cur, index) => {
                                        return <React.Fragment key={index}>
                                            <div className="mb-2 d-flex ">
                                                <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`StartTime${index}`}
                                                    onChange={(e) => addUnavailabililty.setFieldValue(`StartTime${index}`, e)} value={addUnavailabililty.values[`StartTime${index}`] ? addUnavailabililty.values[`StartTime${index}`] : null}
                                                />
                                                <div className="col-sm-1 d-flex align-items-center justify-content-center"><small>To</small></div>
                                                <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`EndTime${index}`}
                                                    onChange={(e) => addUnavailabililty.setFieldValue(`EndTime${index}`, e)} value={addUnavailabililty.values[`EndTime${index}`] ? addUnavailabililty.values[`EndTime${index}`] : null}
                                                />
                                                <div className="col-sm-1 d-flex align-items-center justify-content-center" >
                                                    {index === 0 ? (
                                                        <button className=" d-flex align-items-center justify-content-center" style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimesUnavailability()}  >
                                                            <IoMdAddCircleOutline className="all_react_icons_color" />
                                                        </button>
                                                    ) : (
                                                        <button className=" d-flex align-items-center justify-content-center" style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetimeUnavailability(index); removeSelection(index) }}  >
                                                            <IoMdCloseCircleOutline className="all_react_icons_color" />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-12 text-end">
                            <CustomButton variant="contained" onClick={addUnavailabililty.handleSubmit} > Save</CustomButton>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default UnAvailabilityAppointment