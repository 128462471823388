import axios from "axios"
import { DeleteDynamicRolesApi, addDebitCard, addDynamicRolesApi, cancelPlan, changeDefaultCard, chargeAmount, createRazorpayUrl, deleteDebitCard, getAutomatedPaymentStatus, getDetailsDynamicRolesApi, getPaymentdetails, insertLifeStyle, listDebitCard, listDynamicRolesApi, listLifeStyleApi, listStaffDynamicRolesApi, listnotifications, paymentHistory, planHistory, replyApi, setUpSetting, statusChnageDynamicRolesApi, subcriptionPlanList, ticketListAPi, ticketdetailsAPi, timeZoneListApi, updateAutomatedPaymentStatus, updateDynamicRolesApi, updateLifeStyleApi, updateNotifications, updatepin, viewSettingDetails } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"


export const UPDATE_SETTING = async (body) => {
    try {
        let response = await axiosInstance.post(setUpSetting(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const VIEW_SETTING_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(viewSettingDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

////////////////////////////// CHANGE PIN /////////////////////////////////
export const UPDATE_PIN = async (body) => {
    try {
        let response = await axiosInstance.post(updatepin(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

///////////////////////// Help Desk ///////////////////////////////////////

export const TICKET_LIST = async (body) => {
    try {
        let response = await axios.post(ticketListAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_TICKET = async (body) => {
    try {
        let response = await axios.post(ticketListAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const GET_TICKET_DETAILS = async (body) => {
    try {
        let response = await axios.get(ticketdetailsAPi() + body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const SEND_REPLY = async (body) => {
    try {
        let response = await axios.post(replyApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


////////////////////////////////// LIFE STYLE  /////////////////////////////

export const INSERT_LIFESTYLE = async (body) => {
    try {
        let response = await axiosInstance.post(insertLifeStyle(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const LIFESTYLE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listLifeStyleApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_LIFESTYLE = async (body) => {
    try {
        let response = await axiosInstance.post(updateLifeStyleApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

////////////////////////////////// NOTIFICATION  /////////////////////////////

export const GET_NOTIFICATION_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(listnotifications(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_NOTIFICATION = async (body) => {
    try {
        let response = await axiosInstance.post(updateNotifications(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

////////////////////////////////// TIME ZONE ///////////////////////////////



export const TIME_ZONE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(timeZoneListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


export const PLAN_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(subcriptionPlanList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const PLAN_HISTORY = async (body) => {
    try {
        let response = await axiosInstance.post(planHistory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const PAYMENT_HISTORY = async (body) => {
    try {
        let response = await axiosInstance.post(paymentHistory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CANCEL_PLAN = async (body) => {
    try {
        let response = await axiosInstance.post(cancelPlan(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


export const ADD_DEBIT_CARD = async (body) => {
    try {
        let response = await axiosInstance.post(addDebitCard(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DEBIT_CARD_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listDebitCard(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DEBIT_CARD_DELETE = async (body) => {
    try {
        let response = await axiosInstance.post(deleteDebitCard(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CHANGE_DEFAULT_CARD = async (body) => {
    try {
        let response = await axiosInstance.post(changeDefaultCard(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CHANGE_AUTOMATED_PAYMENT_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(updateAutomatedPaymentStatus(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_AUTOMATED_PAYMENT_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(getAutomatedPaymentStatus(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CHARGE_AMOUNT = async (body) => {
    try {
        let response = await axiosInstance.post(chargeAmount(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CREATE_RAZORPAY_ORDER = async (body) => {
    try {
        let response = await axiosInstance.post(createRazorpayUrl(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const PAYMENT_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getPaymentdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

// roles 

export const INSERT_ROLE = async (body) => {
    try {
        let response = await axiosInstance.post(addDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ROLE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_ROLE_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getDetailsDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_ROLE = async (body) => {
    try {
        let response = await axiosInstance.post(updateDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const CHANGE_ROLE_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(statusChnageDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_ROLE = async (body) => {
    try {
        let response = await axiosInstance.post(DeleteDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ROLE_LIST_STAFF = async (body) => {
    try {
        let response = await axiosInstance.post(listStaffDynamicRolesApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}



















