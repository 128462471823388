import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { Avatar, Fab } from '@mui/material';
import Loading from '../../Component/Loading';
import useProfile from './Hooks/useProfile';
import { FaIdCard } from "react-icons/fa";
import {
    TeamImageFilePath, clinicPictures,
    //  userBasicProfileURL,
    userImageFilePath
} from '../../Context/api';
import { AddClinicFildData } from '../../Redux/ReducerFile/ClinicFildData';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import useHtml2canvas from '../../Hooks/useHtml2canvas';
import { RiEditLine } from "react-icons/ri";
import { MdPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { IoMdCloudDownload } from "react-icons/io";
import userImageDummy from "../../Assets/Images/dummyimg.png"
import { FaArrowLeft } from 'react-icons/fa';
import { RoleTitle } from '../../Context/constants';
const Profile = () => {
    const {
        formik,
        imgUrl,
        loader,
        userdata,
        updateBusinessDetails,
        // basicDetailsFrofileImage,
        clinicDelete,
        clinicList,
        qrCodeData,
        deleteAccount,
        openModal,
        switchProfileList,
        chooseprofileData,
        chooseLocationData,
        ChooseLocationList,
        isProfile,
        tab,
        setTab,
        nextLocationTab,
        backToProfileList,
        handleChooseLocation,
        handleSwitchProfile,
        handleChooseProfile,
        handleClosedModal,
        handleOpenModal,
        navigate,
        setDelete,
        SetclinicDetele,
        handleClose,
        dispatch,
        handleScroll,
        handleCloseAccount,
        setDeleteAccount,
    } = useProfile()
    const { CustomButton } = useMuiCompontent()
    const { contentRef, downloadContent } = useHtml2canvas()
    const updateClinic = JSON.parse(localStorage.getItem("addClinic"))
    const ChangeTab = (Type) => {
        switch (Type) {
            case "BusinessDetails":
                setTab(1)
                break;
            case "QRcode":
                setTab(3)
                break;
            case "DeleteAccount":
                setTab(2)
                break;
            default: setTab(1)
        }
    }
    useEffect(() => {
        if (updateClinic) {
            ChangeTab("BusinessDetails")
            localStorage.removeItem("addClinic");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className=" d-flex justify-content-start">
                        <h2 className='page_name'>Profile</h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0 px-4 pt-3 shadow  PROVIDER_PROFILE">
                    {/* <div className="row"> */}
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="d-flex">
                                <div className="USER_PROFILE_IMAGE">
                                    {/* <Avatar alt={formik?.values?.firstName?.toUpperCase()} sx={{ width: 150, height: 150 }} className='custom_avtar' src={userBasicProfileURL + basicDetailsFrofileImage} /> */}
                                    <Avatar alt={formik?.values?.firstName?.toUpperCase()} sx={{ width: 150, height: 150 }} className='custom_avtar' src={userImageFilePath + imgUrl} />

                                </div>
                                <div className=" USER_INFO ">
                                    <h3>{`${formik?.values?.title} ${formik?.values?.firstName} ${formik?.values?.lastName}`} </h3>
                                    <p> <MdEmail /> {`${formik?.values?.email}`}</p>
                                    <p><span><MdPhone /></span> {`${formik?.values?.mobile}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="row px-2">
                                <CustomButton variant="contained" startIcon={<RiEditLine />} style={{ whiteSpace: "nowrap", borderRadius: "25px", padding: "5px 12px" }} onClick={() => navigate("/Profile/edit")} > edit profile</CustomButton>
                                <CustomButton variant="contained" className='mt-3' startIcon={<i className="fa-solid fa-repeat fs-6"></i>} style={{ whiteSpace: "nowrap", borderRadius: "25px", padding: "5px 12px", background: "linear-gradient(to right ,#FF9669, #FF6534)" }} onClick={() => handleOpenModal("user")}  > switch profile</CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4 tab_scroll">
                        <ul className="nav  mb-0  pb-1 " style={{ width: "330px" }}>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${tab === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => ChangeTab("BusinessDetails")} >Business Details</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${tab === 3 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("QRcode")} >QR Code</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${tab === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("DeleteAccount")} >Delete Account</button>
                            </li>
                        </ul>
                    </div>
                    {
                        tab === 1 && <div className="col-12 BUSINESS_DETAILS mt-3">
                            <div className="row">
                                <div className="col-12 d-flex ps-0"  >
                                    {/* <Avatar alt="Remy Sharp" sx={{ width: 40, height: 40 }} src={userImageFilePath + imgUrl} /> */}
                                    <div className='ms-3' >
                                        <h3>{updateBusinessDetails?.values?.businessName}</h3>
                                        <ul>  <li>{updateBusinessDetails?.values?.categoryName}</li> </ul>
                                        <p>{updateBusinessDetails?.values?.about}</p>
                                    </div>
                                </div>
                                <div className="col-12  py-3 d-flex justify-content-between align-items-center" style={{ borderBottom: "1.2px solid #ededed", borderTop: "1.2px solid #ededed" }}>
                                    <h4 className='mb-0' style={{ fontSize: "20px" }}>My Locations</h4>
                                    <CustomButton startIcon={<IoMdAdd />} variant="contained" style={{ borderRadius: "25px" }} onClick={() => { navigate("/profile/addClinic"); localStorage.removeItem("service"); dispatch(AddClinicFildData({})) }}> Add clinic</CustomButton>
                                </div>
                                <div className='CLINIC_LIST' onScroll={handleScroll} style={{ height: "30vh" }}>
                                    {
                                        clinicList?.length === 0 && <p className='text-center mt-5'>No clinic found</p>
                                    }
                                    {
                                        clinicList?.map((cur, index) => {
                                            return <div key={index} className="col-12 d-flex mt-3">
                                                <Avatar
                                                    alt={cur?.clinicName?.toUpperCase()}
                                                    sx={{ width: 40, height: 40 }}
                                                    src={clinicPictures + cur?.image}
                                                />
                                                <div className='ms-3 d-flex justify-content-between align-items-start ' style={{ borderBottom: "1.2px solid #ededed", width: "100%" }}>
                                                    <div className=''>
                                                        <h3>{cur.clinicName}</h3>
                                                        <ul className='d-lg-flex'>
                                                            <li className='me-3'><FaIdCard className='me-2' /> {cur?.clinicRegistrationNumber}</li>
                                                            <li><i className="fa-solid fa-location-dot me-2" style={{ color: `${cur?.activeStatus ? "#38C4B9" : cur?.activeStatus === false ? "#000" : ""}` }} ></i>{` ${cur?.address}, ${cur?.street||""}, ${cur?.city}, ${cur?.stateName||""}, ${cur?.countryName}, ${cur?.zip} `}</li>
                                                        </ul>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <Fab size="small" style={{ background: "#38C4B9", color: "#fff" }} aria-label="add" className='me-2' onClick={() => { setDelete(cur); SetclinicDetele(true) }}>
                                                            <MdDelete style={{ fontSize: "20px" }} />
                                                        </Fab>
                                                        <Fab size="small" style={{ background: "#38C4B9", color: "#fff" }} aria-label="add" onClick={() => { navigate(`/profile/clinic/edit/${cur.clinicId}`); localStorage.removeItem("service"); dispatch(AddClinicFildData({})) }}>
                                                            <RiEditLine style={{ fontSize: "20px" }} />
                                                        </Fab>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {/* <div className='text-center mt-1'>
                                            {
                                                isLoadingClinicList && <SpinnerCompontent />
                                            }

                                        </div> */}
                                </div>
                            </div>
                        </div>
                    }
                    {tab === 3 && <div className="col-12">
                        <div className="row d-flex justify-content-center text-center ">
                            <div className="col-10">
                                <div className="row">
                                    <div ref={contentRef} className=" QR_code_page downloadable-content p-3 mx-auto d-flex justify-content-start flex-column">
                                        <p className='mb-0 name'>Wink <span>Doc</span></p>
                                        <p className=' my-2 title '>Scan this QR code to add provider</p>
                                        <h3>SCAN HERE</h3>
                                        <div  >
                                            <span dangerouslySetInnerHTML={{ __html: qrCodeData }} />
                                        </div>
                                        <p className='mb-0'>{userdata?.businessName}</p>
                                    </div>
                                    <div className='mt-2 mx-auto'>
                                        <CustomButton variant="outlined" startIcon={<IoMdCloudDownload />} onClick={downloadContent} className='' style={{ borderRadius: "25px" }}> download</CustomButton>
                                        {/* <CustomButton variant="outlined" style={{ borderRadius: "25px" }}> share</CustomButton> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    }
                    {
                        tab === 2 && <div className="col-12 py-3">
                            <p style={{ fontSize: "13px", color: "gray" }}>Deleting your account will remove all of your information
                                from our database. This cannot be undone.</p>
                            <CustomButton onClick={() => setDeleteAccount(true)} variant="contained" className='px-3' style={{ borderRadius: "25px", background: "red" }}>delete account</CustomButton>
                        </div>
                    }

                    {/* </div> */}
                </div>
            </div>



            <Modal show={clinicDelete} onHide={() => SetclinicDetele(false)} centered>
                <Modal.Body className='px-0'>
                    <div className="modal-body text-center cus-i px-0">
                        <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
                        <p className='mb-0'>Are you sure?</p>
                        <p>You want to delete this clinic</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES")} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={deleteAccount} onHide={() => setDeleteAccount(false)} centered>
                <Modal.Body className='px-0'>
                    <div className="modal-body text-center cus-i px-0">
                        <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
                        <p className='mb-0'>Are you sure?</p>
                        <p>You want to delete this account</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleCloseAccount("YES")} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleCloseAccount("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.user} onHide={() => handleClosedModal("user")} centered>
                <Modal.Body className='px-0 pt-0'>
                    {
                        isProfile ? <div className="modal-body  cus-i px-0 PROFILE">
                            <div className='d-flex w-100 justify-content-center align-items-center' >
                                <i className="fa-solid me-2 fa-repeat fs-6  " style={{ color: '#3B3B47', fontSize: "17px" }}></i>
                                <h5 className='m-0 ' style={{ color: '#3B3B47', fontSize: "17px" }} >Switch Profile</h5>
                            </div>
                            <hr />
                            <div className="SWITCH_USERS CUSTOM_SCROLL_STYLE">
                                {
                                    switchProfileList?.map((cur, index) => {
                                        return <React.Fragment key={index} >
                                            <div className={`USER ${chooseprofileData?.userId === cur?.userId ? "ACTIVE" : ""} cursor `} onClick={() => handleChooseProfile(cur)}>
                                                <div className="IMG shadow mb-2">
                                                    <img src={cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? (cur?.businessProfileImage ? userImageFilePath + cur?.businessProfileImage : userImageDummy) :
                                                        (cur?.profileImage ? TeamImageFilePath + cur?.profileImage : userImageDummy)} alt="" />
                                                </div>
                                                <p className='text-center mb-0 TEXT_ELLIPSIS' > {cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? cur?.businessName : `${cur?.firstName} ${cur?.lastName}`}   </p>
                                                <p className='text-center mb-0 mt-0 TEXT_ELLIPSIS' >   {cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? `` : `(${cur?.businessName})`}  </p>
                                                {/* <p className="text-center mb-0" >{cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? "(Provider)" : cur?.roleTitle === RoleTitle?.STAFF ? "(Team)" : `(${cur?.dynamicRoleName})`}</p> */}
                                            </div>
                                        </React.Fragment>
                                    })
                                }
                            </div>
                            <div className='w-100 text-center pt-3'>
                                <CustomButton variant="contained" className='mt-3' disabled={chooseprofileData ? false : true} onClick={() => { nextLocationTab() }} style={{ borderRadius: "25px", padding: "7px 80px", background: "linear-gradient(to right ,#FF9669, #FF6534)" }}  > Next</CustomButton>
                            </div>
                        </div> : <div className="modal-body  cus-i px-0 PROFILE">
                            <div className='d-flex w-100 justify-content-between align-items-center px-3' >
                                <FaArrowLeft className="me-2 cursor" onClick={() => backToProfileList()} />
                                <div className='d-flex w-100 justify-content-center align-items-center'>
                                    <i className="fa-solid fa-location-dot fs-6 me-2  " style={{ color: '#3B3B47', fontSize: "17px" }}></i>
                                    <h5 className='m-0 ' style={{ color: '#3B3B47', fontSize: "17px" }} >Select Location</h5>
                                </div>
                                <p style={{ color: "transparent" }}>Hidden..</p>
                            </div>
                            <hr />
                            <div className="USERS_LOCATION_LIST CUSTOM_SCROLL_STYLE px-4 pb-3">
                                {
                                    ChooseLocationList?.map((cur, index) => {
                                        return <div key={index} className={`  px-2 shadow LOCATION d-flex justify-content-start align-items-center mt-3 ${chooseLocationData?.clinicId === cur?.clinicId && "ACTIVE"} ${userdata?.location?.clinicId === cur?.clinicId ? "" : "cursor"}`} onClick={() => handleChooseLocation(cur)}>

                                            <div className='w-100 d-flex'>
                                                <Avatar
                                                    alt={cur?.clinicName?.toUpperCase()}
                                                    sx={{ width: 40, height: 40 }}
                                                    src={clinicPictures + cur?.image}
                                                />
                                                <div className='d-flex flex-column ps-2 ' style={{ width: 'calc(100% - 40px)' }}>
                                                    <div className='d-flex align-items-start justify-content-between  w-100'>
                                                        <h6 className='m-0 fs-6 me-3 '>{cur.clinicName}</h6>
                                                        <ul className='p-0 d-flex justify-content-start'>
                                                            <li className='me-2 TEXT_ELLIPSIS'><FaIdCard className='me-2 fs-6' />{cur?.clinicRegistrationNumber}</li>
                                                        </ul>
                                                    </div>
                                                    <div className=' d-flex justify-content-between align-items-start w-100 '>
                                                        <ul className='p-0 w-100'>
                                                            <li><i className="fa-solid fa-location-dot me-2 fs-6 "></i>{`${cur?.address}, ${cur?.street||""}, ${cur.city}, ${cur.stateName||""}, ${cur.countryName}, ${cur.zip} `}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    })
                                }
                            </div>
                            <div className='w-100 text-center pt-3'>
                                <CustomButton variant="contained" className='mt-3' style={{ borderRadius: "25px", padding: "7px 80px", background: "linear-gradient(to right ,#FF9669, #FF6534)" }} onClick={handleSwitchProfile}  > Switch Profile</CustomButton>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Profile
