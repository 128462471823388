
import { templateCategoryListApi, templateCategoryAddApi, templateCategoryDetailsApi, templateCategoryUpdateApi, templateCategoryToggleApi, templateCategoryDeleteApi, templatListApi, templateAddApi, templateDetailsApi, templateUpdateApi, templateToggleApi, templateDeleteApi, AssignedFormListApi, AssignFormApi, fillAndUpdateFormApi, filledAndAssignedFormDetailsApi } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"

// template category
export const CATEGORY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(templateCategoryListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ADD_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(templateCategoryAddApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_CATEGORY_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(templateCategoryDetailsApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(templateCategoryUpdateApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CATEGORY_TOGGLE_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(templateCategoryToggleApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(templateCategoryDeleteApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


// template from



export const TEMPLATE_FORM_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(templatListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ADD_TEMPLATE_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(templateAddApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_TEMPLATE_FORM_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(templateDetailsApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_TEMPLATE_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(templateUpdateApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const TEMPLATE_FORM_TOGGLE_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(templateToggleApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_TEMPLATE_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(templateDeleteApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


// agenda 

export const ASSIGNED_TEMPLATE_FORM_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(AssignedFormListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const ASSIGNED_TEMPLATE_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(AssignFormApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const FILL_UPDATE_TEMPLATE_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(fillAndUpdateFormApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_FILLED_ASSIGNED_TEMPLATE_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(filledAndAssignedFormDetailsApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}