/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { createFieldsTemplateFormValidation, createTemplateFormValidation } from '../../../Context/Validation';
import { RoleTitle } from '../../../Context/constants';
import useReduxData from '../../../Hooks/useReduxData';
import { ADD_TEMPLATE_FORM, CATEGORY_LIST, GET_TEMPLATE_FORM_DETAILS, UPDATE_TEMPLATE_FORM } from '../../Repository/TemplateLibraryRepo';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useCreateForm = () => {
    const [categoryList, setCategoryList] = useState([])
    const [editfieldIndex, setEditfieldIndex] = useState(null)
    const [viewFieldsArry, setViewFieldsArry] = useState([]);
    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const navigate = useNavigate()
    const { id } = useParams()

    const formik = useFormik({
        initialValues: {
            type: "",
            label: "",
            fieldSize: "",
            options: [],
            required: false
        },
        validationSchema: createFieldsTemplateFormValidation,
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if ((values?.type === "CheckBox" || values?.type === "Dropdown" || values?.type === "Multiple Choice") && values?.options?.length === 0) {
                errors.options = "Please enter option";
            } else {
                delete errors.options
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            if (editfieldIndex === null) {
                setViewFieldsArry((pre) => ([...pre, { ...values }]))
            } else {
                setViewFieldsArry((pre) => {
                    let temp = [...pre]
                    temp[editfieldIndex] = { ...values }
                    return temp
                })
                setEditfieldIndex(null)
            }
            resetForm()
        }
    })
    const createForm = useFormik({
        initialValues: {
            formName: "",
            formCategory: []
        },
        validationSchema: createTemplateFormValidation,

        onSubmit: async (values) => {
            if (viewFieldsArry?.length === 0) return toast.warn('Please add atleast 1 field')
            let payload = {
                providerId: id ? undefined : (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: id ? undefined : userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                roleTitle: userdata.roleTitle,
                name: values?.formName,
                formCategoryId: values?.formCategory[0]?.formCategoryId,
                status: "SAVED",
                fields: viewFieldsArry,
                formId: id ? id : undefined
            }
            try {
                let response = !id ? await ADD_TEMPLATE_FORM(payload) : await UPDATE_TEMPLATE_FORM(payload)
                toast.success(response?.message)
                navigate("/template-library/template-list")
            } catch (err) {
                toast.error(err?.message)
            }
        }
    })


    //  category list
    async function getCategoryList(categoryId) {
        const payload = {
            deletedStatus: false,
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        };
        try {
            let response = await CATEGORY_LIST(payload)
            setCategoryList(response?.data)
            if (categoryId) {
                let categoryDetails = response?.data?.filter((cur) => categoryId === cur?.formCategoryId)
                createForm.setFieldValue("formCategory", categoryDetails)
            }
        } catch (error) {
            setCategoryList([])
        }
    }

    async function getFormDetailsList() {
        const payload = {
            formId: id,
        };
        try {
            let response = await GET_TEMPLATE_FORM_DETAILS(payload)
            createForm.setFieldValue("formName", response?.data?.name)
            getCategoryList(response?.data?.formCategoryId)
            setViewFieldsArry(response?.data?.fields)
        } catch (error) {
            toast.error(error?.message)
        }
    }

    const handalSearchAbleSelectBox = (event, newValue, name) => {
        createForm.setFieldValue(name, newValue)
    }

    const deleteField = (index) => {
        let newArry = [...viewFieldsArry]
        newArry?.splice(index, 1)
        setViewFieldsArry([...newArry]);
    }
    const copyField = async (data, index) => {
        let copyData = {
            type: data?.type,
            label: data?.label,
            fieldSize: data?.fieldSize,
            options: data?.options,
            required: data?.required
        }
        let newArry = [...viewFieldsArry]
        newArry?.splice(index + 1, 0, copyData)
        setViewFieldsArry([...newArry]);
    }
    const editFieldOption = (data, index) => {
        formik.setValues(data)
        formik?.setFieldValue("options", data?.options?.map((cur) => cur?.name ? cur?.name : cur))
        setEditfieldIndex(index)
    }
    useEffect(() => {
        if (!id) {
            getCategoryList()
        } else {
            getFormDetailsList()
        }
    }, [])
    return {
        editFieldOption,
        copyField,
        navigate,
        handalSearchAbleSelectBox,
        deleteField,
        id,
        formik,
        delimiters,
        categoryList,
        createForm,
        viewFieldsArry,
        editfieldIndex,
    }
}

export default useCreateForm
