import React from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import useDragDrop from '../../Hooks/useDrag&Drop'
import { SearchAbleSelectBox } from '../../Component/Inputs'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import useOther from './Hooks/useOther'
import { Errors } from '../../Component/Error'
import { RoleTitle, currencyOptions } from '../../Context/constants'
import { SignatureURL } from '../../Context/api'
import useDeleteFile from '../../Hooks/useDeleteFile'
import Loading from '../../Component/Loading'
import { Autocomplete, TextField } from '@mui/material'
import Select from 'react-dropdown-select';
const Other = () => {
    const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } = useDragDrop()
    const { deleteFileFun } = useDeleteFile()
    const { CustomButton } = useMuiCompontent()
    const {
        setSignature,
        UploadImage,
        handalSearchAbleSelectBox,
        timeZone,
        loading,
        formik,
        FileRef,
        Signature,
        otherDetails,
        userdata
    } = useOther()

    return <>
        {loading && <Loading />}
        <div className=" d-flex justify-content-between flex-column" style={{ height: "100%" }}>
            <div className="row px-3">
                {
                    userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Currency<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SearchAbleSelectBox
                                            multiple={false}
                                            option={currencyOptions}
                                            getOptionLabel="label"
                                            isOptionEqualToValue="value"
                                            // extraLable="image"
                                            value={formik.values.currency}
                                            name="currency"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={formik} name={"currency"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>


                    </div>
                }

                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-5">
                                    <label className="form-label mb-0">Time Zone<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-lg-7">
                                    <SearchAbleSelectBox
                                        multiple={false}
                                        option={timeZone}
                                        getOptionLabel="text"
                                        isOptionEqualToValue="timezoneId"
                                        value={formik.values.timezone}
                                        name="timezone"
                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                    />
                                    {/* <Autocomplete
                                        options={timeZone}
                                        getOptionLabel={(option) => option.text}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Size small"
                                                placeholder="Favorites"
                                            />
                                        )}
                                    /> */}
                                    {/* <Select
                                        searchable={true}
                                        options={timeZone}
                                        valueField={"text"}
                                        labelField={"text"}
                                        name={"timezone"}
                                        className='font-11 mt-3'
                                    /> */}
                                    <Errors formikfun={formik} name={"timezone"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        </div>
                    </div>
                </div>

                {
                    userdata?.roleTitle !== RoleTitle?.FRONT_DESK && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Upload Signature</label>
                                    </div>
                                    <div className="col-lg-7 d-flex justify-content-between flex-column align-items-center">
                                        <div className="bodrerer w-100  mx-0" style={{ border: `${!Signature ? "2px dashed #aaaaaa" : "none"}` }}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, UploadImage)}
                                        >
                                            <label htmlFor="inputTag">
                                                <AiOutlineCloudUpload className="fs-4" />
                                                {Signature && <img src={SignatureURL + Signature} alt="" />}
                                                <input id="inputTag" type="file" name="file"
                                                    ref={FileRef}
                                                    onChange={(e) => { UploadImage(e.target.files[0]) }}
                                                />
                                                <br />
                                                {
                                                    !Signature && <p className="mb-0">Drag file(s) here or <span>click to upload</span></p>
                                                }
                                                <br />
                                                <span id="imageName"></span>
                                            </label>
                                        </div>
                                        {/* {
                                 Signature && <i className="fa-solid fa-trash ms-3 mt-2 all_icons_color" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setSignature, { fileName: Signature, type: "SIGNATURE", id: otherDetails?.settingId }, true)}></i>
                             } */}
                                        {
                                            Signature && <p className=" m-0 ms-2 mt-2 all_icons_color text-danger" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setSignature, { fileName: Signature, type: "SIGNATURE", id: otherDetails?.settingId }, true)}>Delete</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="row">
                <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-2">
                    <CustomButton variant="contained" onClick={formik.handleSubmit} >Save</CustomButton>
                </div>
            </div>
        </div>

    </>
}

export default Other