import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useReduxData from "../../../Hooks/useReduxData";
import { ADD_CATEGORY, DELETE_TEMPLATE_FORM, TEMPLATE_FORM_LIST, TEMPLATE_FORM_TOGGLE_STATUS, UPDATE_CATEGORY } from "../../Repository/TemplateLibraryRepo";
import { toast } from "react-toastify";
import { RoleTitle } from "../../../Context/constants";
import { useFormik } from "formik";
import { CreateCategoryValidation } from "../../../Context/Validation";

const useTemplateLibrary = () => {
  const [loader, setLoader] = useState(false)
  const [TotalCount, setTotalCount] = useState(0)
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
  const [templateFomrList, setTemplateFomrList] = useState([])
  const [Filter, setFilter] = useState({
    archive: "ALL",
    category: "",
    disable_button: true
  })
  const [openModal, setOpenModal] = useState({
    deleteForm: false,
    formView:false,
    data: null
  })
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const splitLocation = pathname?.split("/")
  const { reduxData: userdata } = useReduxData("userDataRDX")

  const headCells = [
    { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
    { id: "formCategoryName", numeric: true, disablePadding: false, label: "Form Name", isShorted: true },
    { id: "name", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
    { id: "status", numeric: false, disablePadding: false, showLeft: true, label: "Status", isShorted: false },
    { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
  ];


  // vaccination template form  list
  async function getTemplateList() {
    setLoader(true)
    const { page, rowsPerPage, searchPage } = controller;
    const { archive, category } = Filter;
    const keyword = category.trim();
    const payload = {
      searchKeyword: (searchPage && keyword) ? keyword : undefined,
      page: page,
      count: rowsPerPage,
      deletedStatus: false,
      activeStatus: archive === "ALL" ? undefined : archive,
      providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
    };
    try {
      let response = await TEMPLATE_FORM_LIST(payload)
      setTemplateFomrList(response?.data)
      setTotalCount(response?.count)
      setLoader(false)
    } catch (error) {
      if (error.message !== "No details found") toast.error(error.message)
      setTemplateFomrList([])
      setTotalCount(0)
      setLoader(false)
    }
  }
  const handleOpenModal = (type, data = null) => {
    setOpenModal((prev) => ({
      ...prev,
      data: data,
      [type]: true,
    }));
  };
  const handleClosedModal = (type) => {
    setOpenModal((pre) => ({
      ...pre,
      data: null,
      [type]: false
    }))
  }

  const handleDeleteForm = async (event) => {
    if (event === "YES") {
      setLoader(true)
      const payload = {
        formId: openModal.data?.formId,
        deletedStatus: !openModal.data?.deletedStatus
      }
      setLoader(true)
      try {
        let response = await DELETE_TEMPLATE_FORM(payload)
        toast.success(response.message)
        if (templateFomrList?.length === 1 && controller.page === 1) {
          getTemplateList()
        }
        else if (templateFomrList?.length === 1) {
          setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
        } else {
          getTemplateList()
        }
        setLoader(false)
        handleClosedModal("deleteForm")
      } catch (error) {
        toast.error(error.message);
        setLoader(false)
      }

    } else {
      handleClosedModal("deleteCategory")
    }
  }

  const handleToggleStatus = async (event, data) => {
    const { checked } = event.target
    let payload = {
      formId: data?.formId,
      activeStatus: checked
    }
    try {
      let response = await TEMPLATE_FORM_TOGGLE_STATUS(payload)
      toast.success(response.message)
      getTemplateList()
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }

  const handleChangeFilter = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
  }
  const HandleSearchList = () => {
    setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
  }
  const reset = () => {
    setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    setFilter({ archive: "ALL", category: "", disable_button: true })
  }

  useEffect(() => {
    getTemplateList()
    navigate({ replace: true, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])
  return {
    handleChangeFilter,
    HandleSearchList,
    handleOpenModal,
    handleClosedModal,
    setController,
    reset,
    handleDeleteForm,
    handleToggleStatus,
    navigate,
    splitLocation,
    headCells,
    controller,
    Filter,
    TotalCount,
    templateFomrList,
    loader,
    openModal,
  }
}

export default useTemplateLibrary