import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { TIME_ZONE_LIST, UPDATE_SETTING, VIEW_SETTING_DETAILS } from "../../Repository/SettingRepo"
import { useFormik } from "formik"
import { currencyOptions, fileTypes, RoleTitle } from "../../../Context/constants"
import { UPLOAD_IMAGE } from "../../Repository/CommonRepo"
import { settingValidation } from "../../../Context/Validation"
import useReduxData from "../../../Hooks/useReduxData"
import { updateTimezone } from "../../../Redux/ReducerFile/UserData"


const useOther = () => {
    const [timeZone, setTimeZone] = useState([])
    const [loading, setLoading] = useState(false)
    const [Signature, setSignature] = useState(null)
    const [otherDetails, setOtherDetails] = useState(null)
    const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
    const FileRef = useRef()
    useEffect(() => {
        listTimeZone()
        getDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const listTimeZone = async () => {
        setLoading(true)
        const payload = {
            activeStatus: true,
        };
        try {
            let response = await TIME_ZONE_LIST(payload)
            setTimeZone(response.data)
        } catch (error) {
            toast.error(error.message)
            setTimeZone([])
        }
        finally {
            setLoading(false)
        }
    }
    const getDetails = async () => {
        setLoading(true)
        let payload = {
            providerId: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata?.userId : userdata?.providerId,
        }
        try {
            let response = await VIEW_SETTING_DETAILS(payload)
            let currencyData = currencyOptions?.filter((cur, index) => response?.data?.currency === cur?.value)
            formik.setFieldValue("timezone", response?.data?.timezone ? [response?.data?.timezone] : [])
            formik.setFieldValue("currency", currencyData)
            setSignature(response?.data?.digitalSignature)
            dispatch(updateTimezone({ timezone: response?.data?.timezone?.timezone, }))
            setOtherDetails(response?.data)
            setLoading(false)
        } catch (error) {
            toast.error(error.message)
            setLoading(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            timezone: [],
            currency: []
        },
        validationSchema: settingValidation,
        onSubmit: async (values) => {
            let payload = {
                providerId: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata?.userId : userdata?.providerId,
                timezone: values?.timezone[0]?.timezone,
                currency: values?.currency[0]?.value,
                digitalSignature: Signature
            }
            setLoading(true)
            try {
                let response = await UPDATE_SETTING(payload)
                toast.success(response?.message)
                setLoading(false)
                getDetails()

            } catch (error) {
                toast.error(error.message)
                setLoading(false)
            }
        }
    })
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        formik.setFieldValue(name, newValue)
    }
    const UploadImage = async (event) => {
        const fileData = event;
        const fd = new FormData()
        if (fileData?.size / 1024 / 1024 > 10) return toast.warn("Please upload image less than 10 MB");
        if (!fileTypes.Img_Type.includes(fileData?.type)) return toast.warn("Only image format allowed");
        if (fileData?.size / 1024 / 1024 < 10 && fileTypes.Img_Type.includes(fileData?.type)) {
            fd.append('type', "SIGNATURE")
            fd.append('file', fileData)
            fd.append('previousFileName', Signature)
            fd.append('id', otherDetails?.settingId)
            setLoading(true)
            try {
                let response = await UPLOAD_IMAGE(fd)
                setSignature(response?.data?.db_name)
                FileRef.current.value = null;
                setLoading(false)
            } catch (error) {
                toast.error(error.message)
                setSignature(null)
                setLoading(false)
            }
        }
    };
    console.log(timeZone, "timeZone")
    return {
        timeZone,
        loading,
        formik,
        FileRef,
        Signature,
        handalSearchAbleSelectBox,
        setLoading,
        setSignature,
        UploadImage,
        otherDetails,
        userdata,
    }
}

export default useOther