import React, { useEffect } from 'react'
import { Errors } from '../../Component/Error';
import { Avatar, Badge, styled } from '@mui/material';
import Loading from '../../Component/Loading';
import useProfile from './Hooks/useProfile';
import {
    //  userBasicProfileURL,
    userImageFilePath
} from '../../Context/api';
// import PhoneNumber from '../../Component/PhoneInput';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { TitleOptions } from '../../Context/constants';
import { SelectBox } from '../../Component/Inputs';
import { MdOutlineCameraAlt } from "react-icons/md";
import DeleteProfile from '../../Component/DeleteProfile';
import { FaArrowLeft } from 'react-icons/fa';
import TextBox from '../../Component/TextBox';
const EditProfile = () => {
    const {
        categoryList,
        tab,
        formik,
        imgUrl,
        loader,
        updateBusinessDetails,
        // userdata,
        // basicDetailsFrofileImage,
        // setBasicDetailsFrofileImage,
        FileRef,
        ChangeTab,
        setLoader,
        UploadImage,
        navigate,
        setImgUrl,
    } = useProfile()
    const { CustomButton } = useMuiCompontent()
    const updateClinic = JSON.parse(localStorage.getItem("addClinic"))
    useEffect(() => {
        if (updateClinic) {
            ChangeTab("BusinessDetails")
            localStorage.removeItem("addClinic");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className=" d-flex justify-content-start align-items-center" >
                        <FaArrowLeft className="me-2 cursor" onClick={() => navigate("/Profile")} />
                        <h2 className='page_name'> Edit Profile</h2>
                    </div>
                </header>
                <div className="common-white-bg-box mx-0 shadow PROVIDER_PROFILE px-2">
                    <div className="col-12 h-100 py-3 d-flex justify-content-between flex-column ">
                        <div>
                            <div className="col-12 mt-2" style={{ borderBottom: "2px solid #ededed" }}>
                                <ul className="nav  mb-0  pb-1 " >
                                    <li className="nav-item" role="presentation">
                                        <button className={`teams_frofile_stepper_btn ${tab === 1 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("BasicDetails")} >Basic Detials</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`teams_frofile_stepper_btn me-2 ${tab === 2 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => ChangeTab("BusinessDetails")} >Business Details</button>
                                    </li>
                                </ul>
                            </div>
                            {
                                tab === 1 && <div className="col-12">
                                    <div className="row px-2">
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            {/* <div className="row">
                                                <div className="col-3">
                                                    <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-4">
                                                    <SelectBox
                                                        placeholder="Select Title"
                                                        value={formik?.values?.title.trim()}
                                                        name="title"
                                                        keys="value"
                                                        label="label"
                                                        menuValeu="value"
                                                        handleChange={(e) => formik.setFieldValue("title", e.target.value)}
                                                        option={TitleOptions}
                                                    />
                                                    <Errors formikfun={formik} name={"title"} />
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <SelectBox
                                                                placeholder="Select Title"
                                                                value={formik?.values?.title.trim()}
                                                                name="title"
                                                                keys="value"
                                                                label="label"
                                                                menuValeu="value"
                                                                handleChange={(e) => formik.setFieldValue("title", e.target.value)}
                                                                option={TitleOptions}
                                                            />
                                                            <Errors formikfun={formik} name={"title"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            {/* <div className="row">
                                                <div className="col-3">
                                                    <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-4">
                                                    <input type="text" maxLength={50} name="firstName" className="form-control"  {...formik.getFieldProps("firstName")} />
                                                    <Errors formikfun={formik} name={"firstName"} />
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="text" maxLength={50} name="firstName" className="form-control"
                                                                value={formik?.values?.firstName}
                                                                onChange={(e) => formik.setFieldValue("firstName", e.target.value)} />
                                                            <Errors formikfun={formik} name={"firstName"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            {/* <div className="row">
                                                <div className="col-3">
                                                    <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-4">
                                                    <input type="text" maxLength={50} name="lastName" className="form-control"  {...formik.getFieldProps("lastName")} />
                                                    <Errors formikfun={formik} name={"lastName"} />
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="text" maxLength={50} name="lastName" className="form-control"
                                                                value={formik?.values?.lastName}
                                                                onChange={(e) => formik.setFieldValue("lastName", e.target.value)} />
                                                            <Errors formikfun={formik} name={"lastName"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            {/* <div className="row">
                                                <div className="col-3">
                                                    <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-4 mobile_number_input_box">
                                                    <div className="input_group_mobile mb-0">
                                                        <PhoneNumber
                                                            width={false}
                                                            Require={false}
                                                            Name="mobile"
                                                            HandleChange={formik}
                                                            Value={formik.values.mobile}
                                                            disabledInput={true}
                                                        />
                                                        <Errors formikfun={formik} name={"mobile"} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                                        </div>
                                                        <div className="col-lg-7 mobile_number_input_box">
                                                            <div className="input_group_mobile mb-0">
                                                            <TextBox textBoxData={<p>{formik.values.mobile}</p>} />
                                                                {/* <PhoneNumber
                                                                    width={false}
                                                                    Require={false}
                                                                    Name="mobile"
                                                                    HandleChange={formik}
                                                                    Value={formik.values.mobile}
                                                                    disabledInput={true}
                                                                />
                                                                <Errors formikfun={formik} name={"mobile"} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            {/* <div className="row">
                                                <div className="col-3">
                                                    <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-4">
                                                    <input maxLength={50} disabled={true} type="email" className="form-control" name="email"
                                                        {...formik.getFieldProps("email")}
                                                    />
                                                    <Errors formikfun={formik} name={"email"} />
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <TextBox textBoxData={<p>{formik?.values?.email}</p>} />
                                                            {/* <input maxLength={50} disabled={true} type="email" className="form-control" name="email"
                                                                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                                                                value={formik?.values?.email}
                                                            />
                                                            <Errors formikfun={formik} name={"email"} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Your Image</label>
                                                            <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
                                                        </div>
                                                        <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                                            <Badge
                                                                style={{ background: "#fff" }}
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                badgeContent={
                                                                    <SmallAvatar alt="Remy Sharp"  >
                                                                        <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                                        </MdOutlineCameraAlt>
                                                                        <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "PROVIDER", setLoader, setBasicDetailsFrofileImage)} />
                                                                    </SmallAvatar>
                                                                }
                                                            >
                                                                <Avatar  sx={{ width: 80, height: 80 }} src={userBasicProfileURL + basicDetailsFrofileImage} >

                                                                </Avatar>
                                                            </Badge>
                                                            {basicDetailsFrofileImage && <DeleteProfile setImgUrl={setBasicDetailsFrofileImage} deletedData={{ fileName: basicDetailsFrofileImage, type: "PROVIDER", id: userdata?.userId }} isDeleted={true} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            }
                            {tab === 2 && <div className="col-12">
                                <div className="row px-2">
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        {/* <div className="row">
                                            <div className="col-3">
                                                <label className="form-label mb-0">Business Name<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="businessName"
                                                    maxLength={50}
                                                    {...updateBusinessDetails.getFieldProps("businessName")}
                                                />
                                                <Errors formikfun={updateBusinessDetails} name={"businessName"} />
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Business Name<span className='Star_Mark'>*</span></label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="businessName"
                                                            maxLength={100}
                                                            onChange={(e) => updateBusinessDetails.setFieldValue("businessName", e.target.value)}
                                                            value={updateBusinessDetails?.values?.businessName}
                                                        />
                                                        <Errors formikfun={updateBusinessDetails} name={"businessName"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        {/* <div className="row">
                                            <div className="col-3">
                                                <label className="form-label mb-0">Display Picture</label>
                                                <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
                                            </div>
                                            <div className="col-4 d-flex  align-items-start flex-column">
                                                <Badge
                                                    style={{ background: "#fff" }}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <SmallAvatar alt="Remy Sharp"  >
                                                            <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                            </MdOutlineCameraAlt>
                                                            <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "BUSINESSPROFILE", setLoader, setImgUrl)} />
                                                        </SmallAvatar>
                                                    }
                                                >
                                                    <Avatar  sx={{ width: 80, height: 80 }} src={userImageFilePath + imgUrl} >
                                                    </Avatar>
                                                </Badge>
                                                {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "BUSINESSPROFILE", id: updateBusinessDetails?.values?.BusinessProfileId }} isDeleted={true} />}
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Display Picture</label>
                                                        <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
                                                    </div>
                                                    <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                                        <Badge
                                                            style={{ background: "#fff" }}
                                                            overlap="circular"
                                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                            badgeContent={
                                                                <SmallAvatar alt="Remy Sharp"  >
                                                                    <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                                    </MdOutlineCameraAlt>
                                                                    <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "BUSINESSPROFILE", setLoader, setImgUrl, imgUrl, updateBusinessDetails?.values?.BusinessProfileId)} />
                                                                </SmallAvatar>
                                                            }
                                                        >
                                                            <Avatar sx={{ width: 80, height: 80 }} src={userImageFilePath + imgUrl} >
                                                            </Avatar>
                                                        </Badge>
                                                        {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "BUSINESSPROFILE", id: updateBusinessDetails?.values?.BusinessProfileId }} isDeleted={true} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        {/* <div className="row">
                                            <div className="col-3">
                                                <label className="form-label mb-0">Business Category<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-4">
                                                <SelectBox
                                                    placeholder="Select Category"
                                                    value={updateBusinessDetails?.values?.businessCategoryId}
                                                    name="businessCategoryId"
                                                    keys="businessCategoryId"
                                                    label="categoryName"
                                                    menuValeu="businessCategoryId"
                                                    handleChange={(e) => updateBusinessDetails.setFieldValue("businessCategoryId", e.target.value)}
                                                    option={categoryList}
                                                />
                                                <Errors formikfun={updateBusinessDetails} name={"businessCategoryId"} />
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Business Category<span className='Star_Mark'>*</span></label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <SelectBox
                                                            placeholder="Select Category"
                                                            value={updateBusinessDetails?.values?.businessCategoryId}
                                                            name="businessCategoryId"
                                                            keys="businessCategoryId"
                                                            label="categoryName"
                                                            menuValeu="businessCategoryId"
                                                            handleChange={(e) => updateBusinessDetails.setFieldValue("businessCategoryId", e.target.value)}
                                                            option={categoryList}
                                                        />
                                                        <Errors formikfun={updateBusinessDetails} name={"businessCategoryId"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        {/* <div className="row">
                                            <div className="col-3">
                                                <label className="form-label mb-0">About Us<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-4 mobile_number_input_box">
                                                <div className="input_group_mobile mb-0">
                                                    <textarea
                                                        className="form-control"
                                                        id="exampleFormControlTextarea1"
                                                        rows="4"
                                                        name="about"
                                                        {...updateBusinessDetails.getFieldProps("about")}

                                                    ></textarea>
                                                    <Errors formikfun={updateBusinessDetails} name={"about"} />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">About Us<span className='Star_Mark'>*</span></label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <textarea
                                                            className="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="4"
                                                            name="about"
                                                            onChange={(e) => updateBusinessDetails.setFieldValue("about", e.target.value)}
                                                            value={updateBusinessDetails?.values?.about}

                                                        ></textarea>
                                                        <Errors formikfun={updateBusinessDetails} name={"about"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="row">
                            <div className='col-lg-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end '>
                                <div className="d-flex ">
                                    <CustomButton variant="outlined" type='button' className='me-3' onClick={() => navigate("/profile")} > cancel</CustomButton>
                                    {
                                        tab === 1 && <CustomButton variant="contained" type='button' onClick={formik.handleSubmit} > update</CustomButton>
                                    }
                                    {
                                        tab === 2 && <CustomButton variant="contained" type='button' onClick={updateBusinessDetails.handleSubmit} > update</CustomButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default EditProfile
