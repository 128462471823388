import moment from "moment-timezone";
import React from "react";
import Loading from "../../Component/Loading";
import { TableContainer, Table, TableBody, TableCell, TableRow, } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import Man from '../../Assets/Images/dummyimg.png';
import { PatientImageFilePath } from "../../Context/api";
import { addAgendaDetails } from "../../Redux/ReducerFile/AgendaDetails";
import { NoRecordFound } from "../../Component/NoRecordFound";
import useTodayAgenda from "./Hooks/useTodayAgenda";
import useTableSorting from "../../Hooks/useTableSorting";
import MoreVertIcon from '@mui/icons-material/MoreVert';
const TodayAgenda = () => {
    const {
        navigate,
        dispatch,
        setController,
        setAnchorEl,
        userdata,
        paginationTotalCount,
        controller,
        loader,
        apptList,
        headCells,
        isFrontDeskPermission
    } = useTodayAgenda()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-start align-items-center">
                        <h2 className="page_name">Today Agenda</h2>
                    </div>
                </header>
                <div className="common-white-bg-box  mx-0 shadow px-2">
                    <TableContainer >

                        {apptList?.length === 0 && <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /> </div>}
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                apptList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={apptList?.length} />
                            }
                            <TableBody className="table_overflow_scroll" >
                                {stableSort(apptList, getComparator(order, orderBy)).map(
                                    (item, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return <React.Fragment key={index}><TableRow id="hover_tr">
                                            <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                <img alt={item?.patientFirstName} src={item.patientProfilePicture === null ? Man : PatientImageFilePath + item.patientProfilePicture} width={45} height={45} style={{ borderRadius: "50%" }} />
                                            </TableCell>
                                            <TableCell id={labelId} align="left">{(item?.patientFirstName ? item?.patientFirstName : "") + " " + (item?.patientLastName ? item?.patientLastName : "")}</TableCell>
                                            <TableCell id={labelId} align="left" >{moment(item?.startDate)?.tz(userdata?.timezoneName)?.format('DD-MM-YYYY')} / {moment(item?.startDate)?.tz(userdata?.timezoneName)?.format('LT')} - {moment(item?.endDate)?.tz(userdata?.timezoneName)?.format('LT')}</TableCell>
                                            <TableCell id={labelId} align="left">{item?.specialisationName}</TableCell>
                                            <TableCell id={labelId} align="left">{item?.serviceName}</TableCell>
                                            <TableCell id={labelId} align="left">{item?.visitType}</TableCell>
                                            <TableCell id={labelId} align="right">
                                                <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                <ul className="dropdown-menu dropdown-menu-end me-3">
                                                    <li className="cursor"><button className="dropdown-item"
                                                        onClick={() => {
                                                            setAnchorEl(null);
                                                            navigate("overview")
                                                            dispatch(addAgendaDetails({
                                                                image: item?.patientProfilePicture,
                                                                name: item?.patientFirstName + " " + item?.patientLastName,
                                                                id: item?.patientId,
                                                                apptId: item?.appointmentId,
                                                                apptNumber: item?.appointmentNumber,
                                                                prescriptionNumber: item?.prescriptionNumber
                                                            }))
                                                        }} >Overview</button></li>
                                                    <li className="cursor"><button className="dropdown-item"
                                                            onClick={() => {
                                                                setAnchorEl(null);
                                                                navigate(`${item?.patientId}`);
                                                                dispatch(addAgendaDetails({
                                                                    image: item?.patientProfilePicture,
                                                                    name: item?.patientFirstName + " " + item?.patientLastName,
                                                                    id: item?.patientId,
                                                                    apptId: item?.appointmentId,
                                                                    apptNumber: item?.appointmentNumber,
                                                                    prescriptionNumber: item?.prescriptionNumber
                                                                }))
                                                            }}>Checkout Patient</button></li>
                                                    
                                                </ul>
                                            </TableCell>
                                            
                                        </TableRow>
                                        </React.Fragment>
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <nav className=" w-100 " aria-label="pagination">
                        {
                            paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />
                        }
                    </nav>
                </div>
            </div>

        </>
    );
};

export default TodayAgenda;

