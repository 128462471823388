import React from 'react'
import useTemplateLibrary from './Hooks/useTemplateLibrary'
import useTableSorting from '../../Hooks/useTableSorting'
import useCategory from './Hooks/useCategory'
import { SelectBox } from '../../Component/Inputs'
import { activeStatusOptionWithAll } from '../../Context/constants'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { IconButton, Table, TableBody, TableContainer, TableRow, TableCell } from '@mui/material'
import { FiRefreshCcw } from 'react-icons/fi'
import { NoRecordFound } from '../../Component/NoRecordFound'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import Loading from '../../Component/Loading'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from 'react-bootstrap'
import { Errors } from '../../Component/Error'
import ViewForm from './ViewForm'
const TemlpateList = () => {

  const {
    handleChangeFilter,
    HandleSearchList,
    handleOpenModal,
    handleClosedModal,
    setController,
    handleDeleteForm,
    reset,
    handleToggleStatus,
    headCells,
    controller,
    Filter,
    TotalCount,
    templateFomrList,
    loader,
    openModal,
    navigate
  } = useTemplateLibrary()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  const { CustomButton, GreenSwitch } = useMuiCompontent()
  return (
    <>
      {loader && <Loading />}
      <div>
        <header className="body_header d-flex justify-content-between align-items-center py-2 ">
          <ul className="headerlist px-0">
            <li className='phone_hide me-2'>
              <input type="text" onChange={handleChangeFilter} value={Filter?.category} placeholder="Search" className="form-control filter_input" name="category" />
            </li>
            <li className="me-2" style={{ width: 150 }}>
              <SelectBox
                placeholder="Select"
                value={Filter?.archive}
                name="archive"
                keys="value"
                label="label"
                menuValeu="value"
                handleChange={handleChangeFilter}
                option={activeStatusOptionWithAll}
              />
            </li>
            <li className='phone_hide me-2'>
              <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
            </li>
            <li className="me-2 d-flex justify-content-center align-items-center" >
              <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                <FiRefreshCcw />
              </IconButton>
            </li>
            <li className='me-2'>
              <CustomButton variant="contained" onClick={() => navigate("create-form")} >add Form</CustomButton>
            </li>
          </ul>
        </header>
        <TableContainer   >
          {templateFomrList.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
          <Table
            id="table_mui"
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table" >
            {
              templateFomrList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={templateFomrList?.length} />
            }
            <TableBody className="table_overflow_scroll" >
              {stableSort(templateFomrList, getComparator(order, orderBy))?.map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <React.Fragment key={index}><TableRow id="hover_tr" style={{ cursor: 'pointer' }}>
                    <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                      {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                    </TableCell>
                    <TableCell id={labelId} align="left" onClick={() => handleOpenModal("formView", row)}> <span style={{ color: '#0c78ff' }}>{row?.name}</span></TableCell>
                    <TableCell id={labelId} align="left" >{row?.formCategoryName}</TableCell>
                    <TableCell id={labelId} align="right"  >
                      <GreenSwitch checked={row?.activeStatus} size='small' onChange={(e) => handleToggleStatus(e, row)} />
                    </TableCell>
                    <TableCell id={labelId} align="right">
                      <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                      <ul className="dropdown-menu dropdown-menu-end me-3">
                        <li><button className="dropdown-item" onClick={() => navigate(`${row?.formId}`)} ><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Form</button></li>
                        <li><button className="dropdown-item" onClick={() => handleOpenModal("deleteForm", row)}>  <i title="Delete Form" className="fa-solid fa-trash-can all_icons_color me-2"></i>Delete Form</button></li>
                      </ul>
                    </TableCell>
                  </TableRow>
                  </React.Fragment>
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <nav className=" w-100  " aria-label="pagination">
        {TotalCount > 10 && <Peginationcomponent count={TotalCount} controller={controller} setController={setController} />}
      </nav>
      <Modal show={openModal?.deleteForm} onHide={() => handleClosedModal("deleteForm")} centered>
        <Modal.Body className='px-0'>
          <div className="modal-body text-center cus-i px-0">
            <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
            <p className='mb-0'>Are you sure?</p>
            <p>You want to delete this template form</p>
            <CustomButton variant="contained" className="me-3" onClick={() => handleDeleteForm("YES")} > Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => handleDeleteForm("NO")} > No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>

      <Modal size='lg' show={openModal?.formView} onHide={() => { handleClosedModal("formView") }} centered>
        <Modal.Body>
          <div className="text-center">
            <p className="modal-title mb-0">View Form</p>
          </div>
          <hr />
          <div style={{ background: "#ecf3f3", height: "500px", overflow: "auto" }} className='w-100 p-2'>
            <ViewForm viewFieldsArry={openModal?.data?.fields} allDetails={openModal?.data} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TemlpateList
