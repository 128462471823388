import React, { memo } from 'react'
import { SelectBox } from "../../../Component/Inputs";
import { RoleTitle, VisitTypeOptions } from '../../../Context/constants';
import { Modal } from 'react-bootstrap';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TextBox from '../../../Component/TextBox';
const RescheduleAppointment = ({ clossOffcanvas, modalReschdule, apptDetails, userdata, moment, appointmentReschedule, TimeslotList, SlotLodaer, isActiveSlot,
  setIsactiveSlot, CustomButton }) => {
  return (

    <Modal size='xl' modal="true" show={modalReschdule} onHide={() => clossOffcanvas("RESCHEDULE_APPOINTMENT")} centered >
      <Modal.Body>
        {/* <div className="text-start">
          <h5 className="modal-title mb-0">Reschedule Appointment</h5>
        </div> */}
        <div className="d-flex justify-content-between">
          <p className="modal-title mb-0 page_name">Reschedule Appointment</p>
          <span className='cursor' onClick={() => clossOffcanvas("RESCHEDULE_APPOINTMENT")}> <ClearOutlinedIcon /></span>
        </div>
        <div className="row modalform">
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Clinic<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" className="form-control" value={apptDetails.clinicName} disabled />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Clinic<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <TextBox textBoxData={<p>{apptDetails.clinicName}</p>} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" className="form-control" value={apptDetails.serviceName} disabled />

              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <TextBox textBoxData={<p>{apptDetails.specialisationName}</p>} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" className="form-control" value={apptDetails.serviceName} disabled />

              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <TextBox textBoxData={<p>{apptDetails.serviceName}</p>} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Patients Name<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" className="form-control" value={apptDetails.patientFirstName + " " + apptDetails.patientFirstName} disabled />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Patients Name<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <TextBox textBoxData={<p>{(apptDetails?.patientFirstName ? apptDetails?.patientFirstName : "") + " " + (apptDetails?.patientLastName ? apptDetails?.patientLastName : '')}</p>} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          {
            (userdata.roleTitle === RoleTitle?.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle?.FRONT_DESK) ?
              <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                {/* <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Staff<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-4">
                    <input type="text" className="form-control" value={apptDetails.doctorFirstName + " " + apptDetails.doctorLastName} disabled />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-5">
                        <label className="form-label mb-0">Doctor<span className='Star_Mark'>*</span></label>
                      </div>
                      <div className="col-lg-7">
                        <TextBox textBoxData={<p>{apptDetails.doctorFirstName + " " + apptDetails.doctorLastName}</p>} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                  </div>
                </div>
              </div> : null
          }
          <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="date" className="form-control position-relative" name="apptDate" value={moment(appointmentReschedule?.values?.apptDate)?.tz(userdata?.timezoneName)?.format("YYYY-MM-DD")} onChange={appointmentReschedule.handleChange} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="date" className="form-control position-relative" name="apptDate" value={appointmentReschedule?.values?.apptDate ? moment(appointmentReschedule?.values?.apptDate)?.tz(userdata?.timezoneName)?.format("YYYY-MM-DD") : ""}
                      onChange={(e) => {
                        appointmentReschedule.setFieldValue('apptDate', e.target.value);
                        setIsactiveSlot(null)
                        appointmentReschedule.setFieldValue("apptTimeIn", "");
                        appointmentReschedule.setFieldValue("apptTimeOut", "")
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
            <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Slots Available<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-9">
                {appointmentReschedule?.values?.apptDate && appointmentReschedule?.values?.teamMemberId && TimeslotList.length === 0 && SlotLodaer === false && <p className=" text-start mb-0">No Slot Available</p>}
                {appointmentReschedule?.values?.apptDate && appointmentReschedule?.values?.teamMemberId && TimeslotList.length === 0 && SlotLodaer === true && <div className="w-100 d-flex justify-content-start">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>}
                <div className="row show_list_time_slot d-flex justify-content-start " style={{ height: TimeslotList.length > 0 && "150px" }}>
                  <div className="col pb-0">
                    {
                      appointmentReschedule.values.teamMemberId && appointmentReschedule.values.apptDate && TimeslotList?.map((item, index) => {
                        return <React.Fragment key={index}>
                          <button
                            type="button"
                            disabled={item.status === 'Booked' || item.status === 'Unavailable' ? true : false}
                            style={{ backgroundColor: (isActiveSlot === index ? '#38C4B9' : '#fff'), color: (item.status === 'Booked' || item.status === 'Unavailable' ? 'grey' : (isActiveSlot === index ? '#fff' : '#38C4B9')), width: '160px', fontSize: "14px", height: "47px", padding: "5px", border: (item.status === 'Booked' || item.status === 'Unavailable' ? '1px solid grey' : '1px solid #38C4B9') }}
                            className="btn mt-2 me-2 "
                            onClick={() => {
                              setIsactiveSlot(index);
                              appointmentReschedule.setFieldValue("apptTimeIn", item.startTime);
                              appointmentReschedule.setFieldValue("apptTimeOut", item.endTime)
                            }}
                          >
                            {moment(item.startTime).tz(userdata?.timezoneName).format('LT')} - {moment(item.endTime).tz(userdata?.timezoneName).format('LT')} {(item.status === 'Booked') ? 'Booked' : (item.status === 'Unavailable') ? 'Unavailable' : null}
                          </button>
                        </React.Fragment>
                      })
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Visit Type<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <SelectBox
                  placeholder="Select Visit Type"
                  value={appointmentReschedule.values.visitType}
                  name="visitType"
                  keys="value"
                  label="label"
                  menuValeu="value"
                  handleChange={(e) => appointmentReschedule.setFieldValue("visitType", e.target.value)}
                  option={VisitTypeOptions}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Visit Type<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <SelectBox
                      placeholder="Select Visit Type"
                      value={appointmentReschedule.values.visitType}
                      name="visitType"
                      keys="value"
                      label="label"
                      menuValeu="value"
                      handleChange={(e) => appointmentReschedule.setFieldValue("visitType", e.target.value)}
                      option={VisitTypeOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pt-3 " >
            <CustomButton variant="contained" type="button" onClick={() => {
              appointmentReschedule.handleSubmit();
            }} >Reschedule Appointment</CustomButton>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  )
}

export default memo(RescheduleAppointment)
