import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import { TableContainer } from '@mui/material';
import { Peginationcomponent } from '../../Component/Peginationcomponent';
import Loading from '../../Component/Loading';
import useTableSorting from '../../Hooks/useTableSorting';
import useReduxData from '../../Hooks/useReduxData';
import { NoRecordFound } from '../../Component/NoRecordFound';
import { APPOINTMENT_HISTORY } from '../Repository/PatientRepo';
import { RoleTitle } from '../../Context/constants';
import { ASSIGNED_TEMPLATE_FORM_LIST, TEMPLATE_FORM_LIST } from '../Repository/TemplateLibraryRepo';
import ViewForm from '../TemplateLibrary/ViewForm';
const PatientSharedForm = ({ type }) => {
    const [sharedTemplateFormList, setSharedTemplateFormList] = useState([])
    const [count, setCount] = useState(0)
    const [apptDetails, setApptDetails] = useState(null)
    const [modalView, setModalView] = useState(false);
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [openModal, setOpenModal] = useState({ open: false, data: null })
    const { id, subpatientId } = useParams()
    const [loader, setloader] = useState(false)
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    useEffect(() => {
        getSharedTemplateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    const getSharedTemplateForm = async () => {
        setloader(true)
        const payload = {
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
            frontdeskId: userData.roleTitle === RoleTitle.FRONT_DESK ? userData?.userId : undefined,
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            page: controller.page,
            count: controller.rowsPerPage,
            // filter:""  //FILLED,
            appointmentId: ""
        };
        try {
            let response = await ASSIGNED_TEMPLATE_FORM_LIST(payload)
            setSharedTemplateFormList(response?.data)
            setloader(false)
            setCount(response?.count)
        } catch (error) {
            setCount(0)
            setSharedTemplateFormList([])
            setloader(false)
        }
    }

    const apptView = (data) => {
        setModalView(true)
        setApptDetails(data)
    }
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Form Name", isShorted: true },
        { id: "formCategoryName", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
        { id: "formFillTime", numeric: true, disablePadding: false, label: "Fill Time", isShorted: true },
        { id: "Status", numeric: true, disablePadding: false, label: "Status", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between " style={{ height: "100%" }}>
                <div>
                    {/* <header className="body_header mb-0" >
                        <div className="d-flex justify-content-end align-items-center">
                            <div>
                                <ul className="headerlist">
                                    <li>
                                        <div className="dropdown-center">
                                            <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <CustomButton variant="contained" className='mt-2'     startIcon={<IoFilterSharp />} style={{ background: "#f5f5f5", color: "black" }}  >Filters</CustomButton>
                                            </div>
                                            <ul className="dropdown-menu dropdown-menu-end shadow">
                                                <li><button className="dropdown-item " style={{ cursor: "pointer" }} onClick={() => setStatus('')}>All</button></li>
                                                <li><button className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setStatus('Booked')}>Booked Appointments</button></li>
                                                <li><button className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setStatus('Cancelled')}>Cancelled Appointments</button></li>
                                                <li><button className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setStatus('Rescheduled')}>Rescheduled  Appointments</button></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </header> */}
                    <TableContainer >
                        {sharedTemplateFormList?.length === 0 &&
                            <div className="col-sm-12 mt-3 text-center " >
                                <NoRecordFound />
                            </div>
                        }
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                sharedTemplateFormList.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={sharedTemplateFormList.length} />
                            }
                            <TableBody>

                                {
                                    stableSort(sharedTemplateFormList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow hover id="hover_tr" >
                                                <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                    {controller.rowsPerPage * (controller.page - 1) + index + 1}
                                                </TableCell>
                                                <TableCell id={labelId} align="left">{row.name}</TableCell>
                                                <TableCell id={labelId} align="left">{row.formCategoryName}</TableCell>
                                                <TableCell id={labelId} align="left">{row?.formFillTime ? moment(row?.formFillTime).format('lll') : "-"}</TableCell>
                                                <TableCell id={labelId} align="left">{row.status}</TableCell>
                                                <TableCell id={labelId} align="right" className=''>
                                                    <i className="fa-solid fa-eye me-2 all_icons_color" onClick={() => { setOpenModal(() => ({ open: true, data: row })) }}></i>
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='w-100 3'>
                    {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
                </div>
            </div>

            <Modal size='lg' show={openModal?.open} onHide={() => { setOpenModal(() => ({ open: false, data: null })) }} centered>
                <Modal.Body>
                    <div className="text-center">
                        <p className="modal-title mb-0">View Form</p>
                    </div>
                    <hr />
                    <div style={{ background: "#ecf3f3", height: "500px", overflow: "auto" }} className='w-100 p-2'>
                        <ViewForm viewFieldsArry={openModal?.data?.fields} allDetails={openModal?.data} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PatientSharedForm
