import { ImageBaseURL, envoirment } from "./envoirment";
const Url = "/winkdoc-api/rest/"
//liststatebycountry
export const liststatebycountry = () => envoirment.baseUrl + Url + "api/v2/state/list";
export const DeletefileApi = () => envoirment.baseUrl + Url + "api/v1/file/delete";
// export const pictureInsertAPi = () => envoirment.baseUrl + Url + "api/profilepicture/insert";
export const pictureInsertAPi = () => envoirment.baseUrl + Url + "api/v1/file/upload";
// credential
export const signUpApi = () => envoirment.baseUrl + Url + "api/v2/provider/signup";
export const verifyApi = () => envoirment.baseUrl + Url + "api/v3/verifyOTP";
export const createPinApi = () => envoirment.baseUrl + Url + "api/v3/createPin";
export const resendApi = () => envoirment.baseUrl + Url + "api/v3/resendOTP";
export const loginApi = () => envoirment.baseUrl + Url + "api/v1/Provider/login";
export const forgotpin = () => envoirment.baseUrl + Url + "api/v3/forgotpin";
export const verifynewotp = () => envoirment.baseUrl + Url + "api/v2/verifyOTP";
export const resetpin = () => envoirment.baseUrl + Url + "api/v2/createPin";
export const resendloginotp = () => envoirment.baseUrl + Url + "api/v2/resendOTP";
export const liststate = () => envoirment.baseUrl + Url + "api/v2/state/list";
export const listcountry = () => envoirment.baseUrl + Url + "api/v2/listcountry";
export const clinicProof = () => envoirment.baseUrl + Url + "api/v1/file/upload";
export const clinicPdf = envoirment.baseUrl + `${ImageBaseURL}/ClinicProof/`;
export const clinicPictures = envoirment.baseUrl + `${ImageBaseURL}/ClinicPictures/`;
export const qrCode = () => envoirment.baseUrl + Url + "api/v1/qrcode/clinic";
export const logoutApi = () => envoirment.baseUrl + Url + "api/v2/user/logout";

//Dashboard
export const getProviderDashboardDetails = () => envoirment.baseUrl + Url + "api/v1/provider/dashboard";
export const getStaffDashboardDetails = () => envoirment.baseUrl + Url + "api/v1/staff/dashboard";

//  profile
export const listproviderbasic = () => envoirment.baseUrl + Url + "api/v2/provider/list";
export const listproviderbusiness = () => envoirment.baseUrl + Url + "api/v1/businessprofile/list";
export const updatebasicdetails = () => envoirment.baseUrl + Url + "api/v3/staff/update";
export const updatebusinessdetails = () => envoirment.baseUrl + Url + "api/v1/BusinessProfile/update";
export const addbusinessdetails = () => envoirment.baseUrl + Url + "api/v1/businessprofile/insert";
export const updateclinicdetails = () => envoirment.baseUrl + Url + "api/v1/clinic/update";
export const businessCategory = () => envoirment.baseUrl + Url + "api/v2/businesscategory/list";
export const deleteclinic = () => envoirment.baseUrl + Url + "deleteclinic";
export const listproviderclinics = () => envoirment.baseUrl + Url + "api/v1/Clinic/list";
export const addclinicApi = () => envoirment.baseUrl + Url + "api/v1/clinic/insert";
export const addclinicSignupApi = () => envoirment.baseUrl + Url + "api/v1/signup/clinic/insert";
export const listproviderclinicsbyid = () => envoirment.baseUrl + Url + "api/v1/Clinic/list";
export const accountdeleteAPI = () => envoirment.baseUrl + Url + "api/v1/account/delete";
export const specializationListWithService = () => envoirment.baseUrl + Url + "api/v1/clinic/specialization/services/list";
export const userProfileList = () => envoirment.baseUrl + Url + "api/v1/profiles/list";

export const userImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/BusinessProfilePictures/thumbnail150_217/`;
export const userBasicProfileURL = envoirment.baseUrl + `${ImageBaseURL}/ProviderPictures/thumbnail150_217/`;
// img path
export const imgData = envoirment.baseUrl + "/upload/profiles/";
//  setting
export const setUpSetting = () => envoirment.baseUrl + Url + "api/v1/provider/settings/update";
export const viewSettingDetails = () => envoirment.baseUrl + Url + "api/v1/provider/settings/list";

export const listfaqs = () => envoirment.baseUrl + Url + "listfaqs";
export const updatepin = () => envoirment.baseUrl + Url + "api/v3/resetPin";
export const listnotifications = () => envoirment.baseUrl + Url + "api/v1/provider/settings/notification/list";
export const updateNotifications = () => envoirment.baseUrl + Url + "api/v1/provider/settings/notification/update";
export const timeZoneListApi = () => envoirment.baseUrl + Url + "api/v2/timezone/list";
export const ticketListAPi = () => envoirment.ticketBaseUrl + "/api/external/ticket/list";
export const ticketdetailsAPi = () => envoirment.ticketBaseUrl + "/api/external/ticket/";
export const ticketAddApi = () => envoirment.ticketBaseUrl + "/api/external/ticket";
export const replyApi = () => envoirment.ticketBaseUrl + "/api/external/reply";
export const subcriptionPlanList = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/assigned/list";
export const planHistory = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/assigned/history/list";
export const paymentHistory = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/payment/list";
export const cancelPlan = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/cancel";
export const addDebitCard = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/card/add";
export const listDebitCard = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/card/list";
export const deleteDebitCard = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/card/delete";
export const changeDefaultCard = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/card/updateDefaultCard";
export const updateAutomatedPaymentStatus = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/updateAutomatedPaymentStatus";
export const getAutomatedPaymentStatus = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/cards/getAutomatedPaymentStatus";
export const getPaymentdetails = () => envoirment.baseUrl + Url + "api/v1/razorpay/paymentdetails";
export const chargeAmount = () => envoirment.baseUrl + Url + "api/v1/subscriptionplan/card/charge";
export const createRazorpayUrl = () => envoirment.baseUrl + Url + "api/createOrder";
export const addDynamicRolesApi = () => envoirment.baseUrl + Url + "api/dynamic-roles/add";
export const listDynamicRolesApi = () => envoirment.baseUrl + Url + "api/dynamic-roles/list";
export const getDetailsDynamicRolesApi = () => envoirment.baseUrl + Url + "api/dynamic-roles/list/details";
export const updateDynamicRolesApi = () => envoirment.baseUrl + Url + "api/dynamic-roles/update";
export const statusChnageDynamicRolesApi = () => envoirment.baseUrl + Url + "api/dynamic-roles/toggle-activestatus";
export const DeleteDynamicRolesApi = () => envoirment.baseUrl + Url + "api/dynamic-roles/toggle-deteletedstatus";
export const listStaffDynamicRolesApi = () => envoirment.baseUrl + Url + "api/staff/dynamic-roles/list";

export const ticketAttachment = envoirment.ticketBaseUrl + '/upload/tickets/';
export const SignatureURL = envoirment.baseUrl + `${ImageBaseURL}/SignaturePictures/`;
export const pfdData = envoirment.baseUrl + "/upload/bookingPolicy/";
// services
export const insertservices = () => envoirment.baseUrl + Url + "api/v2/service/insert";
export const listservices = () => envoirment.baseUrl + Url + "api/v2/service/list";
export const updateservicedetails = () => envoirment.baseUrl + Url + "api/v2/service/update";
export const ServiceImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/ServicePictures/`;
// spceselition  
export const insertSpecialization = () => envoirment.baseUrl + Url + "api/v2/specialization/insert";
export const Listspecialization = () => envoirment.baseUrl + Url + "api/v2/specialization/list";
export const UpdateSpecialization = () => envoirment.baseUrl + Url + "api/v2/specialization/update";
export const SpecializationImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/SpecializationPictures/`;
// Offers
export const listservicesforoffers = () => envoirment.baseUrl + Url + "listservicesforoffers";
export const insertoffers = () => envoirment.baseUrl + Url + "offers/insertOffer";
export const listoffers = () => envoirment.baseUrl + Url + "offers/listOffers";
export const viewoffer = () => envoirment.baseUrl + Url + "offers/viewOffer";
export const updateoffers = () => envoirment.baseUrl + Url + "offers/updateOffer";
export const OfferImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/OfferPictures/thumbnail150_217/`;
// Availability
export const listavailabilityclinic = () => envoirment.baseUrl + Url + "api/v1/availability/list";
export const insertavailability = () => envoirment.baseUrl + Url + "api/v1/availability/insert";
export const updateclinicavailability = () => envoirment.baseUrl + Url + "api/v1/availability/update";
export const deleteAvailability = () => envoirment.baseUrl + Url + "api/v1/availability/delete";
// UnAvailability
export const insertunavailability = () => envoirment.baseUrl + Url + "api/v1/unavailability/insert";
export const listunavailability = () => envoirment.baseUrl + Url + "api/v1/unavailability/list";
export const deleteunavailability = () => envoirment.baseUrl + Url + "api/v1/unavailability/delete";
export const listunavailabilityclinic = () => envoirment.baseUrl + Url + "listunavailabilityclinic";


// Accept Invitation
export const validateAuthToken = () => envoirment.baseUrl + Url + "api/v1/validateAuth";
export const decline = () => envoirment.baseUrl + Url + "api/v1/decline";

//Teams
export const insertstaff = () => envoirment.baseUrl + Url + "api/v3/staff/insert";
export const updateStaffStatus = () => envoirment.baseUrl + Url + "api/v1/staff/updatestatus";
export const resendInvitationEmailAPI = () => envoirment.baseUrl + Url + "api/v2/staff/resendInvitationEmail";
export const getPeresanalInfoDetails = () => envoirment.baseUrl + Url + "api/v1/staff/personalinformation/list";
export const updatePersonalInfo = () => envoirment.baseUrl + Url + "api/v1/staff/personalinformation/update";
export const getContactInfoDetails = () => envoirment.baseUrl + Url + "api/v1/staff/contactinformation/list";
export const updateContactInfo = () => envoirment.baseUrl + Url + "api/v1/staff/contactInformation/update";
export const getEducationList = () => envoirment.baseUrl + Url + "api/v1/staff/educationqualification/list";
export const insertEducation = () => envoirment.baseUrl + Url + "api/v1/staff/educationqualification/update";
export const TeamLocationListApi = () => envoirment.baseUrl + Url + "api/v1/staff/locations/list";
export const TeamLocationUpdateApi = () => envoirment.baseUrl + Url + "api/v1/staff/locations/update";
export const TeamspecializationListwithServiceApi = () => envoirment.baseUrl + Url + "api/v1/staff/specialization/services/list";
export const TeamspecializationUpdatewithServiceApi = () => envoirment.baseUrl + Url + "api/v1/api/specialization/services/update";
export const updatePhoneNumberApi = () => envoirment.baseUrl + Url + "api/v1/validateAuth/staff/update";
export const clinicWithservicesAPI = () => envoirment.baseUrl + Url + "api/v1/staff/clinicAndServices/list";

export const liststaffarchive = () => envoirment.baseUrl + Url + "liststaffarchive";
export const updatestaffarchive = () => envoirment.baseUrl + Url + "updatestaffarchive";
export const teamProof = envoirment.baseUrl + `${ImageBaseURL}/EducationProof/`;
export const TeamImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/TeamPictures/thumbnail150_217/`;
// front desk
export const insertFrontdesk = () => envoirment.baseUrl + Url + "api/v1/frontdesk/insert";



//  patients 
export const listPatient = () => envoirment.baseUrl + Url + "api/v2/patient/list";
export const addpatient = () => envoirment.baseUrl + Url + "api/v1/patient/insert";
export const listPatientById = () => envoirment.baseUrl + Url + "api/Patient/details";
export const updatePatient = () => envoirment.baseUrl + Url + "api/v1/patient/update";
export const addmember = () => envoirment.baseUrl + Url + "api/v2/member/insert";
export const updateMember = () => envoirment.baseUrl + Url + "api/v2/member/update"
export const MedicalHistoryListApi = () => envoirment.baseUrl + Url + "api/v1/patient/medicalhistory/list";
export const MedicalHistoryApi = () => envoirment.baseUrl + Url + "api/v1/patient/medicalhistory/insert";
export const vaccinationCalenderPatient = () => envoirment.baseUrl + Url + "api/v1/vaccination/calender/list";
export const updateVaccinationCalenderPatient = () => envoirment.baseUrl + Url + "api/v1/patient/vaccinationcalender/update";
export const appointmentHistory = () => envoirment.baseUrl + Url + "api/v1/appointment/history";
export const testList = () => envoirment.baseUrl + Url + "api/v1/patient/testreport/list";
export const vitalHistoryDetails = () => envoirment.baseUrl + Url + "api/v1/patient/vitals/list";
export const allergyHistoryDetails = () => envoirment.baseUrl + Url + "api/v1/patient/allergy/list";
export const LifestyleHistoryDetails = () => envoirment.baseUrl + Url + "api/v1/patient/lifestyle/list";
export const eprescriptionList = () => envoirment.baseUrl + Url + "api/v1/patient/eprescription/list";
export const eprescriptionDetails = () => envoirment.baseUrl + Url + "api/v1/patient/eprescription/details";
export const mergeAccount = () => envoirment.baseUrl + Url + "api/v1/account/merge";
export const updateLifeStyleApi = () => envoirment.baseUrl + Url + "api/v2/lifestyle/update";
export const insertPet = () => envoirment.baseUrl + Url + "api/v1/patient/pet/insert";
export const updatePetApi = () => envoirment.baseUrl + Url + "api/v1/patient/pet/update";
export const listPetApi = () => envoirment.baseUrl + Url + "api/v1/patient/pet/list";


export const listLifeStyleApi = () => envoirment.baseUrl + Url + "api/v2/lifestyle/list";
export const insertLifeStyle = () => envoirment.baseUrl + Url + "api/v2/lifestyle/insert";

export const PatientImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/PatientPictures/thumbnail150_217/`;
export const PetImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/PetPictures/thumbnail150_217/`;


// Appointment
export const listAppointments = () => envoirment.baseUrl + Url + "api/v1/appointment/list";
export const appointmentsById = () => envoirment.baseUrl + Url + "api/v1/appointment/details";
export const changeAppointmentsStatus = () => envoirment.baseUrl + Url + "api/v1/appointment/changeStatus";
export const appointmentPatientList = () => envoirment.baseUrl + Url + "api/v1/appointment/patient/list";
export const removePatientFromGroupAppt = () => envoirment.baseUrl + Url + "api/v1/appointment/patient/remove";
export const addPatientToGroupAppt = () => envoirment.baseUrl + Url + "api/v1/appointment/patient/add";
export const checkOutSlip = () => envoirment.baseUrl + Url + "api/v1/appointment/checkout";
export const getTimeSlot = () => envoirment.baseUrl + Url + "api/v1/appointment/getTimeSlot";
export const insertAppointment = () => envoirment.baseUrl + Url + "api/v1/appointment/book";
export const appointmentFilterList = () => envoirment.baseUrl + Url + "api/v1/appointment/filter/list";

export const offersListForApptApi = () => envoirment.baseUrl + Url + "offers/listOffersForAppointment";

//agenda
export const setupList = () => envoirment.baseUrl + Url + "api/v2/settings/listall";
export const insertSetup = () => envoirment.baseUrl + Url + "api/v2/settings/insert";
export const checkoutPatinet = () => envoirment.baseUrl + Url + "api/v1/appointment/agenda/checkout";
export const listallMedicines = () => envoirment.baseUrl + Url + "api/v1/medicine/list";
export const insertEprescription = () => envoirment.baseUrl + Url + "api/v1/appointment/agenda/eprescription";
export const deleteAgenda = () => envoirment.baseUrl + Url + "api/v1/appointment/agenda/delete";
export const insertTestReport = () => envoirment.baseUrl + Url + "api/v1/appointment/agenda/testreport";
export const assignvaccinationcalender = () => envoirment.baseUrl + Url + "api/v1/appointment/agenda/assignvaccinationcalender";
export const FilePathName = envoirment.baseUrl + `${ImageBaseURL}/TestReport/`

// Waiting List
export const listWaitingAppts = () => envoirment.baseUrl + Url + "api/v1/appointment/waiting/list";
export const confirmWaitingAppt = () => envoirment.baseUrl + Url + "appointments/confirmWaitingAppt";
export const removePatientApi = () => envoirment.baseUrl + Url + "api/v1/appointment/waitinglist/remove";

// Policy
export const bookingpdf = envoirment.baseUrl + `${ImageBaseURL}/BookingPolicy/`;
export const cancelPdf = envoirment.baseUrl + `${ImageBaseURL}/CancellationPolicy/`;
export const reschedulingPdf = envoirment.baseUrl + `${ImageBaseURL}/ReschedulingPolicy/`;
export const refundPdf = envoirment.baseUrl + `${ImageBaseURL}/RefundPolicy/`;

//  notification
export const ListNotificationApi = () => envoirment.baseUrl + Url + "api/v1/notification/list";
export const ReadNotificationApi = () => envoirment.baseUrl + Url + "api/v1/notification/changestatus";

// VaccinationRecord
export const vaccinationCategoryListApi = () => envoirment.baseUrl + Url + "api/v2/vaccination/category/list";
export const listVaccinationFormApi = () => envoirment.baseUrl + Url + "api/v2/vaccination/form/list";
export const assignVaccinationCalenderList = () => envoirment.baseUrl + Url + "api/v1/agenda/vaccination/calender/list";
export const insertVaccinationCategory = () => envoirment.baseUrl + Url + "api/v2/vaccination/category/insert";
export const updateVaccinationCategory = () => envoirment.baseUrl + Url + "api/v2/vaccination/category/update";
export const UpdateVaccinationForm = () => envoirment.baseUrl + Url + "api/v2/vaccination/form/update";
export const insertVaccinationForm = () => envoirment.baseUrl + Url + "api/v2/vaccination/form/insert";
export const importformApi = () => envoirment.baseUrl + Url + "api/v1/vaccination/form/import";
export const VaccinationImageFilePath = envoirment.baseUrl + `${ImageBaseURL}/VaccinationFormPictures/`;


//insightsd
export const bookingInsightsAPi = () => envoirment.baseUrl + Url + "api/v1/insights/bookingInsights";
export const patientInsightsAPi = () => envoirment.baseUrl + Url + "api/v1/insights/patietnReports";
export const TeamInsightsAPi = () => envoirment.baseUrl + Url + "api/v1/insights/teamreport";
export const ServicesInsightsAPi = () => envoirment.baseUrl + Url + "api/v1/insights/reports/services";
export const rushHourAPi = () => envoirment.baseUrl + Url + "api/v1/insights/rushHour";
export const demographicAPi = () => envoirment.baseUrl + Url + "api/v1/insights/demographic";


// chat
export const conversationListApi = () => envoirment.baseUrl + Url + "api/v1/conversation/list";
export const addNewConversationApi = () => envoirment.baseUrl + Url + "api/v1/conversation/newConversation";
export const deleteConversationsApi = () => envoirment.baseUrl + Url + "api/v1/conversation/deleteConversations";
export const blockUserApi = () => envoirment.baseUrl + Url + "api/v1/conversation/blockUser";
export const leaveGroupApi = () => envoirment.baseUrl + Url + "api/v1/conversation/leaveOrRemoveMember";
export const staffListApi = () => envoirment.baseUrl + Url + "api/v1/chat/staff/list";
export const allMessageListApi = () => envoirment.baseUrl + Url + "api/v1/conversation/message/list";
export const lastMessageApi = () => envoirment.baseUrl + Url + "api/v1/conversation/getConversationDetailsForLastMessage";
export const deleteMessageApi = () => envoirment.baseUrl + Url + "api/v1/conversation/messages/deleteMessage";
export const groupDetailsAPi = () => envoirment.baseUrl + Url + "api/v1/conversation/getGroupDetailWithMembers";
export const addnewMemberTogroupAPi = () => envoirment.baseUrl + Url + "api/v1/conversation/addMember";
export const addOrRemoveAdminAccessApi = () => envoirment.baseUrl + Url + "api/v1/conversation/addOrRemoveFromAdminAccess";
export const updateGroupSettingsApi = () => envoirment.baseUrl + Url + "api/v1/conversation/updateGroupSettings";
export const updateGroupInfoApi = () => envoirment.baseUrl + Url + "api/v1/conversation/updateGroupInfo";
export const readMessage = () => envoirment.baseUrl + Url + "api/v1/chat/readMessage";
export const uploadAttchamentApi = () => envoirment.baseUrl + Url + "api/v1/file/upload";
export const ChatAttachmentFilePath = envoirment.baseUrl + `${ImageBaseURL}/Chat/`;
export const ChatGroupFilePath = envoirment.baseUrl + `${ImageBaseURL}/ChatGroupPictures/`;


// Template Library

export const templateCategoryListApi = () => envoirment.baseUrl + Url + "api/template-forms/category/list";
export const templateCategoryAddApi = () => envoirment.baseUrl + Url + "api/template-forms/category/add";
export const templateCategoryDetailsApi = () => envoirment.baseUrl + Url + "api/template-forms/category/list/details";
export const templateCategoryUpdateApi = () => envoirment.baseUrl + Url + "api/template-forms/category/update";
export const templateCategoryToggleApi = () => envoirment.baseUrl + Url + "api/template-forms/category/toggle-activestatus";
export const templateCategoryDeleteApi = () => envoirment.baseUrl + Url + "api/template-forms/category/toggle-deteletedstatus";

export const templatListApi = () => envoirment.baseUrl + Url + "api/template-forms/category/form/list";
export const templateAddApi = () => envoirment.baseUrl + Url + "api/template-forms/category/form/add";
export const templateDetailsApi = () => envoirment.baseUrl + Url + "api/template-forms/category/form/list/details";
export const templateUpdateApi = () => envoirment.baseUrl + Url + "api/template-forms/category/form/update";
export const templateToggleApi = () => envoirment.baseUrl + Url + "api/template-forms/category/form/toggle-activestatus";
export const templateDeleteApi = () => envoirment.baseUrl + Url + "api/template-forms/category/form/toggle-deteletedstatus";

export const AssignedFormListApi = () => envoirment.baseUrl + Url + "api/appointment/agenda/template-forms/form/filled-form/list";
export const AssignFormApi = () => envoirment.baseUrl + Url + "api/appointment/agenda/template-forms/form/aassign";
export const filledAndAssignedFormDetailsApi = () => envoirment.baseUrl + Url + "api/template-forms/form/filled-form/details";
export const fillAndUpdateFormApi = () => envoirment.baseUrl + Url + "api/appointment/agenda/template-forms/form/filled-form/update";
