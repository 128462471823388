import { Avatar, Tooltip } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import Loading from "../../Component/Loading";
import { Modal } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import useAppointmentDetails from "./Hooks/useAppointmentDetails";
import RescheduleAppointment from "./Component/RescheduleAppointment";
import CancelAppointment from "./Component/CancelAppointment";
import { IoMdAdd } from "react-icons/io";
import { AppointmentStatus } from "../../Context/constants";
import { FaArrowLeft } from 'react-icons/fa';
import { Dosage } from "../../Utils/Utils";
import { NoRecordFound } from "../../Component/NoRecordFound";
import { clinicPictures } from "../../Context/api";
import TextBox from "../../Component/TextBox";
const AppointmentDetails = () => {
    const {
        setValue,
        reschedulefun,
        setModalCancel,
        handlePrint,
        setAddpatientModal,
        deletePatientfun,
        setAddPatientValue,
        setDeletePatientdata,
        setDeletePatientmodal,
        addpatientfun,
        setIsactiveSlot,
        setTap,
        clossOffcanvas,
        getlistPatient,
        navigate,
        CustomButton,
        tap,
        loader,
        apptDetails,
        userdata,
        componentRef,
        checkoutSlipData,
        isMaxPatients,
        ePrescriptionList,
        deletePatientmodal,
        addpatientModal,
        allpatientList,
        patientDetails,
        deletePatientdata,
        AddPatientValue,
        modalCancel,
        cancelAppointment,
        modalReschdule,
        appointmentReschedule,
        TimeslotList,
        SlotLodaer,
        isActiveSlot,
    } = useAppointmentDetails()


    return <>
        {loader && <Loading />}
        <div className="common-component-box">
            <header className="body_header" >
                <div className="d-flex justify-content-start align-items-center ">
                    <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(-1) }} />
                    <h2 className="page_name">Details</h2>
                </div>
            </header>
            <div className="common-white-bg-box mx-0 shadow">
                <div className="row px-0 mx-0">
                    <div className="col-12">
                        <div className="tab_scroll">
                            <ul className="nav  mb-0" style={{ width: "500px" }}>
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn me-2 ${tap === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => setTap(1)} >Appointment Details</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${tap === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setTap(2)} >Patient Details</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${tap === 3 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setTap(3)} >E-prescription</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${tap === 4 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setTap(4)} >Checkout Slip</button>
                                </li>
                            </ul>
                        </div>
                        {
                            tap === 1 && <div className="p-0">
                                <div className="row position-relative text_box_height align-content-start px-2 " >
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Appointment Number</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{apptDetails?.appointmentNumber}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">

                                                    </div>
                                                    <div className="col-lg-7">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Clinic</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{apptDetails?.clinicName}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Appointment Date</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{`${moment(apptDetails?.startDate)?.tz(userdata?.timezoneName)?.format('YYYY-MM-DD')}`}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Patient Name</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{(apptDetails?.patientFirstName ? apptDetails?.patientFirstName : "") + " " + (apptDetails?.patientLastName ? apptDetails?.patientLastName : '')}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Appointment Time</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{`${moment(apptDetails?.startDate)?.tz(userdata?.timezoneName)?.format("hh:mm A")}   -  ${moment(apptDetails?.endDate)?.tz(userdata?.timezoneName)?.format("hh:mm A")}`}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Status</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{apptDetails?.Status}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        apptDetails?.Status === AppointmentStatus?.CANCELLED && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Cancelled Reason</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <TextBox textBoxData={<p>{apptDetails?.cancellationReason}</p>} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Specialization</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{apptDetails?.specialisationName}</p>} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Service</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{apptDetails?.serviceName}</p>} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Doctor</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{(apptDetails?.doctorFirstName && apptDetails?.doctorLastName) ? apptDetails?.doctorFirstName + ' ' + apptDetails?.doctorLastName : null}</p>} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Final Price</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <TextBox textBoxData={<p>{(apptDetails?.finalPrice) ? `${apptDetails?.currencyImage}${apptDetails?.finalPrice}` : ""}</p>} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end my-3 px-0" >
                                        <div className="d-flex">
                                            {!(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus?.RESCHEDULED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) && <CustomButton variant="contained" onClick={reschedulefun} className="me-3" >Reschedule Appointment</CustomButton>}
                                            {!(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) && <CustomButton variant="contained" onClick={() => setModalCancel((apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) ? false : true)} >Cancel Appointment</CustomButton>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            tap === 2 && <div className='p-0 pt-3 text_box_height'  >
                                <div className="mx-0 px-0 row d-flex justify-content-end mb-2">
                                    <div className=" mx-0 px-2 col-6 d-flex justify-content-end">
                                        <CustomButton variant="contained" disabled={(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) ? true : isMaxPatients ? true : false}
                                            onClick={() => { getlistPatient(); setAddpatientModal(true) }}
                                            startIcon={<IoMdAdd />}
                                        >add patient</CustomButton>
                                    </div>
                                </div>
                                <div className="row px-2 g-3">
                                    {
                                        !patientDetails.length && <h3 className='text-center mt-5' style={{ color: "gray", fontSize: "12px" }}>No record found  </h3>
                                    }
                                    {patientDetails?.map((cur, index) => {
                                        return <div className="col-lg-4 col-xl-3 col-md-6 col-12" key={cur?.patientId}>
                                            <div className="col shadow PATIENT-CARD rounded">
                                                <div className="w-25">
                                                    <Avatar alt="Remy Sharp" sx={{ width: 50, height: 50 }} />
                                                </div>
                                                <div className="w-50 TEXT-CONTENT">
                                                    <p>{(cur?.patientFirstName ? cur?.patientFirstName : "") + " " + (cur?.patientLastName ? cur?.patientLastName : '')}</p>
                                                    <p>{cur?.email}</p>
                                                </div>
                                                <div className="w-25 text-end">
                                                    <i
                                                        onClick={() => { setDeletePatientmodal(true); setDeletePatientdata(cur) }}
                                                        style={{ cursor: "pointer" }}
                                                        className="fa-solid fa-trash-can ms-3 fs-6 all_icons_color" ></i>
                                                </div>
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>
                        }
                        {
                            tap === 4 && <div className='p-0 pt-2 text_box_height' >
                                {
                                    apptDetails?.Status !== AppointmentStatus.CHECKEDOUT ? <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /></div> :
                                        <div className="row px-0 mx-0">
                                            <div className="col-3 ms-auto d-flex justify-content-end pe-0 mb-2">
                                                <Tooltip title=" Download PDF " placement="left" style={{ cursor: "pointer" }}>
                                                    <CustomButton
                                                        onClick={handlePrint}
                                                        variant="contained"  >print slip</CustomButton>
                                                </Tooltip>
                                            </div>
                                            <div className="container bg p-0" ref={componentRef}>
                                                <div className="col-lg-12 bg2">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className="imgs p-2 ms-2">
                                                            <Avatar
                                                                alt={checkoutSlipData?.clinicName}
                                                                src={clinicPictures + checkoutSlipData?.clinicImage}
                                                                sx={{ width: 100, height: 100 }}
                                                            />
                                                        </div>
                                                        <div    >
                                                            <h3 className="text-lig">{checkoutSlipData?.clinicName}</h3>
                                                            <p className="m-0 text-lig">
                                                                {` ${checkoutSlipData?.city?.charAt(0)?.toUpperCase()}${checkoutSlipData?.city?.slice(1)}, ${checkoutSlipData?.state?.charAt(0).toUpperCase()}${checkoutSlipData?.state?.slice(1)}, ${checkoutSlipData?.country?.charAt(0)?.toUpperCase()}${checkoutSlipData?.country?.slice(1)},${checkoutSlipData?.zipCode}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between pt-3 px-3 pb-1 appointment_details">
                                                    <p><b>Patient Name :</b> {(checkoutSlipData?.patientFirstName ? checkoutSlipData?.patientFirstName : "") + " " + (checkoutSlipData?.patientLastName ? checkoutSlipData?.patientLastName : "")}</p>
                                                    <p><b>Appointment Date/Time :</b>{`${moment(checkoutSlipData?.apptStartDate)?.format("DD/MM/YYYY")} , ${moment(checkoutSlipData?.apptStartDate)?.format("hh:mm A")}  `}</p>
                                                </div>
                                                <div className="px-2 bgimg">
                                                    <div className="cover-border px-3 py-3">
                                                        <div className="d-flex  justify-content-start align-items-center mb-2">
                                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone  ms-2 me-3" alt="" />
                                                            <h5 >Vitals</h5>
                                                        </div>
                                                        <div className="bordrbottom">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h6>Blood Pressure</h6>
                                                                    <p className="p-text">{checkoutSlipData?.bp ? checkoutSlipData?.bp : "-"}</p>
                                                                </div>
                                                                <div className="col">
                                                                    <h6>Pulse</h6>
                                                                    <p className="p-text">{checkoutSlipData?.pulse ? checkoutSlipData?.pulse : "-"}</p>
                                                                </div>
                                                                <div className="col">
                                                                    <h6>Weight</h6>
                                                                    <p className="p-text">{checkoutSlipData?.weight ? (`${checkoutSlipData?.weight} Kg`) : "-"}</p>
                                                                </div>
                                                                <div className="col">
                                                                    <h6>Height</h6>
                                                                    <p className="p-text">{checkoutSlipData?.height ? (`${checkoutSlipData?.height} Cm`) : "-"}</p>
                                                                </div>
                                                                <div className="col">
                                                                    <h6>Temperature</h6>
                                                                    <p className="p-text">{checkoutSlipData?.temperature && checkoutSlipData?.temperature}<sup>&#176;</sup>F</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone  ms-2 me-3" alt="" />
                                                            <h5 >Symptoms</h5>
                                                        </div>
                                                        <div className="bordrbottom">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="p-text">
                                                                        {checkoutSlipData?.symptom?.map((item, indexSym) => <span key={indexSym} className='ms-1 mt-1'>{item?.symptoms}{checkoutSlipData?.symptom?.length > indexSym + 1 ? ',' : ''}</span>)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone ms-2 me-3" alt="" />
                                                            <h5 >Chronic Disease</h5>
                                                        </div>
                                                        <div className="bordrbottom">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="p-text">
                                                                        {checkoutSlipData?.chronicDisease?.map((item, indexpre) => <span key={indexpre} className='ms-1 mt-1'>{item?.chronicDiseases}{checkoutSlipData?.symptom?.length > indexpre + 1 ? ',' : ''}</span>)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                                            <img src={require("../../Assets/UploadDocumentImage/prescription.png")} className="icone  ms-2 me-3" alt="" />
                                                            <h5 >RX</h5>
                                                        </div>
                                                        <div className="bordrbottom" style={{ overflow: "auto", width: "100%" }}>
                                                            <table className="table bootstrap_table_responsive" >
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ backgroundColor: "transparent" }}>Medicine</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Medicine type</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Strength</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Standard</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Duration</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Dosage Instructions</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Quantity</td>
                                                                        <td style={{ backgroundColor: "transparent" }}>Notes</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {checkoutSlipData?.rx?.map((item, index) =>
                                                                        <tr key={index}>
                                                                            <td>{item?.medicineName}</td>
                                                                            <td>{item?.medicineType}</td>
                                                                            <td>{item?.doseAmount + item?.doseType}</td>
                                                                            {/* <td>{` ${item?.medicineIntakeType === "STANDARD" ? `${item?.morningMedCount}-${item?.afternoonMedCount}-${item?.nightMedCount}` : `Every ${item?.medicineIntakeDurationAmount + item?.customMedCountTimeFrame}`} `}</td> */}
                                                                            <td>{Dosage(item)}</td>
                                                                            <td>{item?.medicineIntakeDurationAmount + " " + item?.medicineIntakeDurationType}</td>
                                                                            <td> {item?.medicineIntakeTime !== "CUSTOM" ? item?.medicineIntakeTime : item?.medicineIntakeCustomTime}</td>
                                                                            <td>{item?.medicineIntakeAmount}</td>
                                                                            <td>{item?.notes ? item?.notes : "N/A"}</td>
                                                                        </tr>
                                                                    )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone ms-2 me-3" alt="" />
                                                            <h5 >Diagnosis</h5>
                                                        </div>
                                                        <div className="bordrbottom">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="p-text">
                                                                        {checkoutSlipData?.diagnosis}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                                            <img src={require("../../Assets/UploadDocumentImage/blood-sample.png")} className="icone ms-2 me-3" alt="" />
                                                            <h5 >Test Required</h5>
                                                        </div>
                                                        <div className="bordrbottom mb-4">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="p-text">
                                                                        {checkoutSlipData?.tests?.map((item, indextests) => <span key={indextests} className='ms-1 mt-1'>{item?.testsName}{checkoutSlipData?.tests?.length > indextests + 1 ? ',' : ''}</span>)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-around py-3">
                                                    <div className="d-flex justify-content-start align-items-center"> <img src={require("../../Assets/UploadDocumentImage/surgeon.png")} className="icone me-3" alt="" />
                                                        <p className=" mb-0 font_14"><b>Doctor’s Name :</b> {(checkoutSlipData?.doctorFirstName && checkoutSlipData.doctorLastName) ? checkoutSlipData?.doctorFirstName + " " + checkoutSlipData.doctorLastName : "-"}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-start align-items-center"> <img src={require("../../Assets/UploadDocumentImage/calendar.png")} className="icone me-3" alt="" />
                                                        <p className=" mb-0 font_14"><b>Follow Up Date :</b> {checkoutSlipData?.followUpDate ? moment(checkoutSlipData?.followUpDate)?.format("DD/MM/YYYY") : "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        }
                        {/* apptDetails?.prescriptionNumber */}
                        {
                            tap === 3 && <div className='p-0 pt-2 text_box_height'>

                                {!apptDetails?.prescriptionNumber ? <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /></div> :
                                    <div className="row mx-0 px-0">
                                        <div className="col-3 ms-auto d-flex justify-content-end pe-0 mb-2">
                                            <Tooltip title=" Download PDF " placement="left" style={{ cursor: "pointer" }}>
                                                <CustomButton onClick={handlePrint} variant="contained">print</CustomButton>
                                            </Tooltip>
                                        </div>
                                        <div className=" tbl-border p-0 mx-0" ref={componentRef}>
                                            <div className="m-0 ps-3">
                                                <h1 className="rx p-0 m-0">Rx</h1>
                                            </div>
                                            <div className="d-flex justify-content-between p-4">
                                                <div className="col-lg-4">
                                                    <div className="botm-border">
                                                        <p className="p-0 m-0 mb-1">
                                                            <b>Patient Name :</b> <span> {(ePrescriptionList?.[0]?.patientFirstName ? ePrescriptionList?.[0]?.patientFirstName : "") + " " + (ePrescriptionList?.[0]?.patientLastName ? ePrescriptionList?.[0]?.patientLastName : '')}</span>
                                                        </p>
                                                    </div>
                                                    <div className="botm-border mt-2">
                                                        <p className="p-0 m-0 mb-1"><b>Age :</b> <span> {ePrescriptionList?.[0]?.age} y</span></p>
                                                    </div>
                                                    <div className="botm-border mt-2">
                                                        <p className="p-0 m-0 mb-1"><b>Gender :</b> <span> {ePrescriptionList?.[0]?.gender}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="botm-border">
                                                        <p className="p-0 m-0 mb-1">
                                                            <b>Prescription Number :</b> <span> {ePrescriptionList?.[0]?.prescriptionNumber}</span>
                                                        </p>
                                                    </div>
                                                    <div className="botm-border mt-2">
                                                        <p className="p-0 m-0 mb-1"><b>Date :</b> <span> {moment(ePrescriptionList?.[0]?.startDate).format("DD/MM/YYYY")}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1"></div>
                                            </div>
                                            <div className="p-4 " style={{ height: "400px", overflow: "auto", width: "100%" }}>
                                                <table className="table table-bordered tbl-border bootstrap_table_responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Medicine</th>
                                                            <th >Medicine type</th>
                                                            <th>Strength</th>
                                                            <th>Standard</th>
                                                            <th>Duration</th>
                                                            <th>Dosage Instructions</th>
                                                            <th>Quantity</th>
                                                            <th>Notes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            ePrescriptionList?.map((item, index) => {
                                                                return <tr>
                                                                    <th>{index + 1}</th>
                                                                    <td>{item?.medicineName}</td>
                                                                    <td>{item?.medicineType}</td>
                                                                    <td>{item?.doseAmount + item?.doseType}</td>
                                                                    {/* <td>{` ${item?.medicineIntakeType === "STANDARD" ? `${item?.morningMedCount}-${item?.afternoonMedCount}-${item?.nightMedCount}` : `Every ${item?.medicineIntakeDurationAmount + item?.customMedCountTimeFrame}`} `}</td> */}
                                                                    <td>{Dosage(item)}</td>
                                                                    <td>{item?.medicineIntakeDurationAmount + " " + item?.medicineIntakeDurationType}</td>
                                                                    <td> {item?.medicineIntakeTime !== "CUSTOM" ? item?.medicineIntakeTime : item?.medicineIntakeCustomTime}</td>
                                                                    <td>{item?.medicineIntakeAmount}</td>
                                                                    <td>{item?.notes ? item?.notes : "N/A"}</td>
                                                                </tr>

                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        {/*delete patient  */}
        < Modal show={deletePatientmodal} onHide={() => setDeletePatientmodal(false)} centered >
            <Modal.Body>
                <div className="modal-body text-center">
                    <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
                    <p className="mb-0">Are you sure? </p>
                    <p >You want to delete this patient</p>
                    <CustomButton variant="contained" className="me-3" onClick={() => deletePatientfun("YES", deletePatientdata)} > Yes</CustomButton>
                    <CustomButton variant="outlined" onClick={() => deletePatientfun("NO")} > No</CustomButton>
                </div>
            </Modal.Body>
        </ Modal>
        {/* add patient */}
        <Modal show={addpatientModal} onHide={() => {
            setAddpatientModal(false)
            setValue("2")
        }} centered >
            <Modal.Body>
                <div className="modal-body adddetail">
                    <div className="text-center">
                        <h4 className="modal-title mb-3">Add Patient</h4>
                    </div>

                    <div className="row px-0 mx-0 d-flex justify-content-start mb-2 SelectMultiple">
                        <div className="col-12 px-0 mx-0">
                            <div className="row shadow  ">
                                <div className="col-10 px-0">
                                    <input type="text" onChange={(e) => setAddPatientValue(e?.target?.value)} className='w-100 search' placeholder='Search...' />
                                </div>
                                <div className="col-2 incon_search "><AiOutlineSearch /></div>
                            </div>
                            <div className="row select_box  mt-2">
                                <div className="col-12">
                                    {
                                        allpatientList?.filter((filters, ins) => (filters?.firstName?.toLowerCase() || filters?.lastName?.toLowerCase())?.includes(AddPatientValue?.toLowerCase()))?.map((cur, index) => {
                                            return <React.Fragment key={index}>
                                                <div className="row country_list px-2  py-2 my-2 shadow-sm">
                                                    <div className="form-check  ">
                                                        <input className="form-check-input" onClick={() => addpatientfun(cur?.id)} value={cur?.id} type="radio" name="flexRadioDefault" id={cur?.id} />
                                                        <label className="form-check-label" htmlFor={cur?.id}>
                                                            {(cur?.firstName ? cur?.firstName : "") + " " + (cur?.lastName ? cur?.lastName : '')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
        <CancelAppointment modalCancel={modalCancel} clossOffcanvas={clossOffcanvas} cancelAppointment={cancelAppointment} CustomButton={CustomButton} />
        <RescheduleAppointment clossOffcanvas={clossOffcanvas} modalReschdule={modalReschdule} apptDetails={apptDetails} userdata={userdata}
            moment={moment} appointmentReschedule={appointmentReschedule} TimeslotList={TimeslotList} SlotLodaer={SlotLodaer}
            isActiveSlot={isActiveSlot} setIsactiveSlot={setIsactiveSlot} CustomButton={CustomButton} />
    </>;
};

export default AppointmentDetails;
