import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { useFormik } from "formik";
import { E_prescriptionValidation, addReportValidation, addvaccinationValidation } from "../../../Context/Validation";
import { useLocation, useNavigate } from "react-router-dom";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { AppointmentStatus, RoleTitle, } from "../../../Context/constants";
import { APPOINTMENT_LIST, ASSIGN_VACCINATION_CALENDAR, DELETE_AGENDA, INSERT_EPRESCRIPTION, INSERT_TEST_REPORT, MEDICINE_LIST } from "../../Repository/AppointmentsRepo";
import { GET_EPRESCRIPTION_DETAILS, TEST_REPORT_LIST, UPDATE_VACCINATION_CALENDAR_PATIENT, VACCINATION_CALENDAR_PATIENT } from "../../Repository/PatientRepo";
import { UPLOAD_FILE_PDF } from "../../Repository/ProfileRepo";
import { VACCINATION_CATEGORY_LIST, VACCINATION_FORM_LIST } from "../../Repository/VaccinationRepo";
import useHtml2canvas from "../../../Hooks/useHtml2canvas";
import { useReactToPrint } from 'react-to-print';
import { ASSIGNED_TEMPLATE_FORM, ASSIGNED_TEMPLATE_FORM_LIST, CATEGORY_LIST, FILL_UPDATE_TEMPLATE_FORM, TEMPLATE_FORM_LIST } from "../../Repository/TemplateLibraryRepo";
const useTodayAgenda = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loader, setLoader] = useState(false)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [show, setShow] = useState(false);
    const [ShowPDFModal, setShowPDFModal] = useState(false)
    const [vaccinationSerialNumberImage, setVaccinationSerialNumberImage] = useState(false)
    const [vaccinationSerialNumberPath, setVaccinationSerialNumberPath] = useState(null)
    const [pdfPathName, setPdfPathName] = useState(null)
    const [apptList, setApptList] = useState([])
    const [EprescriptionList, setEprescriptionList] = useState([])
    const [medicineData, setMedicineData] = useState([])
    const [ListVaccinationCategory, setListVaccinationCategory] = useState([])

    const [VaccinationFormList, setVaccinationFormList] = useState([])
    const [vaccinationData,] = useState({ categoryId: "", categoryName: "", form: [] })
    const [MultipleReportData, SetMultipleReportData] = useState([])
    const [assignVaccinationcalenderList, setAssignVaccinationcalenderList] = useState([])
    const [patientDeatils, setPatientDetails] = useState({})
    const [Url, setUrl] = useState(null);
    const [UploadReportType, setUploadReportType] = useState(null)
    const [removeButton, setremoveButton] = useState(false)
    const [tab, setTab] = useState(1)
    const [categoryList, setCategoryList] = useState([])
    const [assignedTemplateFormList, setAssignedTemplateFormList] = useState([])
    const [templateFomrList, setTemplateFomrList] = useState([])
    const [CreateModal, setCreateModal] = useState({
        Prescription: false,
        Report: false,
        Vaccination: false,

    })
    const [openDeleteModal, setOpenDeleteModal] = useState({
        Prescription: false,
        Report: false,
        Vaccination: false,
        data: null
    })
    const [openCategoryListModal, setOpenCategoryListModal] = useState({
        open: false,
        data: null
    })
    const [openTemplateListModal, setOpenTemplateListModal] = useState({
        open: false,
        data: null
    })
    const [openFillFormModal, setOpenFillFormModal] = useState({
        open: false,
        data: null
    })
    const [openViewFormModal, setOpenViewFormModal] = useState({
        open: false,
        data: null
    })
    const [openModal, setOpenModal] = useState({
        viewVaccinationForm: false,
        downloadVaccinationForm: false,
        printVaccinationForm: false,
        viewEPrescription: false,
        data: null
    })
    console.log(paginationTotalCount, 'paginationTotalCount')
    const headCells = [
        { id: "image", numeric: true, disablePadding: false, label: "Image" },
        { id: "patientFirstName", numeric: true, disablePadding: false, label: "Patient Name", showIcon: true },
        { id: "Time", numeric: true, disablePadding: false, label: "Date/Time" },
        { id: "specialisationName", numeric: true, disablePadding: false, label: "Specialization", showIcon: true },
        { id: "serviceName", numeric: true, disablePadding: false, label: "Services Name", showIcon: true },
        { id: "visitType", numeric: true, disablePadding: false, label: "Visit Type", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const headCellsPrescription = [
        { id: "Prescription Number", numeric: true, disablePadding: false, label: "Prescription Number", showIcon: true },
        { id: "date", numeric: true, disablePadding: false, label: "Date", showIcon: true },
        { id: "Medicine", numeric: true, disablePadding: false, label: "Medicine", showIcon: true },
        { id: "medicineType", numeric: true, disablePadding: false, label: "Medicine type", showIcon: true },
        { id: "Dose", numeric: true, disablePadding: false, label: "Standard", showIcon: true },
        { id: "Strength", numeric: true, disablePadding: false, label: "Strength", showIcon: true },
        { id: "Duration", numeric: true, disablePadding: false, label: "Duration", showIcon: true },
        { id: "medicineIntakeTime", numeric: true, disablePadding: false, label: "Dosage Instructions", showIcon: true },
        { id: "Qty", numeric: true, disablePadding: false, label: "Qty", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const headCellsReport = [
        { id: "Title", numeric: true, disablePadding: false, label: "Title" },
        { id: "Description", numeric: true, disablePadding: false, label: "Description", showIcon: true },
        { id: "File", numeric: true, disablePadding: false, label: "File" },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];

    const headCellsVaccination = [
        { id: "formName", numeric: false, disablePadding: true, label: "Form Name", showIcon: true },
        { id: "doctorName", numeric: true, disablePadding: false, label: "Doctor", showIcon: true },
        { id: "PatientName", numeric: true, disablePadding: false, label: "Patient Name", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const headCellsTemplate = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Form Name", isShorted: true },
        { id: "formCategoryName", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];

    const headCellsTemplateCategory = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
    ];
    const headCellsTemplateForm = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Form Name", isShorted: true },
        { id: "formCategoryName", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
    ];

    const { CustomButton } = useMuiCompontent()
    const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
    const { reduxData: AgendaDetails } = useReduxData("AgendaDetailsRDX")
    const { contentRef, downloadContent } = useHtml2canvas()
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const openMenu = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK

    const openModalFun = (type) => {
        const modalState = {
            Prescription: false,
            Report: false,
            Vaccination: false,
            viewEPrescription: false,
        };
        if (modalState.hasOwnProperty(type)) {
            modalState[type] = true;
            setCreateModal(modalState);
        }
    }
    const closeModalFun = () => {
        setCreateModal({
            Prescription: false,
            Report: false,
            Vaccination: false,
            viewEPrescription: false,
        })
    }

    const handleClosedModal = (type) => {
        setOpenDeleteModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }

    const handleOpenModal = (type, data) => {
        setOpenDeleteModal((prev) => ({
            ...prev,
            data,
            [type]: true,
        }));
    };
    const handleClosedVaccinationModal = (type) => {
        setremoveButton(false)
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }

    const handleOpenVaccinationModal = (type, data) => {
        setOpenModal((prev) => ({
            ...prev,
            data,
            [type]: true,
        }));
    };

    const listAppointment = async () => {
        const data = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            roleTitle: userdata.roleTitle,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            teams: [],
            patients: [],
            services: [],
            status: [AppointmentStatus.BOOKED, AppointmentStatus.RESCHEDULED],
            page: controller.page,
            count: controller.rowsPerPage,
            startDate: null,
            endDate: moment().endOf('day'),
            filter: "AGENDA",
            clinics: userdata?.location?.clinicId ? [userdata?.location?.clinicId] : [],
        }
        setLoader(true)
        try {
            let response = await APPOINTMENT_LIST(data)
            setApptList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setApptList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }
    }
    const getEprescriptionList = async () => {
        let payload = {
            // prescriptionNumber: AgendaDetails?.prescriptionNumber,
            appointmentId: AgendaDetails?.apptId,
        }
        try {
            let response = await GET_EPRESCRIPTION_DETAILS(payload)
            setEprescriptionList(response.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setPaginationTotalCount(0)
            setEprescriptionList([])
            setLoader(false)
        }
    }
    const medicineList = async () => {
        let payload = {
            activeStatus: true,
            // providerId:userdata.userId,
            // patientId: AgendaDetails?.id,
            // appointmentId: AgendaDetails?.apptId,
        }
        setLoader(true)
        try {
            let response = await MEDICINE_LIST(payload)
            setMedicineData(response?.data)
            setLoader(false)
        } catch (error) {
            setMedicineData([])
            setLoader(false)
        }
    }
    // create e-prescription
    const addMedicine = useFormik({
        initialValues: {
            rx: [],
            type: [],
            unit: [],
            dose: "",
            medCountType: "STANDARD",
            timeofmedicine: "HOURS",
            timeofmedicinenumberForHours: "",
            timeofmedicinenumberForDays: "",
            morningMedCount: true,
            afternoonMedCount: false,
            nightMedCount: false,
            DurationTimeFrame: "DAYS",
            duration: '',
            quantity: '',
            note: '',
            when: "BEFORE FOOD",
            whenCustom: ""
        },
        validationSchema: E_prescriptionValidation,
        onSubmit: async (values) => {
            if (values?.medCountType === "STANDARD") {
                if (!values?.afternoonMedCount && !values?.morningMedCount && !values?.nightMedCount) {
                    return toast.warn("Please fill in all required fields")
                }
            }
            let payload = {
                patientId: AgendaDetails?.id,
                appointmentId: AgendaDetails?.apptId,
                appointmentNumber: AgendaDetails?.apptNumber,
                // providerId: userdata?.userId,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                roleTitle: userdata.roleTitle,
                rx: [{
                    medicineId: values?.rx?.[0]?.medicineId,
                    medicineType: values?.type?.[0]?.value,
                    dose: values?.dose,
                    unit: values?.unit?.[0]?.value,
                    medCountType: values?.medCountType,
                    morningMedCount: values?.morningMedCount,
                    afternoonMedCount: values?.afternoonMedCount,
                    nightMedCount: values?.nightMedCount,
                    customMedCount: values?.timeofmedicine === "HOURS" ? values?.timeofmedicinenumberForHours : values?.timeofmedicinenumberForDays,
                    customMedCountTimeFrame: values?.medCountType === "STANDARD" ? null : values?.timeofmedicine,
                    duration: values?.duration,
                    durationTimeFrame: values?.DurationTimeFrame,
                    when: values?.when,
                    quantity: values?.quantity,
                    notes: values?.note,
                    whenCustom: values?.whenCustom
                }]
            }
            setLoader(true)
            try {
                let response = await INSERT_EPRESCRIPTION(payload)
                toast.success(response.message)
                addMedicine.resetForm()
                setLoader(false)
                closeModalFun()
                getEprescriptionList()
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    // delete e-prescription and report
    const handleDelete = async (event) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                patientId: AgendaDetails?.id,
                appointmentId: openDeleteModal?.data?.appointmentId,
                type: openDeleteModal?.Prescription ? "EPRESCRIPTION" : openDeleteModal?.Report ? "TESTREPORT" : "VACCINATIONFORM",
                prescriptionId: openDeleteModal?.Prescription ? openDeleteModal?.data?.prescriptionId : undefined,
                testReportId: openDeleteModal?.Report ? openDeleteModal?.data?.testreportId : undefined,
                vaccinationFormId: openDeleteModal.Vaccination ? [openDeleteModal?.data?.vaccinationFormId] : undefined
            }
            try {
                let response = await DELETE_AGENDA(payload)
                toast.success(response.message)
                setLoader(false)
                if (openDeleteModal?.Prescription) {
                    if (EprescriptionList?.length === 1 && controller.page === 1) {
                        getEprescriptionList()
                    }
                    else if (EprescriptionList?.length === 1) {
                        setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                    } else getEprescriptionList()

                } else if (openDeleteModal.Report) {
                    if (MultipleReportData?.length === 1 && controller.page === 1) {
                        getreportList()
                    }
                    else if (MultipleReportData?.length === 1) {
                        setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                    } else getreportList()
                }
                else {
                    if (assignVaccinationcalenderList?.length === 1 && controller.page === 1) {
                        getAssignVaccinationCalendarList()
                    }
                    else if (assignVaccinationcalenderList?.length === 1) {
                        setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                    } else getAssignVaccinationCalendarList()
                }
                handleClosedModal(openDeleteModal?.Prescription ? "Prescription" : openDeleteModal.Report ? "Report" : "Vaccination")
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }

        } else {
            handleClosedModal(openDeleteModal?.Prescription ? "Prescription" : openDeleteModal.Report ? "Report" : "Vaccination")
        }

    }
    // create test report
    const addReport = useFormik({
        initialValues: {
            title: "",
            description: ""
        },
        validationSchema: addReportValidation,
        onSubmit: async (values) => {
            if (!Url) {
                return toast.warn("Please upload test report")
            }
            setLoader(true)
            let payload = {
                patientId: AgendaDetails?.id,
                appointmentId: AgendaDetails?.apptId,
                appointmentNumber: AgendaDetails?.apptNumber,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                roleTitle: userdata?.roleTitle,
                testReport: [{
                    fileName: Url,
                    description: values?.description,
                    title: values?.title,
                }]
            }
            try {
                let response = await INSERT_TEST_REPORT(payload)
                toast.success(response.message)
                addReport.resetForm()
                setLoader(false)
                setUrl(null)
                getreportList()
                closeModalFun()
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    // upload pdf/image for test report 
    const uploadPdf = async (e) => {
        if (!e) return
        const fd = new FormData()
        fd.append('file', e)
        fd.append('type', "TESTREPORT")
        fd.append('previousFileName', Url)
        fd.append('id', null)
        setLoader(true)
        try {
            let response = await UPLOAD_FILE_PDF(fd)
            toast.success(response.message)
            setLoader(false)
            setUrl(response?.data?.db_name)
            setUploadReportType(e.type)
        } catch (error) {
            toast.error(error?.message);
            setLoader(false)
        }
    }
    // report list
    const getreportList = async () => {
        setLoader(true)
        let payload = {
            patientId: AgendaDetails?.id,
            appointmentId: AgendaDetails?.apptId,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        }
        try {
            let response = await TEST_REPORT_LIST(payload)
            SetMultipleReportData(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setPaginationTotalCount(0)
            SetMultipleReportData([])
            setLoader(false)
        }
    }
    // vaccination category list
    const getVaccinationCategoryList = async () => {
        const payload = {
            activeStatus: true,
            roleTitle: userdata?.roleTitle,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK || userdata.roleTitle === RoleTitle.STAFF) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        };
        setLoader(true)
        try {
            let response = await VACCINATION_CATEGORY_LIST(payload)
            setListVaccinationCategory(response?.data)
            setLoader(false)
        } catch (error) {
            setListVaccinationCategory([])
            setLoader(false)
        }

    }
    // vaccination form list
    const getlistVaccinationForm = async (id) => {
        setLoader(true)
        const payload = {
            activeStatus: true,
            vaccinationCategoryId: id,
            publishStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK || userdata.roleTitle === RoleTitle.STAFF) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,

        };
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            setVaccinationFormList(response?.data)
            setLoader(false)
        } catch (error) {
            setVaccinationFormList([])
            setLoader(false)
        }
    }
    const addvaccination = useFormik({
        initialValues: {
            category: [],
            vaccinationForm: []
        },
        validationSchema: addvaccinationValidation,
        onSubmit: async (values) => {
            setLoader(true)
            const payload = {
                patientId: AgendaDetails?.id,
                appointmentNumber: AgendaDetails?.apptNumber,
                appointmentId: AgendaDetails?.apptId,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                roleTitle: userdata?.roleTitle,
                formDetails: values?.vaccinationForm
            };
            try {
                let response = await ASSIGN_VACCINATION_CALENDAR(payload)
                setLoader(false)
                addvaccination.resetForm()
                toast.success(response.message)
                getAssignVaccinationCalendarList()
                getVaccinationCategoryList()
                closeModalFun()
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    const getAssignVaccinationCalendarList = async () => {
        const payload = {
            patientId: AgendaDetails?.id,
            appointmentId: AgendaDetails?.apptId,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
        };
        setLoader(true)
        try {
            let response = await VACCINATION_CALENDAR_PATIENT(payload)
            setAssignVaccinationcalenderList(response?.data?.map((cur) => {
                return { ...cur, doctorName: `${cur?.Doctor} ${cur?.doctorLastName}` }
            }))
            setLoader(false)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            setAssignVaccinationcalenderList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }

    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "rx") {
            addMedicine.setFieldValue("rx", newValue);
        }
        if (name === "type") {
            addMedicine.setFieldValue("type", newValue);
            addMedicine.setFieldValue("unit", [])
        }
        if (name === "unit") {
            addMedicine.setFieldValue("unit", newValue)
        }
        if (name === "category") {
            addvaccination.setFieldValue("category", newValue);
            getlistVaccinationForm(newValue?.[0]?.vaccinationCategoryId)
            addvaccination.setFieldValue("vaccinationForm", []);
        }
        if (name === "vaccinationForm") {
            addvaccination.setFieldValue("vaccinationForm", newValue);
        }
    }

    const handleChagneGivenon = (e, formIndex) => {
        setOpenModal((pre) => ({
            ...pre, data: {
                ...pre?.data, vaccinationFormFieldArray: pre.data.vaccinationFormFieldArray.map((cur, index) => {
                    if (formIndex === index) {
                        return { ...cur, newGivenOn: e.target.value }
                    }
                    return cur
                })
            }
        }))

    }
    const handleSubmitVaccination = async () => {
        let payload = {
            vaccinationFormArray: openModal?.data?.vaccinationFormFieldArray?.map((cur, index) => {
                if (cur?.newGivenOn) {
                    return { ...cur, givenOn: cur?.newGivenOn, newGivenOn: undefined }
                }
                return cur
            })
        }
        setLoader(true)
        try {
            let response = await UPDATE_VACCINATION_CALENDAR_PATIENT(payload)
            toast.success(response.message)
            setLoader(false)
            handleClosedVaccinationModal("viewVaccinationForm")
            getAssignVaccinationCalendarList()
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
        }
    }

    // tempale library

    const closeModal = () => {
        setOpenTemplateListModal(() => ({ data: null, open: false }));
        setOpenFillFormModal(() => ({ data: null, open: false }));
        setOpenCategoryListModal(() => ({ data: null, open: false }));
    }

    const getAssignedTemplateForm = async (id) => {
        setLoader(true)
        const payload = {
            providerId: id ? undefined : (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: id ? undefined : userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            // staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            page: controller?.page,
            count: controller?.rowsPerPage,
            patientId: AgendaDetails?.id,
            appointmentId: AgendaDetails?.apptId,

        };
        try {
            let response = await ASSIGNED_TEMPLATE_FORM_LIST(payload)
            setAssignedTemplateFormList(response?.data)
            setLoader(false)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            setPaginationTotalCount(0)
            setAssignedTemplateFormList([])
            setLoader(false)
        }
    }
    async function getCategoryList() {
        const payload = {
            deletedStatus: false,
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        };
        try {
            let response = await CATEGORY_LIST(payload)
            setCategoryList(response?.data)
        } catch (error) {
            setCategoryList([])
        }
    }

    async function getTemplateList() {
        setLoader(true)
        const payload = {
            deletedStatus: false,
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            formCategoryId: openCategoryListModal?.data?.formCategoryId
        };
        try {
            let response = await TEMPLATE_FORM_LIST(payload)
            setTemplateFomrList(response?.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setTemplateFomrList([])
        }
    }
    // vaccination template form  list
    async function handleAssignForm(filledData) {
        setLoader(true)
        const payload = {
            providerId: openFillFormModal?.data ? undefined : ((userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId),
            frontdeskId: (userdata.roleTitle === RoleTitle.FRONT_DESK && !openFillFormModal?.data) ? userdata?.userId : undefined,
            formCategoryId: openFillFormModal?.data ? undefined : openCategoryListModal?.data?.formCategoryId,
            staffId: (userdata.roleTitle === RoleTitle.STAFF && !openFillFormModal?.data) ? userdata?.userId : undefined,
            roleTitle: userdata.roleTitle,
            patientId: openFillFormModal?.data ? undefined : AgendaDetails?.id,
            appointmentId: openFillFormModal?.data ? undefined : AgendaDetails?.apptId,
            formId: openFillFormModal?.data ? openFillFormModal?.data?.id : undefined,
            formIds: !openFillFormModal?.data ? [filledData?.formId] : undefined,
            status: openFillFormModal?.data ? undefined : "FILLED",
            answers: filledData?.answers,
            filledPercentage: filledData?.filledPercentage,
            filter: openFillFormModal?.data ? undefined : "AGENDA"
        };
        try {
            let response = openFillFormModal?.data ? await FILL_UPDATE_TEMPLATE_FORM(payload) : await ASSIGNED_TEMPLATE_FORM(payload)
            closeModal()
            toast.success(response?.message)
            getAssignedTemplateForm()
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    useEffect(() => {
        if (removeButton) {
            if (openModal.downloadVaccinationForm) {
                downloadContent()
                handleClosedVaccinationModal("downloadVaccinationForm")
            }
            if (openModal.printVaccinationForm) {
                handlePrint()
                handleClosedVaccinationModal("printVaccinationForm")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeButton])
    useEffect(() => {
        if (splitLocation[splitLocation?.length - 1] !== "overview") {
            listAppointment()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    useEffect(() => {
        if (splitLocation[splitLocation?.length - 1] === "overview") {
            if (tab === 1) getEprescriptionList()
            if (tab === 2) getreportList()
            if (tab === 3) {
                getVaccinationCategoryList()
                getAssignVaccinationCalendarList()
            }
            if (tab === 4) getAssignedTemplateForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    useEffect(() => {
        setController({ page: 1, rowsPerPage: 10 })
    }, [tab])


    return {
        navigate,
        dispatch,
        uploadPdf,
        setShowPDFModal,
        setPdfPathName,
        setShow,
        getlistVaccinationForm,
        setUrl,
        setTab,
        setPatientDetails,
        setController,
        handalSearchAbleSelectBox,
        setAnchorEl,
        medicineList,
        openModalFun,
        closeModalFun,
        handleClosedModal,
        handleOpenModal,
        handleDelete,
        handleClosedVaccinationModal,
        handleChagneGivenon,
        handleSubmitVaccination,
        setremoveButton,
        handleOpenVaccinationModal,
        setVaccinationSerialNumberPath,
        setVaccinationSerialNumberImage,
        setOpenCategoryListModal,
        getCategoryList,
        setOpenTemplateListModal,
        getTemplateList,
        setOpenFillFormModal,
        handleAssignForm,
        setOpenViewFormModal,
        closeModal,
        openViewFormModal,
        openFillFormModal,
        templateFomrList,
        openTemplateListModal,
        headCellsTemplateForm,
        openCategoryListModal,
        headCellsTemplateCategory,
        categoryList,
        controller,

        assignedTemplateFormList,
        headCellsTemplate,
        vaccinationSerialNumberImage,
        vaccinationSerialNumberPath,
        componentRef,
        contentRef,
        removeButton,
        openModal,
        assignVaccinationcalenderList,
        anchorEl,
        openMenu,
        ITEM_HEIGHT,
        CustomButton,
        userdata,
        paginationTotalCount,
        show,
        loader,
        apptList,
        patientDeatils,
        medicineData,
        addMedicine,
        Url,
        addReport,
        ListVaccinationCategory,
        addvaccination,
        UploadReportType,
        VaccinationFormList,
        MultipleReportData,
        pdfPathName,
        vaccinationData,
        ShowPDFModal,
        headCells,
        tab,
        headCellsPrescription,
        CreateModal,
        headCellsReport,
        headCellsVaccination,
        EprescriptionList,
        openDeleteModal,
        isFrontDeskPermission
    }
}

export default useTodayAgenda
