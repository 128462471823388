import React from 'react'

const NoRecordFound = () => {
    const network = JSON.parse(localStorage.getItem("network"))
    return (
        <>
            <div className='NoRecordFound'>
                {
                    network ? <img src={require("../Assets/Images/internetError.png")} alt="" className='no_record_img' /> :
                        <img src={require("../Assets/Images/norecordfoundNew.1.png")} alt="" className='no_record_img' />
                }
                {
                    network ? <h3 className='no_record_text'>No internet connection was found. Check <br /> your connection or  try again </h3> : <h3 className='no_record_text'>No record found  </h3>
                }

            </div>

        </>
    )
}
const NoRecordFoundInside = () => {
    const network = JSON.parse(localStorage.getItem("network"))
    return (
        <>
            <div className=''>
                {
                    network ? <img src={require("../Assets/Images/internetError.png")} alt="" className='no_record_img' /> :
                        <img src={require("../Assets/Images/norecordfoundNew.1.png")} alt="" className='no_record_img' />
                }
                {
                    network ? <h3 className='no_record_text'>No internet connection was found. Check <br /> your connection or  try again </h3> : <h3 className='no_record_text'>No record found  </h3>
                }

            </div>

        </>
    )
}



export  {NoRecordFound,NoRecordFoundInside}
