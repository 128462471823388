import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import useCreateForm from './Hooks/useCreateForm'
import { CustomMenuItem, CustomSelect, SearchAbleSelectBox, SelectBox } from '../../Component/Inputs'
import { Errors } from '../../Component/Error'
import { fieldSizeDataOptions, templateFieldOptions } from '../../Context/constants'
import { TagsInput } from 'react-tag-input-component';
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { Checkbox, FormControlLabel, FormGroup, InputBase, Radio, RadioGroup } from '@mui/material'
import { IoMdCloseCircle } from "react-icons/io";
const CreateTemplateForm = () => {
    const {
        editFieldOption,
        copyField,
        deleteField,
        navigate,
        handalSearchAbleSelectBox,
        id,
        editfieldIndex,
        formik,
        delimiters,
        categoryList,
        createForm,
        viewFieldsArry,
    } = useCreateForm()

    const { CustomButton, GreenSwitch } = useMuiCompontent()
    return (
        <>
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate("/template-library/template-list") }} />
                        <h2 className='page_name'>{id ? 'Update' : "Create"} Template Form</h2>
                    </div>
                </header>
                <div className=" common-white-bg-box d-flex justify-content-between flex-column  mx-0 pt-2   shadow">
                    <div className="row h-100 mx-0">
                        <div className="col-4 border-end flex-fill">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label className="form-label mb-0">Fields<span className='Star_Mark'>*</span></label>
                                    <SelectBox
                                        placeholder="Select Title"
                                        value={formik?.values?.type}
                                        name="type"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={(e) => {
                                            let value = e.target.value
                                            if ((value === "Text" || value === "Date" || value === "Title")) {
                                                formik.setFieldValue("options", [])
                                            }
                                            formik.setFieldValue("type", e.target.value)
                                        }}
                                        option={templateFieldOptions}
                                    />
                                    <Errors formikfun={formik} name={"type"} />
                                </div>
                                <div className="col-12 mb-3">
                                    <label className="form-label mb-0">Label<span className='Star_Mark'>*</span></label>
                                    <input type="text" className="form-control" name='label' onChange={(e) => formik.setFieldValue("label", e.target.value)}
                                        value={formik.values.label}
                                    />
                                    <Errors formikfun={formik} name={"label"} />
                                </div>
                                <div className="col-12 mb-3">
                                    <label className="form-label mb-0">Field Size<span className='Star_Mark'>*</span></label>
                                    <SelectBox
                                        placeholder="Select Title"
                                        value={formik?.values?.fieldSize}
                                        name="fieldSize"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={(e) => formik.setFieldValue("fieldSize", e.target.value)}
                                        option={fieldSizeDataOptions}
                                    />
                                    <Errors formikfun={formik} name={"fieldSize"} />
                                </div>

                                {
                                    (formik?.values?.type === "CheckBox" || formik?.values?.type === "Dropdown" || formik?.values?.type === "Multiple Choice") && <div className="col-12 mb-3">
                                        <label className="form-label mb-0">Options<span className='Star_Mark'>*</span></label>
                                        <TagsInput
                                            autocomplete
                                            delimiters={delimiters}
                                            value={formik?.values?.options}
                                            onChange={(e) => formik.setFieldValue("options", e)}
                                            name="options"
                                            placeHolder="Enter option"
                                            classNames={"Word-Break"}
                                        />
                                        <Errors formikfun={formik} name={"options"} />
                                    </div>
                                }
                                {
                                    formik?.values?.type !== "Title" && <div className="col-12 d-flex justify-content-between align-items-center">
                                        <label className="form-label mb-0">Required</label>
                                        <GreenSwitch checked={formik?.values?.required} onChange={(e) => formik.setFieldValue("required", e.target.checked)} />
                                    </div>
                                }

                                <div className="col-12 text-center mt-4">
                                    <CustomButton variant="contained" onClick={formik.submitForm}  >{editfieldIndex !== null ? "Update" : "Add"}</CustomButton>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 flex-fill d-flex justify-content-between flex-column">
                            <div className="row d-flex">
                                <div className="col-4">
                                    <label className="form-label mb-0">Form category</label>
                                    <SearchAbleSelectBox
                                        multiple={false}
                                        option={categoryList}
                                        getOptionLabel="name"
                                        isOptionEqualToValue="formCategoryId"
                                        value={createForm?.values?.formCategory}
                                        name="formCategory"
                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                    />
                                    <Errors formikfun={createForm} name={"formCategory"} />
                                </div>
                                <div className="col-4  ">
                                    <label className="form-label mb-0">Form Name<span className='Star_Mark'>*</span></label>
                                    <input maxLength={100} type="text" className="form-control" name='formName' onChange={(e) => createForm.setFieldValue("formName", e.target.value)}
                                        value={createForm?.values?.formName}
                                    />
                                    <Errors formikfun={createForm} name={"formName"} />
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                {viewFieldsArry?.length > 0 && (
                                    viewFieldsArry?.map((result, fieldIndex) => {
                                        if (result?.type === 'Text') {
                                            return <div className={`mt-3 ${result?.fieldSize}`} key={fieldIndex}>
                                                <div className='d-flex justify-content-between'>
                                                    <label className="form-label mb-0">{result?.label} {result?.required && <span className='Star_Mark'>*</span>}</label>
                                                    <div>
                                                        <i className="fa-solid fa-pen-to-square cursor  me-2" onClick={() => editFieldOption(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <i className="fa-regular fa-clone cursor  me-2" onClick={() => copyField(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <IoMdCloseCircle onClick={() => deleteField(fieldIndex)} className='mb-1 cursor' style={{ color: '#d90000', fontSize: "20px" }} />
                                                    </div>
                                                </div>
                                                <input maxLength={50} type="text" className="form-control" />
                                            </div>
                                        }
                                        if (result?.type === 'Date') {
                                            return <div className={`mt-3 ${result?.fieldSize}`} key={fieldIndex}>
                                                <div className='d-flex justify-content-between'>
                                                    <label className="form-label mb-0">{result?.label} {result?.required && <span className='Star_Mark'>*</span>}</label>
                                                    <div>
                                                        <i className="fa-solid fa-pen-to-square cursor  me-2" onClick={() => editFieldOption(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <i className="fa-regular fa-clone cursor  me-2" onClick={() => copyField(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <IoMdCloseCircle onClick={() => deleteField(fieldIndex)} className='mb-1 cursor' style={{ color: '#d90000', fontSize: "20px" }} />
                                                    </div>
                                                </div>
                                                <input type="date" className="form-control position-relative" />
                                            </div>

                                        }
                                        if (result?.type === 'Multiple Choice') {
                                            return <div className={`mt-3 ${result?.fieldSize}`} key={fieldIndex}>
                                                <div className='d-flex justify-content-between'>
                                                    <label className="form-label mb-0">{result?.label} {result?.required && <span className='Star_Mark'>*</span>}</label>
                                                    <div>
                                                        <i className="fa-solid fa-pen-to-square cursor  me-2" onClick={() => editFieldOption(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <i className="fa-regular fa-clone cursor  me-2" onClick={() => copyField(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <IoMdCloseCircle onClick={() => deleteField(fieldIndex)} className='mb-1 cursor' style={{ color: '#d90000', fontSize: "20px" }} />
                                                    </div>
                                                </div>
                                                {result?.options?.length > 0 && (
                                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                                                        {
                                                            result?.options?.map((val, index) =>
                                                                <FormControlLabel key={index} value={val} control={<Radio />} label={val?.name ? val?.name : val} />
                                                            )}
                                                    </RadioGroup>
                                                )}
                                            </div>

                                        }
                                        if (result?.type === 'CheckBox') {
                                            return <div className={`mt-3 ${result?.fieldSize}`} key={fieldIndex}>
                                                <div className='d-flex justify-content-between'>
                                                    <label className="form-label mb-0">{result?.label} {result?.required && <span className='Star_Mark'>*</span>}</label>
                                                    <div>
                                                        <i className="fa-solid fa-pen-to-square cursor  me-2" onClick={() => editFieldOption(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <i className="fa-regular fa-clone cursor  me-2" onClick={() => copyField(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <IoMdCloseCircle onClick={() => deleteField(fieldIndex)} className='mb-1 cursor' style={{ color: '#d90000', fontSize: "20px" }} />
                                                    </div>
                                                </div>
                                                <FormGroup row >
                                                    {result?.options?.length > 0 && (
                                                        result?.options?.map((cur, index) =>
                                                            <FormControlLabel key={index} value={cur} control={<Checkbox size='small' />} label={cur?.name ? cur?.name : cur} className='d-block' />
                                                        ))}
                                                </FormGroup>
                                            </div>

                                        }
                                        if (result?.type === 'Dropdown') {
                                            return <div className={`mt-3 ${result?.fieldSize}`} key={fieldIndex}>
                                                <div className='d-flex justify-content-between'>
                                                    <label className="form-label mb-0">{result?.label} {result?.required && <span className='Star_Mark'>*</span>}</label>
                                                    <div>
                                                        <i className="fa-solid fa-pen-to-square cursor  me-2" onClick={() => editFieldOption(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <i className="fa-regular fa-clone cursor  me-2" onClick={() => copyField(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <IoMdCloseCircle onClick={() => deleteField(fieldIndex)} className='mb-1 cursor' style={{ color: '#d90000', fontSize: "20px" }} />
                                                    </div>
                                                </div>
                                                <CustomSelect
                                                    className={`w-100`}
                                                    size='small'
                                                    input={<InputBase />}
                                                >
                                                    {
                                                        result?.options?.map((cur, inndex) => {
                                                            return <CustomMenuItem key={cur?.optionId ? cur?.optionId : cur} value={cur?.optionId ? cur?.optionId : cur}>{cur?.name ? cur?.name : cur}</CustomMenuItem>
                                                        })
                                                    }
                                                </CustomSelect>
                                            </div>

                                        }
                                        if (result?.type === 'Title') {
                                            return <div className={`mt-3 ${result?.fieldSize}`} key={fieldIndex}>
                                                <div className='d-flex justify-content-between'>
                                                    <label className="form-label mb-0 fs-5">{result?.label}</label>
                                                    <div>
                                                        <i className="fa-solid fa-pen-to-square cursor  me-2" onClick={() => editFieldOption(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <i className="fa-regular fa-clone cursor  me-2" onClick={() => copyField(result, fieldIndex)} style={{ color: '#3394e9', fontSize: "15px" }} />
                                                        <IoMdCloseCircle onClick={() => deleteField(fieldIndex)} className='mb-1 cursor' style={{ color: '#d90000', fontSize: "20px" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        return null
                                    })
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12 text-end mb-2">
                                    <CustomButton variant="contained" onClick={createForm.handleSubmit} >{id ? "Update" : "Save"}</CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default CreateTemplateForm
