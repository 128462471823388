import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom'
import useReduxData from '../../Hooks/useReduxData'
import { toast } from 'react-toastify'
import { addPatinetDetails } from '../../Redux/ReducerFile/Patientdetails'
import Loading from '../../Component/Loading'
import { GET_PATIENT_DETAILS, PET_LIST } from '../Repository/PatientRepo'
import { FaArrowLeft } from 'react-icons/fa'
const SubPatientDetails = ({ type }) => {
    const [loader, setLoader] = useState(false)
    const response = useSelector((store) => store?.patientdetailsRDX)
    const { reduxData: MemberType, dispatch } = useReduxData("SubMemberTypeRDX")
    const { id, subpatientId } = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const splitPath = pathname.split("/")
    const ActiveTab = splitPath[splitPath.length - 1]
    // patients details by id
    const getpatientDetails = async () => {
        let payload = {
            patientId: type === "Patients" ? id : subpatientId
        }
        try {
            let response = await GET_PATIENT_DETAILS(payload)
            setLoader(false)
            dispatch(addPatinetDetails(response.data))
        } catch (error) {
            toast.error(error.message);
            setLoader(false)
        }
    }
    // pet details by id
    const getPetDetails = async () => {
        let payload = {
            patientId: subpatientId,
        }
        setLoader(true)
        try {
            let response = await PET_LIST(payload)
            dispatch(addPatinetDetails(response.data))
            navigate("detail")
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    useEffect(() => {
        if (MemberType) {
            getpatientDetails()
        } else {
            getPetDetails()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => navigate(`/patients/${id}/sub-members`)} />
                        <h2 className='page_name'>{`${response?.firstName ? response?.firstName : ""} ${response?.lastName ? response?.lastName : ""}`}</h2>
                    </div>
                </header>
                <div className="common-white-bg-box mx-0 shadow  position-relative pt-0 ">
                    <div className="col-12 pb-3 position-sticky top-0 z-3 pt-2 tab_scroll" style={{ borderBottom: "2px solid #ededed", background: "#fff" }}>
                        <ul className="nav  mb-0  pb-1 px-2 " style={{width:"888px"}}>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${(ActiveTab === "details" || ActiveTab === "detail") && "active_teams_frofile"}`} onClick={() => navigate(`${response?.petType ? "detail" : "details"}`)} id="personal" type="button">Basic Details</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "appointments-history" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`appointments-history`)}>Appointments History</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "e-prescription" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`e-prescription`)} >E-prescription</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "test-report" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`test-report`)} >Test Reports</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "vaccine-calendar" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("vaccine-calendar")} >Vaccination Record</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "vitals" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("vitals")} >Vitals</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "checkout-slip" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("checkout-slip")} >Checkout Slip</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "shared-form" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("shared-form")} >Shared/Filled Form</button>
                            </li>
                        </ul>
                    </div>
                    <div className='px-2 PATIENT-OUTLET-HIEGHT mx-3 '>
                        <Outlet context={{ getpatientDetails }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubPatientDetails
