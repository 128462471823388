import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { confirmWaitingAppt } from '../../../Context/api';
import { axiosInstance } from "../../../Context/CraeteAxios";
import useReduxData from "../../../Hooks/useReduxData";
import { AppointmentStatus, RoleTitle, commonMessages } from "../../../Context/constants";
import { CLINIC_LIST, GET_BASIC_DETAILS } from "../../Repository/ProfileRepo";
import { SERVICE_LIST } from "../../Repository/ExpertiseRepo";
import { PATIENT_LIST } from "../../Repository/PatientRepo";
import { useFormik } from "formik";
import { INSERT_APPOINTMENT, REMOVE_PATIENT_WAITING, TIME_SLOT_LIST, WAITING_LIST } from "../../Repository/AppointmentsRepo";
import moment from "moment-timezone";
// import { createAppointmentStaffValidation, createAppointmentValidation } from "../../../Context/Validation";
const useWaitingList = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [waitingListData, setWaitingListData] = useState([])
    const [patientFilterList, setPatientFilterList] = useState([])
    const [clinicList, setClinicList] = useState([])
    const [teamList, setTeam] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [TimeslotList, setTimeSlotList] = useState([])
    const [offersList, setOffersList] = useState([])
    const [apptId, setApptId] = useState([])
    const [Loader, setLoader] = useState(false)
    const [SlotLodaer, setSlotLodaer] = useState(false)
    const [check, setCheck] = useState(false)
    const [isActiveSlot, setIsactiveSlot] = useState(null)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const [openModal, setOpenModal] = useState({
        addPatient: false,
        bookAppointment: false,
        removePatient: false,
        data: null
    })
    const [Filter, setFilter] = useState({
        waitingList: "",
        disable_button: true
    })
    useEffect(() => {
        waitingList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    useEffect(() => {
        document.title = " Waiting List"
    }, [])

    const handleOpenModal = (type, data = null) => {
        if (type === "bookAppointment") {
            getListproviderclinics(data?.clinicId)
            // getservicesList(data?.clinicId, data?.serviceId)
            // getlistPatient(data?.patientDetails);
            // getliststaff(data?.clinicId, data?.serviceId, data?.staffId)
            // getSlotfun(data.startDate, data?.staffId, data?.clinicId, data?.serviceId)
            createAppointment?.setFieldValue("visitType", data.visitType)
            createAppointment?.setFieldValue("date", data.startDate)
            createAppointment?.setFieldValue("service", [data.serviceDetails])
        }
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        if (type === "addPatient" || type === "bookAppointment") {
            setIsactiveSlot(null)
            createAppointment.resetForm()
            setCheck(false)
            setOffersList([])
            setTimeSlotList([])
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    // get waiting list
    const waitingList = async () => {
        setLoader(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { waitingList } = Filter;
        const keyword = waitingList?.trim();
        const payload = {
            searchKeyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            roleTitle: userdata.roleTitle,
            startDate: moment().startOf('day'),
            endDate: moment().add(1, 'year'),
            clinicId: userdata?.location?.clinicId
        };
        try {
            let response = await WAITING_LIST(payload)
            setLoader(false)
            setWaitingListData(response.data)
            setPaginationTotalCount(response.totalCount)
        } catch (error) {
            toast.error(error.message)
            setPaginationTotalCount(0)
            setWaitingListData([])
            setLoader(false)
        }
    }
    // patient list
    const getlistPatient = async (patientdetails = []) => {
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            patientFilter: "ALL",
            roleTitle: userdata?.roleTitle,
        }
        setLoader(true)
        try {
            let response = await PATIENT_LIST(payload)
            setPatientFilterList(response.data)
            setLoader(false)
            if (patientdetails) {
                const patientIds = patientdetails.map(patient => patient?.patientId);
                const patientDetails = response?.data?.filter((cur) => patientIds?.includes(cur?.id))
                createAppointment.setFieldValue("patientName", patientDetails)
            }
        } catch (error) {
            setLoader(false)
            setPatientFilterList([])
        }
    }
    //clinic list
    const getListproviderclinics = async (clinicId) => {
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,

            activeStatus: true,
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            if (clinicId) {
                const clinicDetails = response?.data?.filter((cur, index) => cur?.clinicId === clinicId)
                createAppointment.setFieldValue("clinic", clinicDetails)
            }
            setLoader(false)
        } catch (error) {
            setClinicList([])
            toast.error(error?.message)
            setLoader(false)
        }
    }
    // staff list
    const getliststaff = async (clinicId, serviceId, staffId) => {
        setLoader(true)
        const payload = {
            getStaffList: true,
            activeStatus: true,
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            clinicId,
            serviceId,
            invitationStatus: "ACCEPTED"
        };
        try {
            let response = await GET_BASIC_DETAILS(payload)
            setTeam(response.data)
            if (clinicId) {
                const StaffDetails = response?.data?.filter((cur, index) => cur?.userId === staffId)
                createAppointment.setFieldValue("teamMember", StaffDetails)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            setTeam([])
        }
    }
    // service list
    const getservicesList = async (clinicId, serviceId) => {
        setLoader(true)
        const payload = {
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            roleTitle: userdata.roleTitle === RoleTitle.STAFF ? RoleTitle.STAFF : undefined,
            clinicId: clinicId
        };

        try {
            let response = await SERVICE_LIST(payload)
            const serviceList = response?.data?.map(cur => ({
                ...cur,
                specializationName: cur?.specialization?.specializationName
            })) || [];
            setServicesList(serviceList);
            if (serviceId) {
                const servicesDetails = serviceList?.filter((cur, index) => cur?.serviceId === serviceId)
                createAppointment.setFieldValue("service", servicesDetails)
            }
            setLoader(false)
        } catch (error) {
            setServicesList([])
            setLoader(false)
        }

    }
    // slot list
    const getSlotfun = async (date, teamMember, clinic, serviceId) => {
        if (clinic && serviceId && (teamMember || userdata.userId) && date) {
            let payload = {
                staffId: userdata?.roleTitle === RoleTitle.STAFF ? userdata.userId : teamMember,
                serviceId: serviceId,
                date: moment(date).startOf('day'),
                // date: moment(date).startOf("day").utc(),
                timezoneName: userdata?.timezoneName,
                day: moment(date).format('dddd'),
                clinicId: clinic,
            }
            setSlotLodaer(true)
            setTimeSlotList([])
            try {
                let response = await TIME_SLOT_LIST(payload)
                setTimeSlotList(response.data)
                setSlotLodaer(false)
            } catch (error) {
                setTimeSlotList([])
                setSlotLodaer(false)
            }
        }
    }
    const apptBook = () => {
        setLoader(true)
        axiosInstance.post(confirmWaitingAppt(), {
            userId: userdata.userId,
            apptId: apptId
        }).then((resp) => {
            if (resp.data.status === '200') {
                toast.success(resp.data.message)
                waitingList()
                setShow2(false)
            } else if (resp.data.status === "500") {
                toast.error(commonMessages.NetworkError);
            }
            else {
                toast.error(resp.data.message)
            }
            setLoader(false)
        }).catch(() => {
            toast.error(commonMessages.NetworkError);
            setLoader(false)
        })
    }
    // remove patient
    const handleRemovePatinet = async () => {
        let payload = {
            waitingNumber: openModal?.data?.waitingNumber
        }
        try {
            let response = await REMOVE_PATIENT_WAITING(payload)
            toast.success(response.message)
            handleClosedModal("removePatient")
            if (waitingListData?.length === 1 && controller.page === 1) {
                waitingList()
            }
            else if (waitingListData?.length === 1) {
                setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
            } else waitingList()

        } catch (error) {
            toast.error(error.message)
        }
    }
    const headCells = [
        { id: "startDate", numeric: false, disablePadding: true, label: "Date", showIcon: true },
        { id: "Time", numeric: true, disablePadding: false, label: "Time" },
        { id: "teamMember", numeric: true, disablePadding: false, label: "Doctor", showIcon: true },
        { id: "specialisationName", numeric: true, disablePadding: false, label: "Specialization", showIcon: true },
        { id: "serviceName", numeric: true, disablePadding: false, label: "Services Name", showIcon: true },
        { id: "patientName", numeric: true, disablePadding: false, label: "Patient Name", showIcon: false },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    // appointment create
    const createAppointment = useFormik({
        initialValues: {
            patientName: [],
            service: [],
            clinic: [],
            date: '',
            startTime: '',
            endTime: '',
            teamMember: [],
            visitType: '',
            couponCode: "",
        },
        // validationSchema: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? createAppointmentValidation : createAppointmentStaffValidation,
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if (!openModal?.bookAppointment && (!values?.patientName || values?.patientName?.length === 0)) {
                errors.patientName = 'Please select patient';
            }
            if (!values?.date) {
                errors.date = 'Please select date';
            }
            if (!openModal?.bookAppointment && (!values?.service || values?.service?.length === 0)) {
                errors.service = 'Please select service';
            }
            if (!values?.visitType) {
                errors.visitType = 'Please select visit type';
            }
            if (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER) {
                if (!openModal?.bookAppointment && (!values?.teamMember || values?.teamMember?.length === 0)) {
                    errors.teamMember = 'Please select team';
                }
            }
            return errors;
        },
        onSubmit: async (values) => {
            if (values.patientName?.length > SERVICES_DETAILS?.noOfPatients) {
                return toast.warn(`You can select ${SERVICES_DETAILS?.noOfPatients} patient `)
            }
            if (new Date(values.startTime).getTime() === new Date(values.endTime).getTime())
                return toast.warn("Start time and  end time can not be equal");
            if (!values.startTime && !values.endTime) {
                return toast.warn(`Please select appointment time`)
            }
            if (values.startTime > values.endTime)
                return toast.warn(
                    " End  time can not be greater than start time"
                );
            const data = {
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                clinicId: userdata?.location?.clinicId,
                visitType: values.visitType,
                startDate: values.startTime,
                endDate: values.endTime,
                status: openModal?.bookAppointment ? AppointmentStatus.BOOKED : AppointmentStatus.WAITING,
                // currrentDateTime: curDateTime,
                totalPrice: OFERS_DETAILS?.discountPrice || SERVICES_DETAILS?.fixedPrice,
                roleTitle: userdata.roleTitle,
                timezoneName: userdata?.timezoneName,
                offerId: OFERS_DETAILS?.offerId,
                waitingListId: openModal?.bookAppointment ? openModal?.data?.waitingNumber : undefined,
                staffId: (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? (!openModal?.bookAppointment ? Staff_DETAILS?.userId : openModal?.data?.staffId) : userdata?.userId,
                serviceDetails: values.service[0],
                patientDetails: !openModal?.bookAppointment ? values?.patientName?.map((cur) => ({ patientId: cur?.id, firstName: cur?.firstName, lastName: cur?.lastName })) : openModal?.data?.patientDetails?.map((cur) => ({ patientId: cur?.patientId, firstName: cur?.patientFirstName, lastName: cur?.patientLastName })),
            }
            if (openModal.addPatient) {
                let appointmentDate = moment(values.date);
                let appointmentStartTime = moment(values.startTime, 'HH:mm:ss').utc();
                let appointmentEndTime = moment(values.endTime, 'HH:mm:ss').utc();
                // console.log(appointmentStartTime,"appointmentStartTime")
                // console.log(appointmentEndTime,"appointmentEndTime")
                data.startDate = moment.utc(appointmentDate.format('YYYY-MM-DD') + 'T' + appointmentStartTime.format('HH:mm:ss'));
                data.endDate = moment.utc(appointmentDate.format('YYYY-MM-DD') + 'T' + appointmentEndTime.format('HH:mm:ss'));

            }
            setLoader(true)
            try {
                let response = await INSERT_APPOINTMENT(data)
                toast.success(response.message)
                createAppointment.resetForm()
                setTimeSlotList([])
                handleClosedModal(openModal?.bookAppointment ? "bookAppointment" : "addPatient")
                if (waitingListData?.length === 1 && controller.page === 1) {
                    waitingList()
                }
                if (waitingListData?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else waitingList()
                setIsactiveSlot(null)
                createAppointment.resetForm()
                setCheck(false)
                setOffersList([])
                setLoader(false)
            } catch (error) {
                toast.error(error?.message)
                setLoader(false)
            }
        }

    })

    // all details of selectd offers patient services staff clinic
    const OFERS_DETAILS = offersList?.find((cur,) => cur?.discountPrice && cur?.discountPrice)
    // const PATIENT_DETAILS = createAppointment.values?.patientName[0]
    const SERVICES_DETAILS = createAppointment.values?.service[0]
    const Staff_DETAILS = createAppointment.values.teamMember[0]

    // apply offer
    const ApplyOffer = (data) => {
        const temp = [...offersList]
        let offersIndex = temp.findIndex(x => x.offerId === data?.offerId);
        if (data?.offerType === "FLAT") {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than  ${data?.minimumCartValue}`)
            } else {
                temp.forEach((item, itemindex) => {
                    if (item.offerId === data?.offerId) {
                        item.discountPrice = data?.fixedPrice - data?.discountAmount
                    } else {
                        item.discountPrice = null
                    }
                })
                toast.success(`Apply offer successful`)
            }
        } else {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than ${data?.minimumCartValue}`)
            } else {
                temp[offersIndex].discountPrice = data?.fixedPrice - data?.discountAmount
                let price = ((data?.fixedPrice * data?.percentage) / 100).toFixed(2);
                let result = data?.fixedPrice - price
                let newPrice = result.toFixed(2)
                if (newPrice > data?.maxDiscountUpto) {
                    temp.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = data?.maxDiscountUpto
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                } else {
                    temp.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = newPrice
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                }
            }
        }
        setOffersList(temp)
    }
    // apply offer when enter coupon code in input   
    const applyByCouponCodeOffers = () => {
        const offersData = [...offersList]
        let index = offersData.findIndex(x => x.couponCode === createAppointment?.values?.couponCode);
        if (index === -1) {
            return toast.warn("your coupon code in not matching")
        }
        let data = offersData.find(x => x.couponCode === createAppointment?.values?.couponCode);
        if (data.discountPrice) {
            return toast.warn("your coupon already apply")
        }
        if (data?.offerType === "FLAT") {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than  ${data?.minimumCartValue}`)
            } else {
                offersData.forEach((item, itemindex) => {
                    if (item.offerId === data?.offerId) {
                        item.discountPrice = data?.fixedPrice - data?.discountAmount
                    } else {
                        item.discountPrice = null
                    }
                })
                toast.success(`Apply offer successful`)
                createAppointment?.setFieldValue("couponCode", "")
            }
        } else {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than ${data?.minimumCartValue}`)
            } else {
                offersData[index].discountPrice = data?.fixedPrice - data?.discountAmount
                let price = ((data?.fixedPrice * data?.percentage) / 100).toFixed(2);
                let result = data?.fixedPrice - price
                let newPrice = result.toFixed(2)
                if (newPrice > data?.maxDiscountUpto) {
                    offersData.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = data?.maxDiscountUpto
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                    createAppointment?.setFieldValue("couponCode", "")
                } else {
                    offersData.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = newPrice
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                    createAppointment?.setFieldValue("couponCode", "")
                }
            }
        }
        setOffersList(offersData)
    }
    //  cancel offers
    const cancelOffer = (data) => {
        const temp2 = [...offersList]
        let offersIndex2 = temp2.findIndex(x => x.offerId === data?.offerId);
        temp2[offersIndex2].discountPrice = null
        setOffersList(temp2)
        toast.success(`Cancel offer successful`)
    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "service") {
            createAppointment.setFieldValue('teamMember', []);
            createAppointment.setFieldValue('date', "");
            if (openModal?.addPatient) {
                let endDate = moment(createAppointment.values.startTime).add(newValue[0]?.duration, 'minutes').seconds(0);
                createAppointment.setFieldValue('endTime', endDate);
            }
            createAppointment.setFieldValue('service', newValue);
            getliststaff(userdata?.location?.clinicId, newValue?.[0]?.serviceId)
        }
        if (name === "patient") createAppointment.setFieldValue('patientName', newValue)
        if (name === "team") {
            createAppointment.setFieldValue('date', "");
            createAppointment.setFieldValue('teamMember', newValue);
        }
    }
    useEffect(() => {
        if (createAppointment.values.date && openModal?.bookAppointment) {
            getSlotfun(createAppointment.values.date, !openModal?.bookAppointment ? createAppointment?.values?.teamMember?.[0]?.userId : openModal?.data?.staffId, userdata?.location?.clinicId, createAppointment?.values?.service?.[0]?.serviceId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAppointment.values.date])
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ waitingList: "", disable_button: true, })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    return {
        headCells,
        Loader,
        waitingListData,
        paginationTotalCount,
        controller,
        show2,
        show,
        userdata,
        createAppointment,
        isActiveSlot,
        offersList,
        OFERS_DETAILS,
        Staff_DETAILS,
        setCheck,
        check,
        SERVICES_DETAILS,
        getservicesList,
        handleRemovePatinet,
        ApplyOffer,
        cancelOffer,
        applyByCouponCodeOffers,
        setIsactiveSlot,
        handalSearchAbleSelectBox,
        getlistPatient,
        getListproviderclinics,
        handleOpenModal,
        handleClosedModal,
        setShow2,
        setApptId,
        setShow,
        setController,
        apptBook,
        handleChangeFilter,
        HandleSearchList,
        reset,
        Filter,
        SlotLodaer,
        openModal,
        patientFilterList,
        clinicList,
        TimeslotList,
        teamList,
        servicesList
    }
}

export default useWaitingList
