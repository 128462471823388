import React from 'react'
import useRoles from './Hooks/useRoles'
import Loading from '../../Component/Loading'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { IoIosSearch, IoMdAdd } from 'react-icons/io'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { FiRefreshCcw } from 'react-icons/fi'
import { NoRecordFound } from '../../Component/NoRecordFound'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import useTableSorting from '../../Hooks/useTableSorting'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from 'react-bootstrap'
import { Errors } from '../../Component/Error'
import { BiArchiveIn } from 'react-icons/bi'
import { activeStatusOption,  dailogAllMessage } from '../../Context/constants'
import { SelectBox } from '../../Component/Inputs'
const Roles = () => {

    const {
        handleDeleteRole,
        handleClosedModal,
        handleOpenModal,
        handleChangeFilter,
        setController,
        HandleSearchList,
        archiveUnarchive,
        reset,
        openModal,
        formik,
        roleList,
        Filter,
        loader,
        controller,
        paginationTotalCount,
        headCells,
    } = useRoles()
    const { CustomButton, } = useMuiCompontent()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className=" py-2" style={{ height: "100%" }}>
                <div className='pb-2 ' >
                    <ul className="headerlist px-0">
                        <li className='me-2' style={{ width: "210px" }}> <input type="text" placeholder="Search" className="form-control filter_input" name='search' value={Filter?.search} onChange={handleChangeFilter} /> </li>
                        <li className="me-3" style={{ width: 200 }}>
                            <SelectBox
                                placeholder="Select"
                                value={Filter?.archive}
                                name="archive"
                                keys="value"
                                label="label"
                                menuValeu="value"
                                handleChange={handleChangeFilter}
                                option={activeStatusOption}
                            />
                        </li>
                        <li className='me-2'><CustomButton disabled={Filter?.disable_button} startIcon={<IoIosSearch />} variant="contained" onClick={HandleSearchList}>search</CustomButton></li>
                        <li className="me-2" >
                            <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                                <FiRefreshCcw />
                            </IconButton>
                        </li>
                        <li><CustomButton startIcon={<IoMdAdd />} variant="contained" onClick={() => handleOpenModal("addRole")} >add Role</CustomButton></li>
                    </ul>
                </div>
                <div className="row" >
                    {roleList.length === 0 &&
                        <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /></div>
                    }
                    <div className="col-12 h-100">
                        <div className="row h-100  d-flex justify-content-between ">
                            <TableContainer   >
                                {roleList.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table" >
                                    {
                                        roleList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={roleList?.length} />
                                    }
                                    <TableBody className="table_overflow_scroll" >
                                        {stableSort(roleList, getComparator(order, orderBy))?.map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}><TableRow id="hover_tr" style={{ cursor: 'pointer' }}>
                                                    <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                        {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left" onClick={() => handleOpenModal("formView", row)}> {row?.dynamicRoleName}</TableCell>
                                                    {/* <TableCell id={labelId} align="right"  >
                                                        <GreenSwitch checked={row?.activeStatus} size='small' onChange={(e) => handleToggleStatus(e, row)} />
                                                    </TableCell> */}
                                                    <TableCell id={labelId} align="right">
                                                        <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul className="dropdown-menu dropdown-menu-end me-3">
                                                            <li style={{marginLeft:"1px"}}><button className="dropdown-item" onClick={() => handleOpenModal("addRole", row)} ><i className="fa-solid fa-pen-to-square all_icons_color  fs-6 me-1"></i>Edit Role</button></li>
                                                            <li style={{marginLeft:"0px"}} ><button className="dropdown-item" href="#" onClick={() => { handleOpenModal("archiveRole", row) }}><BiArchiveIn className="all_react_icons_color me-1 " size={18} />{`${row?.activeStatus === true ? "Archive" : "Unarchive"} Role`}</button></li>
                                                            <li style={{marginLeft:"3px"}} ><button className="dropdown-item" href="#" onClick={() => handleOpenModal("deleteRole", row)} ><i style={{ fontSize: "15px", color: "#888686 ",  }} className="fa-solid fa-trash-can me-2 "></i>Delete Role</button></li>
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="col-12" style={{ height: "10%" }}>
                                <nav className=" w-100  " aria-label="pagination">
                                    {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={openModal?.addRole} onHide={() => { handleClosedModal("addRole"); formik.resetForm(); }} centered>
                <Modal.Body>
                    <div className="row"></div>
                    <div className='pb-3' style={{ borderBottom: "2px solid #ededed" }}>
                        <p className="modal-title mb-0"> {openModal?.data ? "Update Role" : "Add Role"} </p>
                    </div>
                    <div className="modal-body  cus-i px-0">
                        <div className="row">
                            <div className="col-sm-12  py-4 ">
                                <div className="row">
                                    <div className="col-4">
                                        <label className="form-label mb-0">Role Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-8">
                                        <input maxLength={50} type="text" className="form-control" id="exampleInputEmail1" value={formik?.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} aria-describedby="emailHelp" />
                                        <Errors formikfun={formik} name={"name"} />
                                    </div>
                                </div>
                            </div>
                            <div className="text-end mt-3 col-12">
                                <CustomButton variant="outlined" type='button' className='me-3' onClick={() => { handleClosedModal("addRole"); formik.resetForm() }} >cancel</CustomButton>
                                <CustomButton variant="contained" onClick={formik.handleSubmit} >Save</CustomButton>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openModal?.archiveRole} onHide={() => handleClosedModal("archiveRole")} centered>
                <Modal.Body>
                    <div className="modal-body text-center">
                        <BiArchiveIn className="all_react_icons_color " />
                        <p className="mb-0">Are you sure? </p>
                        <p>{openModal?.data?.deletedStatus === false ? dailogAllMessage.Role.Archive : dailogAllMessage.Role.UnArchive}</p>
                        <CustomButton variant="outlined" className="me-3" onClick={() => archiveUnarchive("NO")} > No</CustomButton>
                        <CustomButton variant="contained"  onClick={() => archiveUnarchive("YES")} > Yes</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.deleteRole} onHide={() => handleClosedModal("deleteRole")} centered>
        <Modal.Body className='px-0'>
          <div className="modal-body text-center cus-i px-0">
            <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
            <p className='mb-0'>Are you sure?</p>
            <p>You want to delete this role</p>
            <CustomButton variant="contained" className="me-3" onClick={() => handleDeleteRole("YES")} > Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => handleDeleteRole("NO")} > No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default Roles
