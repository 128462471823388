import { Checkbox, FormControlLabel, FormGroup, InputBase, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { CustomMenuItem, CustomSelect } from '../../Component/Inputs'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { useFormik } from 'formik'
import * as Yup from "yup";
import moment from 'moment-timezone'
import { Button } from '@mui/material';
const ViewForm = ({ roleTitle = null, titleModal = "Submit", viewFieldsArry, allDetails, isEditable = false, handleCancel = () => { }, handleSubmitForm = () => { } }) => {
    const { CustomButton } = useMuiCompontent()

    const initialValues = {};
    const validationSchemaFields = {};
    viewFieldsArry?.forEach((field) => {
        if (field.type === "CheckBox") {
            initialValues[field.fieldId] = Array.isArray(field?.answer) ? field?.answer : [];
        } else if (field?.type === "Multiple Choice" || field?.type === "Dropdown") {
            initialValues[field.fieldId] = field?.answer?.optionId || field?.answer || "";
        } else if (field.type === "Date") {
            initialValues[field.fieldId] = field?.answer ? moment(field.answer, "DD-MM-YYYY").format("YYYY-MM-DD") : ""
        } else {
            initialValues[field.fieldId] = field?.answer || "";
        }

        if (field.required) {
            if (field.type === "CheckBox") {
                validationSchemaFields[field.fieldId] = Yup.array().min(1, `Field is required`);
            } else if (field.type === "Date") {
                validationSchemaFields[field.fieldId] = Yup.string()
                    .required(`Field is required`)
                    .matches(/\d{4}-\d{2}-\d{2}/, "Invalid date format");
            } else {
                validationSchemaFields[field.fieldId] = Yup.string().required(`Field is required`);
            }
        }
    });
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object(validationSchemaFields),
        onSubmit: async (values) => {
            const nonTitleFields = viewFieldsArry.filter((field) => field.type !== "Title");
            const transformedValues = { ...values };
            viewFieldsArry.forEach((field) => {
                if (field.type === "Date" && transformedValues[field.fieldId]) {
                    transformedValues[field.fieldId] = moment(transformedValues[field.fieldId]).format("DD-MM-YYYY");
                }
                if (field.type === "Multiple Choice" && transformedValues[field.fieldId]) {
                    transformedValues[field.fieldId] = Number(transformedValues[field.fieldId])
                }
            });
            const answers = Object.keys(transformedValues).map((key) => {
                return { fieldId: parseInt(key), answer: transformedValues[key] };
            });

            const filledCount = answers.filter((answer) => {
                if (Array.isArray(answer.answer)) {
                    return answer.answer.length > 0;
                }
                return answer.answer !== "" && answer.answer !== null && answer.answer !== undefined;
            })?.length;
            const filledPercentage = (filledCount / nonTitleFields.length) * 100;
            const payload = {
                formId: allDetails.formId,
                filledPercentage,
                roleTitle: roleTitle ? roleTitle : undefined,
                answers,
            };
            await handleSubmitForm(payload);
        },
    });

    return (
        <div className="d-flex flex-column h-100 ">
            <div className="row mx-0">
                <div className="col-6">
                    <label className="form-label mb-0 fs-6">Form Name</label>
                    <p style={{ color: "gray", fontSize: "12px" }}>{allDetails?.name}</p>
                </div>
                <div className="col-6">
                    <label className="form-label mb-0 fs-6">Form Category</label>
                    <p style={{ color: "gray", fontSize: "12px" }}>{allDetails?.formCategoryName}</p>
                </div>
            </div>
            <div className="row mx-0 flex-fill align-content-start">
                {viewFieldsArry?.map((field, index) => (
                    <div className={`mt-3 ${field.fieldSize}  `} key={index}>
                        <label className={`form-label mb-0 ${field.type === "Title" ? "fs-6  " : ""}`}>
                            {field.label} {(field.type !== "Title" && field.required) ? <span className="Star_Mark">*</span> : null}
                        </label>
                        {field.type === "Text" && isEditable && (
                            <>
                                <input type="text" className="form-control" {...formik.getFieldProps(field.fieldId)} />
                            </>
                        )}
                        {field.type === "Text" && !isEditable && (
                            <>
                                <textarea rows="2" value={field?.answer}
                                    className="form-control position-relative" />
                            </>
                        )}
                        {field.type === "Date" && (
                            <>
                                <input type="date" className="form-control position-relative" {...formik.getFieldProps(field.fieldId)} />
                            </>
                        )}
                        {field.type === "Multiple Choice" && (
                            <>
                                <RadioGroup row {...formik.getFieldProps(field.fieldId)}>
                                    {field.options.map((option, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            value={option.optionId}
                                            control={<Radio />}
                                            label={option.name}
                                        />
                                    ))}
                                </RadioGroup>
                            </>
                        )}
                        {field.type === "CheckBox" && (
                            <>
                                <FormGroup row>
                                    {field.options.map((option, idx) => (
                                        <FormControlLabel
                                            key={idx}
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    value={option.optionId}
                                                    checked={
                                                        Array.isArray(formik.values[field.fieldId]) &&
                                                        formik.values[field.fieldId].includes(option.optionId)
                                                    }
                                                    onChange={(e) => {
                                                        const value = option.optionId;
                                                        const currentValues = Array.isArray(formik.values[field.fieldId])
                                                            ? formik.values[field.fieldId]
                                                            : [];
                                                        const newValue = e.target.checked
                                                            ? [...currentValues, value]
                                                            : currentValues.filter((item) => item !== value);
                                                        formik.setFieldValue(field.fieldId, newValue);
                                                    }}
                                                />
                                            }
                                            label={option.name}
                                        />
                                    ))}
                                </FormGroup>
                            </>
                        )}

                        {field.type === "Dropdown" && (
                            <>
                                <CustomSelect
                                    className="w-100"
                                    size="small"
                                    value={formik.values[field.fieldId]}
                                    onChange={(e) => formik.setFieldValue(field.fieldId, e.target.value)}
                                    input={<InputBase />}
                                >
                                    {field.options.map((option, idx) => (
                                        <CustomMenuItem key={idx} value={option.optionId}>
                                            {option.name}
                                        </CustomMenuItem>
                                    ))}
                                </CustomSelect>
                            </>
                        )}
                        {field.type !== "Title" && isEditable && formik.touched[field.fieldId] && formik.errors[field.fieldId] ? (
                            <div className="error " >{formik.errors[field.fieldId]}</div>
                        ) : null}
                    </div>
                ))}
            </div>
            <div className="row mx-0 py-3 ">
                {
                    isEditable && <div className="w-100 text-end px-3">
                        <CustomButton variant="outlined" className="me-3" onClick={handleCancel} >{titleModal === "update" ? "Cancel" : "Back"}</CustomButton>
                        {/* <CustomButton variant="contained" className="" type="button" onClick={handleSubmit}
                        >{titleModal}</CustomButton> */}


                        <Button
                            style={{
                                background: "#38C4B9",
                                textTransform: "capitalize",
                                padding: "5px 8px",
                                fontSize: "13px",

                            }}
                            variant="contained"
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                formik.submitForm();
                            }}
                        >
                            {titleModal}
                        </Button>
                    </div>
                }
            </div>
        </div>
    );

}

export default ViewForm
