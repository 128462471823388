import React from 'react'
import { Errors } from '../../Component/Error';
import moment from 'moment-timezone';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Loading from '../../Component/Loading';
import useEditTeam from './Hooks/useEditTeam';
import { TeamImageFilePath } from '../../Context/api';
import PhoneNumber from '../../Component/PhoneInput';
import { motion } from 'framer-motion';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { SelectBox } from '../../Component/Inputs';
import { BloodGroupOptions, GenderOptions_TEAM, LanguageOptions, RoleTitle, TitleOptions } from '../../Context/constants';
import { Avatar, Badge, styled } from '@mui/material';
import { MdOutlineCameraAlt } from 'react-icons/md';
import DeleteProfile from '../../Component/DeleteProfile';
import useTableSorting from '../../Hooks/useTableSorting';
import { NoRecordFound } from '../../Component/NoRecordFound';
import { IoMdAdd } from 'react-icons/io';
import { SearchAbleSelectBox } from "../../Component/Inputs";
import { GrDocumentPdf } from "react-icons/gr";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from 'react-bootstrap';
const EditTeameProfile = () => {

    const {
        roleList,
        loader,
        imgUrl,
        showform,
        peresanalInfoFormik,
        ListState,
        contactInfoFormik,
        proofData,
        ListDataCountry,
        teamProof,
        StaffDetails,
        viewPDF,
        FileRef,
        alldegreeList,
        UploadImage,
        handalDOB,
        setImgUrl,
        setShowForm,
        setViewPDF,
        navigate,
        setLoader,
        handalSearchAbleSelectBox,

    } = useEditTeam()
    const { CustomButton } = useMuiCompontent()
    const headCells = [
        { id: "degree", numeric: false, disablePadding: true, label: "Degree" },
        { id: "university", numeric: true, disablePadding: false, label: "University", showIcon: true },
        { id: "yearOfPassing", numeric: true, disablePadding: false, label: "Year of Passing", showIcon: true },
        { id: "registrationNumber", numeric: true, disablePadding: false, label: "Registration Number ", showIcon: true },
        { id: "Document", numeric: false, disablePadding: false, label: "Document", },
        { id: "Action", numeric: false, disablePadding: false, label: "Action", showLeft: true },
    ];

    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    return (
        <>
            {loader && <Loading />}
            <div className="" style={{ height: "100%" }}>
                <div className="row pt-3">
                    <div className="col-12 pb-3 px-0 tab_scroll" style={{ borderBottom: "2px solid #ededed" }}>
                        <ul className="nav  mb-0" style={{ width: "520px" }}>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${showform === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => setShowForm(1)} >Personal Information</button>
                            </li>

                            {
                                StaffDetails?.roleId === RoleTitle?.STAFF && <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${showform === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setShowForm(2)} >Education Qualification</button>
                                </li>
                            }

                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${showform === 3 && "active_teams_frofile"}`} id="contact" type="button" onClick={() => setShowForm(3)} >Contact Information</button>
                            </li>
                        </ul>
                        <ul className="nav  mb-0" >
                            {showform === 2 && <li>   <CustomButton variant="contained" startIcon={<IoMdAdd />} onClick={() => navigate('add-degree')}  >Add degree </CustomButton></li>}
                        </ul>
                    </div>
                </div>
                {showform === 1 && <motion.div className=' d-flex justify-content-between  NESTED-CONTAINER-HEIGHT flex-column'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    <div className="col-12" >
                        <div className="row px-0">
                            {
                                StaffDetails?.roleId !== RoleTitle?.STAFF && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Role<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SearchAbleSelectBox
                                                        multiple={false}
                                                        option={roleList}
                                                        getOptionLabel="dynamicRoleName"
                                                        isOptionEqualToValue="dynamicRoleId"
                                                        value={peresanalInfoFormik?.values?.dynamicRoleId}
                                                        name="dynamicRoleId"
                                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"dynamicRoleId"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                            }




                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <SelectBox
                                                    placeholder="Select Title"
                                                    value={peresanalInfoFormik.values.title.trim()}
                                                    name="title"
                                                    keys="value"
                                                    label="label"
                                                    menuValeu="value"
                                                    handleChange={(e) => peresanalInfoFormik.setFieldValue("title", e.target.value)}
                                                    option={TitleOptions}
                                                />
                                                <Errors formikfun={peresanalInfoFormik} name={"title"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0"> First Name<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input maxLength={50} type="text" className="form-control" name='firstName' value={peresanalInfoFormik.values.firstName}
                                                    onChange={(e) => peresanalInfoFormik.setFieldValue("firstName", e.target.value)} />
                                                <Errors formikfun={peresanalInfoFormik} name={"firstName"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0"> Last Name<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input maxLength={50} type="text" className="form-control" name='lastName' value={peresanalInfoFormik.values.lastName}
                                                    onChange={(e) => peresanalInfoFormik.setFieldValue("lastName", e.target.value)} />
                                                <Errors formikfun={peresanalInfoFormik} name={"lastName"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <SelectBox
                                                    placeholder="Select Gender"
                                                    value={peresanalInfoFormik.values.gender}
                                                    name="gender"
                                                    keys="value"
                                                    label="label"
                                                    menuValeu="value"
                                                    handleChange={(e) => peresanalInfoFormik.setFieldValue("gender", e.target.value)}
                                                    option={GenderOptions_TEAM}
                                                />
                                                <Errors formikfun={peresanalInfoFormik} name={"gender"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Date of Birth
                                                    {/* <span className='Star_Mark'>*</span> */}
                                                </label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input type="date" className="form-control position-relative" name='dob' max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                    value={peresanalInfoFormik.values.dob ? moment(peresanalInfoFormik.values.dob).format("YYYY-MM-DD") : null}
                                                    onChange={handalDOB} />
                                                {/* <Errors formikfun={peresanalInfoFormik} name={"dob"} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Age
                                                    {/* <span className='Star_Mark'>*</span> */}
                                                </label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input type="text" className="form-control" disabled={true} name='age' value={peresanalInfoFormik.values.age}
                                                />
                                                {/* <Errors formikfun={peresanalInfoFormik} name={"age"} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Blood Group
                                                    {/* <span className='Star_Mark'>*</span> */}
                                                </label>
                                            </div>
                                            <div className="col-lg-7">
                                                <SelectBox
                                                    placeholder="Select Blood Group"
                                                    value={peresanalInfoFormik.values.bloodGroup}
                                                    name="bloodgroup"
                                                    keys="value"
                                                    label="label"
                                                    menuValeu="value"
                                                    handleChange={(e) => peresanalInfoFormik.setFieldValue("bloodGroup", e.target.value)}
                                                    option={BloodGroupOptions}
                                                />
                                                {/* <Errors formikfun={peresanalInfoFormik} name={"bloodGroup"} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Language<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4">
                                        <SelectBox
                                            placeholder="Select Language"
                                            value={peresanalInfoFormik.values.language}
                                            name="language"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => peresanalInfoFormik.setFieldValue("language", e.target.value)}
                                            option={LanguageOptions}
                                        />
                                        <Errors formikfun={peresanalInfoFormik} name={"language"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Language<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <SelectBox
                                                    placeholder="Select Language"
                                                    value={peresanalInfoFormik.values.language}
                                                    name="language"
                                                    keys="value"
                                                    label="label"
                                                    menuValeu="value"
                                                    handleChange={(e) => peresanalInfoFormik.setFieldValue("language", e.target.value)}
                                                    option={LanguageOptions}
                                                />
                                                <Errors formikfun={peresanalInfoFormik} name={"language"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>

                            {
                                StaffDetails?.roleId === RoleTitle?.STAFF && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Experience (in year)<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="number" className="form-control"
                                                        name='experience' value={peresanalInfoFormik?.values?.experience}
                                                        onChange={(e) => peresanalInfoFormik.setFieldValue("experience", e.target.value)}
                                                        // onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                        onInput={(e) => {
                                                            let maxLength = e.target.value.includes('.') ? 3 : 2;
                                                            e.target.maxLength = maxLength;
                                                            if (e.target.value.length > maxLength) {
                                                                e.target.value = e.target.value.slice(0, maxLength);
                                                            }
                                                        }}
                                                        max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"experience"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Display Picture</label>
                                    </div>
                                    <div className="col-4 d-flex  align-items-start flex-column">
                                        <Badge
                                            style={{ background: "#fff" }}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar alt="Remy Sharp"  >
                                                    <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                    </MdOutlineCameraAlt>
                                                    <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                        onChange={(e) => UploadImage(e, FileRef, "TEAM", setLoader, setImgUrl)}
                                                    />
                                                </SmallAvatar>
                                            }
                                        >
                                            <Avatar  sx={{ width: 80, height: 80 }} src={TeamImageFilePath + imgUrl} >
                                            </Avatar>
                                        </Badge>
                                        {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "TEAM", id: StaffDetails?.userId }} isDeleted={true} />}
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Display Picture</label>
                                            </div>
                                            <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                                <Badge
                                                    style={{ background: "#fff" }}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <SmallAvatar alt="Remy Sharp"  >
                                                            <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                            </MdOutlineCameraAlt>
                                                            <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                                onChange={(e) => UploadImage(e, FileRef, "TEAM", setLoader, setImgUrl, imgUrl, StaffDetails?.userId)}
                                                            />
                                                        </SmallAvatar>
                                                    }
                                                >
                                                    <Avatar sx={{ width: 80, height: 80 }} src={TeamImageFilePath + imgUrl} >
                                                    </Avatar>
                                                </Badge>
                                                {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "TEAM", id: StaffDetails?.userId }} isDeleted={true} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        <div className=" col-12 col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end py-3" >
                            <CustomButton variant="contained" onClick={peresanalInfoFormik.handleSubmit} >Update</CustomButton>
                        </div>
                    }

                </motion.div>
                }
                {showform === 2 &&
                    <motion.div className=' d-flex justify-content-between  NESTED-CONTAINER-HEIGHT flex-column '
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}>
                        <TableContainer >
                            {alldegreeList?.length === 0 &&
                                <div className="col-sm-12 mt-3 text-center " >
                                    <NoRecordFound />
                                </div>
                            }
                            <Table
                                id="table_mui"
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                {
                                    alldegreeList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={alldegreeList?.length} />
                                }
                                <TableBody className="table_overflow_scroll" >

                                    {stableSort(alldegreeList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${row?.id}`;
                                            return <React.Fragment key={row?.id || index}><TableRow id="hover_tr">
                                                <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                    {row?.degree}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{row?.university}</TableCell>
                                                <TableCell id={labelId} align="left">{row?.yearOfPassing}</TableCell>
                                                <TableCell id={labelId} align="left">{row?.registrationNumber}</TableCell>
                                                <TableCell id={labelId} align="left">
                                                    {
                                                        row?.proof ? <GrDocumentPdf onClick={() => window.open(teamProof + row?.proof)} style={{ fontSize: "25px", color: "red", cursor: "pointer" }} /> : "--"
                                                    }


                                                </TableCell>

                                                <TableCell id={labelId} align="right">
                                                    <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li onClick={() => navigate(`edit-degree/${row?.staffQualificationsId}`)}><button className="dropdown-item cursor" ><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Degree</button></li>
                                                    </ul>
                                                </TableCell>


                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </motion.div>
                }

                {showform === 3 && <motion.div className=' d-flex justify-content-between flex-column  NESTED-CONTAINER-HEIGHT'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Mobile Number fdv<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4 mobile_number_input_box">
                                        <div className="input_group_mobile mb-0">
                                            <PhoneNumber
                                                width={false}
                                                Require={false}
                                                Name="mobile"
                                                HandleChange={contactInfoFormik}
                                                Value={contactInfoFormik.values.mobile}
                                                disabledInput={true}
                                            />
                                        </div>
                                        <Errors formikfun={contactInfoFormik} name={"mobile"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7 mobile_number_input_box">
                                                <div className="input_group_mobile mb-0">
                                                    <PhoneNumber
                                                        width={false}
                                                        Require={false}
                                                        Name="mobile"
                                                        HandleChange={contactInfoFormik}
                                                        Value={contactInfoFormik.values.mobile}
                                                        disabledInput={true}
                                                    />
                                                </div>
                                                <Errors formikfun={contactInfoFormik} name={"mobile"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4 ">
                                        <input disabled type="email" className="form-control" name='email' value={contactInfoFormik.values.email}
                                            onChange={(e) => contactInfoFormik.setFieldValue(`email`, e.target.value)} />
                                        <Errors formikfun={contactInfoFormik} name={"email"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input disabled type="email" className="form-control" name='email' value={contactInfoFormik.values.email}
                                                    onChange={(e) => contactInfoFormik.setFieldValue(`email`, e.target.value)} />
                                                <Errors formikfun={contactInfoFormik} name={"email"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  pb-2 mt-4 " >
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={contactInfoFormik.values.street||""}
                                            onChange={(e) => contactInfoFormik.setFieldValue(`street`, e.target.value)}
                                        />
                                        <Errors formikfun={contactInfoFormik} name={"street"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={contactInfoFormik.values.address}
                                                    onChange={(e) => contactInfoFormik.setFieldValue(`address`, e.target.value)}
                                                />
                                                <Errors formikfun={contactInfoFormik} name={"address"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-2 " >
                                {/* <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-4">
                                        <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={contactInfoFormik.values.address||""}
                                            onChange={(e) => contactInfoFormik.setFieldValue(`address`, e.target.value)}
                                        />
                                        <Errors formikfun={contactInfoFormik} name={"address"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                            </div>
                                            <div className="col-lg-7">
                                                <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={contactInfoFormik.values.street}
                                                    onChange={(e) => contactInfoFormik.setFieldValue(`street`, e.target.value)}
                                                />
                                                <Errors formikfun={contactInfoFormik} name={"street"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-2 " >
                                {/* <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-4">
                                        <SearchAbleSelectBox
                                            placeholder="Select Country"
                                            multiple={false}
                                            option={ListDataCountry}
                                            getOptionLabel="countryName"
                                            isOptionEqualToValue="id"
                                            value={contactInfoFormik?.values?.country}
                                            name="country"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={contactInfoFormik} name={"country"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                            </div>
                                            <div className="col-lg-7">
                                                <SearchAbleSelectBox
                                                    placeholder="Select Country"
                                                    multiple={false}
                                                    option={ListDataCountry}
                                                    getOptionLabel="countryName"
                                                    isOptionEqualToValue="id"
                                                    value={contactInfoFormik?.values?.country}
                                                    name="country"
                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                />
                                                <Errors formikfun={contactInfoFormik} name={"country"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-2 " >
                                {/* <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-4">
                                        <SearchAbleSelectBox
                                            placeholder="Select State"
                                            multiple={false}
                                            option={ListState}
                                            getOptionLabel="stateName"
                                            isOptionEqualToValue="stateId"
                                            value={contactInfoFormik?.values?.state}
                                            name="state"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={contactInfoFormik} name={"state"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                            </div>
                                            <div className="col-lg-7">
                                                <SearchAbleSelectBox
                                                    placeholder="Select State"
                                                    multiple={false}
                                                    option={ListState}
                                                    getOptionLabel="stateName"
                                                    isOptionEqualToValue="stateId"
                                                    value={contactInfoFormik?.values?.state}
                                                    name="state"
                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                />
                                                <Errors formikfun={contactInfoFormik} name={"state"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-2 " >
                                {/* <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            maxLength={50}
                                            className="form-control"
                                            name="city"
                                            placeholder="City"
                                            value={contactInfoFormik.values.city||""}
                                            onChange={(e) => contactInfoFormik.setFieldValue(`city`, e.target.value)}
                                        />
                                        <Errors formikfun={contactInfoFormik} name={"city"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                            </div>
                                            <div className="col-lg-7">
                                                <input
                                                    type="text"
                                                    maxLength={50}
                                                    className="form-control"
                                                    name="city"
                                                    placeholder="City"
                                                    value={contactInfoFormik.values.city}
                                                    onChange={(e) => contactInfoFormik.setFieldValue(`city`, e.target.value)}
                                                />
                                                <Errors formikfun={contactInfoFormik} name={"city"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-2 "  >
                                {/* <div className="row">
                                    <div className="col-3">
                                    </div>
                                    <div className="col-4">
                                        <input type="text" className="form-control" name="zip"
                                            onChange={(e) => contactInfoFormik.setFieldValue(`zip`, e.target.value)} value={contactInfoFormik.values.zip||""}
                                            maxLength={10}
                                            placeholder="ZIP Code"
                                        />
                                        <Errors formikfun={contactInfoFormik} name={"zip"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                            </div>
                                            <div className="col-lg-7">
                                                <input type="text" className="form-control" name="zip"
                                                    onChange={(e) => contactInfoFormik.setFieldValue(`zip`, e.target.value)} value={contactInfoFormik.values.zip}
                                                    maxLength={10}
                                                    placeholder="ZIP Code"
                                                />
                                                <Errors formikfun={contactInfoFormik} name={"zip"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-3 " >
                        <CustomButton variant="contained" onClick={contactInfoFormik.handleSubmit} >update</CustomButton>
                    </div>


                </motion.div>
                }
            </div>
            <Modal size="xl" show={viewPDF} onHide={() => setViewPDF(false)} centered>
                <Modal.Body className="p-0" style={{ height: '600px' }}>
                    <iframe src={teamProof + proofData} title={teamProof + proofData} style={{ width: '100%', height: '100%' }} />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default EditTeameProfile
