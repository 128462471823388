import React, { memo } from 'react'
import { SearchAbleSelectBox, SelectBox } from "../../../Component/Inputs";
import { Errors } from '../../../Component/Error';
import { Avatar, } from "@mui/material";
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import { RoleTitle, VisitTypeOptions } from '../../../Context/constants';
import { Modal } from 'react-bootstrap';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TextBox from '../../../Component/TextBox';
const BookAppointment = ({ patientFilterList, servicesList, teamList, TimeslotList, SlotLodaer, bookAppointmentModal, clossOffcanvas, handalSearchAbleSelectBox, createAppointment, userdata
    , Staff_DETAILS, isActiveSlot, setIsactiveSlot, moment, offersList, applyByCouponCodeOffers, cancelOffer, ApplyOffer, OFERS_DETAILS, SERVICES_DETAILS, check, setCheck }) => {

    const { GreenSwitch, CustomButton } = useMuiCompontent()
    const price = OFERS_DETAILS?.discountPrice ?? SERVICES_DETAILS?.variablePrice ?? SERVICES_DETAILS?.fixedPrice ?? "";
    return (
        <>
            <Modal size='xl' modal="true" show={bookAppointmentModal} onHide={() => clossOffcanvas("BOOK_APPOINTMENT")} centered >
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <p className="modal-title mb-0 page_name">Create Appointment</p>
                        <span className='cursor' onClick={() => clossOffcanvas("BOOK_APPOINTMENT")}> <ClearOutlinedIcon /></span>
                    </div>
                    <div className="row modalform">
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Clinic<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" className="form-control"
                                                maxLength={50}
                                                value={userdata?.location?.clinicName || ""}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SearchAbleSelectBox
                                                disabled={!servicesList.length}
                                                className="w-100"
                                                multiple={false}
                                                option={servicesList}
                                                getOptionLabel="serviceName"
                                                isOptionEqualToValue="serviceId"
                                                value={createAppointment.values.service}
                                                name="service"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                parenthesesData="specializationName"
                                            />
                                            <Errors formikfun={createAppointment} name={"service"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Patients<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SearchAbleSelectBox
                                                extraLable="lastName"
                                                getOptionLabel="firstName"
                                                multiple={true}
                                                option={patientFilterList}
                                                isOptionEqualToValue="id"
                                                value={createAppointment.values.patientName}
                                                name="patient"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                            />
                                            <Errors formikfun={createAppointment} name={"patientName"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        {
                            userdata.roleTitle !== RoleTitle?.STAFF ?
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Doctor<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SearchAbleSelectBox
                                                        disabled={!servicesList?.length || !teamList?.length}
                                                        multiple={false}
                                                        option={teamList}
                                                        extraLable="lastName"
                                                        getOptionLabel="firstName"
                                                        isOptionEqualToValue="userId"
                                                        value={createAppointment.values.teamMember}
                                                        name="team"
                                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    />
                                                    <Errors formikfun={createAppointment} name={"teamMember"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="date" className="form-control position-relative" name="date"
                                                value={createAppointment.values.date ? moment(createAppointment.values.date).format("YYYY-MM-DD") : ""}
                                                onChange={(e) => {
                                                    createAppointment.setFieldValue('date', e.target.value);
                                                    setIsactiveSlot(null)
                                                    createAppointment.setFieldValue("startTime", "");
                                                    createAppointment.setFieldValue("endTime", "")
                                                }} />
                                            <Errors formikfun={createAppointment} name={"date"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12  pt-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-3">Slots Available<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            {/* {createAppointment.values.date && (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? Staff_DETAILS?.userId : userdata.userId) && TimeslotList?.length === 0 && SlotLodaer === false && <p className=" text-start">No Slot Available</p>} */}
                                            { TimeslotList?.length === 0 && SlotLodaer === false && <p className=" text-start mb-0">No Slot Available</p>}
                                            {createAppointment.values.date && (userdata.roleTitle !== RoleTitle.STAFF ? Staff_DETAILS?.userId : userdata.userId) && TimeslotList?.length === 0 && SlotLodaer === true && <div className="w-100 d-flex justify-content-start mb-3">
                                                <div className="spinner-border text-secondary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                            }
                                            <div className="row show_list_time_slot d-flex justify-content-start " style={{ height: TimeslotList?.length > 0 && createAppointment.values.date && "150px" }}>
                                                <div className="col pb-0">
                                                    {
                                                        createAppointment.values.date && TimeslotList?.map((item, index) => {
                                                            return <React.Fragment key={index}>
                                                                <button
                                                                    type="button"
                                                                    disabled={item.status === 'Booked' || item.status === 'Unavailable' ? true : false}
                                                                    style={{ backgroundColor: (isActiveSlot === index ? '#38C4B9' : '#fff'), color: (item.status === 'Booked' || item.status === 'Unavailable' ? 'grey' : (isActiveSlot === index ? '#fff' : '#38C4B9')), width: '160px', fontSize: "14px", height: "47px", padding: "5px", border: (item.status === 'Booked' || item.status === 'Unavailable' ? '1px solid grey' : '1px solid #38C4B9') }}
                                                                    className="btn mb-2 me-2 "
                                                                    onClick={() => {
                                                                        setIsactiveSlot(index);
                                                                        createAppointment.setFieldValue("startTime", item.startTime);
                                                                        createAppointment.setFieldValue("endTime", item.endTime)
                                                                    }}
                                                                >
                                                                    {moment(item.startTime).tz(userdata?.timezoneName).format('LT')} - {moment(item.endTime).tz(userdata?.timezoneName).format('LT')} {(item.status === 'Booked') ? 'Booked' : (item.status === 'Unavailable') ? 'Unavailable' : null}
                                                                </button>
                                                            </React.Fragment>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        {
                            offersList.length > 0 && <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" className="form-control"
                                            maxLength={25}
                                            value={createAppointment?.values?.couponCode}
                                            id="inputAddress" placeholder=""
                                            onChange={(e) => createAppointment?.setFieldValue("couponCode", e.target.value)}
                                        />
                                        <Errors formikfun={createAppointment} name={"couponCode"} />
                                    </div>
                                    <div className="col-sm-4 text-start">
                                        <CustomButton variant="contained" type="button"
                                            onClick={applyByCouponCodeOffers}>Apply</CustomButton>
                                    </div>
                                    <div className="col-sm-12  mb-3">
                                        <div className="row px-3 offers_list_avilability">
                                            {offersList?.map((cur, index) => {
                                                return <React.Fragment key={cur?.offerId}>
                                                    <div className="col-12 offers_Card_avilability mb-2 ">
                                                        <div className="w-100 d-flex" >
                                                            <Avatar
                                                                alt="Offers"
                                                                src={require("../../../Assets/Images/offers2.png")}
                                                                sx={{ width: 30, height: 30 }}
                                                            />
                                                            <p className="name">{cur?.offerName}</p>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-between mt-2">
                                                            <p className="code px-2 ">{cur?.couponCode}</p>
                                                            <p className="type">{`${cur?.offerType === "FLAT" ? `Flat ${cur?.currencySymbol}${cur?.discountAmount} discount` : `${cur.percentage}% discount`}`}</p>
                                                        </div>
                                                        <div className="apply" >
                                                            <div className="d-flex">
                                                                <p className="price" style={{ textDecoration: cur?.discountPrice ? "line-through" : "none" }}>{cur?.currencySymbol}{cur?.fixedPrice}</p>
                                                                <p className="discount">{`${cur?.discountPrice ? cur?.currencySymbol : ""}`}{cur?.discountPrice}</p>
                                                            </div>
                                                            {
                                                                cur?.discountPrice ? <CustomButton variant="contained" type="button" style={{ background: "red", padding: "1px 2px 1px 2px" }}
                                                                    onClick={() => cancelOffer(cur)}>Remove</CustomButton> :
                                                                    <CustomButton variant="contained" type="button" style={{ padding: "1px 2px 1px 2px" }}
                                                                        onClick={() => ApplyOffer(cur)}>Apply</CustomButton>
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Total Price<span className='Star_Mark'></span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            {/* <input type="text" className="form-control"
                                                maxLength={25}
                                                value={OFERS_DETAILS?.discountPrice ?? SERVICES_DETAILS?.variablePrice ?? SERVICES_DETAILS?.fixedPrice ?? ""}
                                                disabled
                                            /> */}

                                            <TextBox textBoxData={<p>{`${SERVICES_DETAILS?.currencyImage ? SERVICES_DETAILS?.currencyImage : ""}${price}`}</p>} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Visit Type<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SelectBox
                                                placeholder="Select Visit Type"
                                                value={createAppointment.values.visitType}
                                                name="visitType"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => createAppointment.setFieldValue("visitType", e.target.value)}
                                                option={VisitTypeOptions}
                                            />
                                            <Errors formikfun={createAppointment} name={"visitType"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Add to waiting list</label>
                                        </div>
                                        <div className="col-lg-7">
                                            <GreenSwitch defaultChecked={check} onClick={() => setCheck(!check)} id="flexSwitchCheckDefault" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pt-3 " >
                            <CustomButton variant="contained" type="button" onClick={() => {
                                createAppointment.handleSubmit();
                            }} >Book Appointment</CustomButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default memo(BookAppointment)
