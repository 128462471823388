import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import useReduxData from '../../../Hooks/useReduxData';
import { RoleTitle } from '../../../Context/constants';
import { CHANGE_ROLE_STATUS, DELETE_ROLE, EDIT_ROLE, INSERT_ROLE, ROLE_LIST } from '../../Repository/SettingRepo';
import { toast } from 'react-toastify';
import { addRoleValidation } from '../../../Context/Validation';

const useRoles = () => {
    const [loader, setLoader] = useState(false)
    const [roleList, setRoleList] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Filter, setFilter] = useState({
        archive: true,
        disable_button: true,
        search: ""
    })
    const [openModal, setOpenModal] = useState({
        addRole: false,
        archiveRole: false,
        deleteRole: false,
        data: null
    })
    const { reduxData: userdata, } = useReduxData("userDataRDX")
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Role Name", isShorted: true },
        // { id: "status", numeric: false, disablePadding: false, showLeft: true, label: "Status", isShorted: false },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];
    async function getRoleList() {
        setLoader(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { search, archive } = Filter;
        const keyword = search.trim();
        const payload = {
            searchKeyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            activeStatus: archive,
            deletedStatus: false
        };
        try {
            let response = await ROLE_LIST(payload)
            setRoleList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setRoleList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: addRoleValidation,
        onSubmit: async (values, { resetForm }) => {
            setLoader(true)
            let payload = {
                name: values?.name,
                dynamicRoleId: openModal?.data ? openModal?.data?.dynamicRoleId : undefined,
                providerId: openModal?.data ? undefined : (userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId
            }
            try {
                let response = openModal?.data ? await EDIT_ROLE(payload) : await INSERT_ROLE(payload)
                handleClosedModal("addRole")
                resetForm()
                toast.success(response.message)
                getRoleList()
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoader(false)
            }
        }
    })
    const archiveUnarchive = async (event) => {
        if (event === "YES") {
            let payload = {
                dynamicRoleId: openModal?.data?.dynamicRoleId,
                activeStatus: !openModal?.data?.activeStatus
            }
            setLoader(true)
            try {
                let response = await CHANGE_ROLE_STATUS(payload)
                toast.success(response.message)
                if (roleList?.length === 1 && controller.page === 1) getRoleList()
                else if (roleList?.length === 1) setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                else getRoleList()

                handleClosedModal("archiveRole")
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        } else {
            handleClosedModal("archiveRole")
        }
    }
    const handleDeleteRole = async (event) => {
        if (event === "YES") {
            let payload = {
                dynamicRoleId: openModal?.data?.dynamicRoleId,
                deletedStatus: !openModal?.data?.deletedStatus
            }
            setLoader(true)
            try {
                let response = await DELETE_ROLE(payload)
                toast.success(response.message)
                if (roleList?.length === 1 && controller.page === 1) getRoleList()
                else if (roleList?.length === 1) setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                else getRoleList()

                handleClosedModal("deleteRole")
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        } else {
            handleClosedModal("deleteRole")
        }
    }
    const handleOpenModal = (type, data = null) => {
        if (type === "addRole" && data) {
            formik.setFieldValue("name", data?.dynamicRoleName)
        }
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, search: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    useEffect(() => {
        getRoleList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    return {
        handleDeleteRole,
        handleClosedModal,
        handleOpenModal,
        handleChangeFilter,
        setController,
        HandleSearchList,
        archiveUnarchive,
        reset,
        openModal,
        formik,
        roleList,
        Filter,
        loader,
        controller,
        paginationTotalCount,
        headCells,
    }
}

export default useRoles
