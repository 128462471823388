
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { IconButton, Input, InputAdornment, TextField } from "@mui/material";
import { useFormik } from 'formik';
import { Errors } from '../../Component/Error';
import { createpinValidation, pattern } from '../../Context/Validation';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useReduxData from '../../Hooks/useReduxData';
import { addUserData } from '../../Redux/ReducerFile/UserData';
import { RoleTitle, } from '../../Context/constants';
import Loading from '../../Component/Loading';
import { Button } from '@mui/material';
import { CREATE_PIN, VALIDATE_AUTH_TOKEN } from '../Repository/CredentialRepo';
import { motion } from 'framer-motion';
import { IoMdUnlock } from 'react-icons/io';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MdPhone } from 'react-icons/md';
// import PhoneNumber from '../../Component/PhoneInput';
import { UPDATE_STAFF_MOBILE_NUMBER } from '../Repository/StaffRepo';
const CreatePin = () => {
    const [userdata, setUserdata] = useState({})
    const [loader, setLoader] = useState(false)
    const [active, setActive] = useState(null)
    const [showPassword, setShowPassword] = useState({ newPin: false, confirmPin: false })
    const { token } = useParams()
    const navigate = useNavigate()
    const { dispatch } = useReduxData("userDataRDX")
    const handleClickShowPassword = (type) => {
        setShowPassword((pre) => ({
            ...pre, [type]: !pre[type]
        }))
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        CheckToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const backToLogin = () => {
        dispatch(addUserData({}))
        navigate("/login")
    }
    const CheckToken = async () => {
        let payload = { token: token }
        try {
            let response = await VALIDATE_AUTH_TOKEN(payload)
            if (response?.data?.conditionStatus === 1002 || response?.data?.conditionStatus === 1006) { //accepted invitation
                setActive(3)
            }
            if (response?.data?.conditionStatus === 1003) { //mobile number
                setActive(1)
            }
            if (response?.data?.conditionStatus === 1004) {//mobile number and create pin 
                setActive(1)
            }
            if (response?.data?.conditionStatus === 1005 || response?.data?.conditionStatus === 1007) { //creatre pin
                setActive(2)
            }

            setUserdata(response.data)
        } catch (error) {
            toast.error(error?.message)
            setLoader(false)
            navigate("declineInvitation")
        }
    }
    const PinCreate = useFormik({
        initialValues: {
            newpin: '',
            confirmpin: '',
        },
        validationSchema: createpinValidation,
        onSubmit: async (values) => {
            setLoader(true)
            let payload = {
                pin: values.newpin,
                confirmPin: values.confirmpin,
                userId: userdata?.userId,
                authId: userdata?.authId,
                roleTitle: RoleTitle?.SERVICE_PROVIDER,
                filter: "INVITATION"
            }
            try {
                let response = await CREATE_PIN(payload)
                toast.success(response?.message);
                setLoader(false)
                // setTimeout(() => {
                //     backToLogin()
                // }, 1000)
                setActive(4)
                PinCreate.resetForm()
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    const ragistorNumber = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: Yup.object({ phone: Yup.string().trim().required("Please enter mobile number ") }),
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if (values?.phone?.length > 0 && (values?.phone.match(pattern?.PHONE_NO))) {
                delete errors.phone
            } else {
                errors.phone = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: async (values, { resetForm }) => {
            setLoader(true)
            let payload = {
                mobile: values?.phone,
                userId: userdata?.userId,
                authId: userdata?.authId,
            }
            try {
                let response = await UPDATE_STAFF_MOBILE_NUMBER(payload)
                toast.success(response?.message);
                setLoader(false)
                resetForm()
                if (userdata?.conditionStatus === 1003) {
                    // backToLogin()
                    setActive(5)
                } else {
                    setActive(2)
                }
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    if (!active) return
    return (
        <>
            {loader && <Loading />}
            <ToastContainer />
            <div className="LOGIN_CONTAINER">
                <div className="container">
                    <div className="row LOGIN_SECTION shadow">
                        <div className="col-lg-6 col-12 INFO_SECTION d-none d-lg-block ">
                        </div>
                        {
                            active === 1 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                exit={{ x: -300 }}
                                transition={{ duration: .5 }}
                            >
                                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="" className="img-fluid" width={80} height={80} />
                                <h3>Mobile Number</h3>
                                {/* <p style={{ width: "80%" }} className="text-center">Set the new PIN for the account so that you
                                    can easily login and use features.</p> */}
                                <form action="" className="FORM" >
                                    <div className="col-sm-12 mb-3">
                                        <label className="form-label mb-2 ms-4"> Mobile Number<span className='Star_Mark'>*</span></label>
                                        <div className="d-flex align-items-center">
                                            <MdPhone className="me-1 ICON" />
                                            {/* <PhoneNumber
                                                width={100}
                                                Require={false}
                                                Name="phone"
                                                HandleChange={ragistorNumber}
                                                Value={ragistorNumber?.values?.phone}
                                                countrySelectComponent="null"
                                            /> */}
                                            <TextField
                                                variant="standard"
                                                onChange={(e) => { ragistorNumber.setFieldValue("phone", e.target.value) }}
                                                name="phone"
                                                value={ragistorNumber?.values?.phone}
                                                type="number"
                                                style={{ width: "100%" }}
                                                color='primary'
                                                onInput={(e) => {
                                                    let maxValue = 10
                                                    e.target.maxLength = maxValue;
                                                    if (e.target.value.length > maxValue) {
                                                        e.target.value = e.target.value.slice(0, maxValue);
                                                    }
                                                    if (parseFloat(e.target.value) < 0) {
                                                        e.target.value = 0;
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="ms-4" style={{ height: "5px" }}>
                                            <Errors formikfun={ragistorNumber} name={`phone`} />
                                        </div>
                                    </div>
                                    <Button type="button" onClick={ragistorNumber.handleSubmit} variant="contained" className="w-100 fs-6 mt-5" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" }}> {userdata?.conditionStatus === 1003 ? "Save" : "Next"} </Button>
                                </form>
                            </motion.div>
                        }
                        {
                            active === 2 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                exit={{ x: -300 }}
                                transition={{ duration: .5 }}
                            >
                                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="" className="img-fluid" width={80} height={80} />
                                <h3>Create PIN</h3>
                                <p style={{ width: "80%" }} className="text-center">Set the new PIN for the account so that you
                                    can easily login and use features.</p>
                                <form action="" className="FORM" >
                                    <div className="col-sm-12 mb-2">
                                        <label className="form-label mb-0 ms-4">New PIN<span className='Star_Mark'>*</span></label>
                                        <div className="d-flex align-items-center">
                                            <IoMdUnlock className="me-1 ICON" />
                                            <Input
                                                onChange={(e) => PinCreate.setFieldValue("newpin", e.target.value)}
                                                value={PinCreate.values.newpin}
                                                name="password"
                                                style={{ width: "100%" }}
                                                id="standard-adornment-password"
                                                type={showPassword.newPin ? 'number' : 'password'}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword("newPin")}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword.newPin ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />

                                        </div >
                                        <div className="ms-4" style={{ height: "5px" }}>
                                            <Errors formikfun={PinCreate} name={"newpin"} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <label className="form-label mb-0 ms-4">Confirm PIN<span className='Star_Mark'>*</span></label>
                                        <div className="d-flex align-items-center">
                                            <IoMdUnlock className="me-1 ICON" />
                                            <Input
                                                onChange={(e) => PinCreate.setFieldValue("confirmpin", e.target.value)}
                                                value={PinCreate.values.confirmpin}
                                                name="password"
                                                style={{ width: "100%" }}
                                                id="standard-adornment-password"
                                                type={showPassword.confirmPin ? 'number' : 'password'}
                                                onInput={(e) => {
                                                    if (e.target.value.length > 4) {
                                                        e.target.value = e.target.value.slice(0, 4);
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword("confirmPin")}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword.confirmPin ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />

                                        </div >
                                        <div className="ms-4" style={{ height: "5px" }}>
                                            <Errors formikfun={PinCreate} name={"confirmpin"} />
                                        </div>
                                    </div>
                                    <Button type="button" onClick={PinCreate.handleSubmit} variant="contained" className="w-100 fs-6 mt-5" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" }}> Continue</Button>
                                </form>
                            </motion.div>
                        }
                        {
                            active === 3 && <motion.div className="col-lg-6 col-12 justify-content-center FORM_SECTION"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                exit={{ x: -300 }}
                                transition={{ duration: .5 }}
                            >
                                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="" className="img-fluid" width={80} height={80} />
                                <h3>Accepted</h3>
                                <p style={{ width: "80%" }} className="text-center">Your have  successfully accepted the invitation </p>
                                <form action="" className="FORM" >
                                    <div className="SUCCESSFULLY_ICON d-flex justify-content-center w-100">
                                        {/* <img src={require("../../Assets/Images/8832119.png")} alt="" width={80} height={80} className="img-fluid" /> */}
                                        <svg className="animated-svg my-5" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                            <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                        </svg>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Button type="button" onClick={backToLogin} variant="contained" className="w-75 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px", textTransform: "capitalize" }}>Back to Login</Button>
                                    </div>
                                </form>
                            </motion.div>
                        }

                        {
                            active === 4 && <motion.div className="col-lg-6 col-12 justify-content-center FORM_SECTION"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                exit={{ x: -300 }}
                                transition={{ duration: .5 }}
                            >
                                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="" className="img-fluid" width={80} height={80} />
                                <h3>Created PIN</h3>
                                <p style={{ width: "80%" }} className="text-center">Your PIN have been created  successfully  </p>
                                <form action="" className="FORM" >
                                    <div className="SUCCESSFULLY_ICON d-flex justify-content-center w-100">
                                        {/* <img src={require("../../Assets/Images/8832119.png")} alt="" width={80} height={80} className="img-fluid" /> */}
                                        <svg className="animated-svg my-5" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                            <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                        </svg>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Button type="button" onClick={backToLogin} variant="contained" className="w-75 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px", textTransform: "capitalize" }}>Back to Login</Button>
                                    </div>
                                </form>
                            </motion.div>
                        }
                        {
                            active === 5 && <motion.div className="col-lg-6 col-12 justify-content-center FORM_SECTION"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                exit={{ x: -300 }}
                                transition={{ duration: .5 }}
                            >
                                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="" className="img-fluid" width={80} height={80} />
                                <h3>Invitation Accepted</h3>
                                <p style={{ width: "80%" }} className="text-center">Kindly  proceed to login   </p>
                                <form action="" className="FORM" >
                                    <div className="SUCCESSFULLY_ICON d-flex justify-content-center w-100">
                                        {/* <img src={require("../../Assets/Images/8832119.png")} alt="" width={80} height={80} className="img-fluid" /> */}
                                        <svg className="animated-svg my-5" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                            <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                        </svg>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Button type="button" onClick={backToLogin} variant="contained" className="w-75 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px", textTransform: "capitalize" }}>Back to Login</Button>
                                    </div>
                                </form>
                            </motion.div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatePin