import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { useFormik } from "formik";
import { pattern } from "../../../Context/Validation";
import { GET_BASIC_DETAILS } from "../../Repository/ProfileRepo";
import { RESEND_INVITATION_EMAIL, UPDATE_STAFF_STATUS } from "../../Repository/StaffRepo";
import { RoleTitle } from "../../../Context/constants";
import { ROLE_LIST_STAFF } from "../../Repository/SettingRepo";

const useTeamsList = () => {
  const [listTeams, setListTeams] = useState([])
  const [loader, setLoader] = useState(true)
  const [roleList, setRoleList] = useState([])
  const [resendInvitation, setResendInvitation] = useState(false)
  const navigate = useNavigate()
  const [showArchiv, setShowArchiv] = useState(false);
  const [archivedata, setArchiveData] = useState({})
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
  const [paginationTotalCount, setPaginationTotalCount] = useState(null)
  const [changeEmail, setChangeEmail] = useState(false)
  const [Filter, setFilter] = useState({
    archive: true,
    team: "",
    role: "ALL",
    disable_button: true
  })
  const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
  useEffect(() => {
    getliststaff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])

  useEffect(() => {
    getRoleList()
    document.title = "Team"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const headCells = [
    { id: "image", numeric: false, disablePadding: true, label: "Image" },
    { id: "firstName", numeric: true, disablePadding: false, label: "Name", showIcon: true },
    { id: "gender", numeric: true, disablePadding: false, label: "Gender", showIcon: true },
    { id: "mobile", numeric: true, disablePadding: false, label: "Mobile Number", showIcon: true },
    { id: "email", numeric: true, disablePadding: false, label: "Email", showIcon: true },
    { id: "invitationStatus", numeric: false, disablePadding: false, label: "Invitation Status", showIcon: true },
    { id: "Assign Login", numeric: false, disablePadding: false, label: "Assign Login" },
    { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action" }
  ];


  // archive/unarchive Api            
  const archiveUnarchive = async (event, data) => {
    if (event === "YES") {
      let payload = {
        activeStatus: !data.activeStatus,
        staffId: data.userId,
      }
      setLoader(true)
      try {
        let response = await UPDATE_STAFF_STATUS(payload)
        toast.success(response.message)
        if (listTeams?.length === 1 && controller.page === 1) {
          getliststaff()
        }
        else if (listTeams?.length === 1) {
          setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
        } else getliststaff()
        setLoader(false)
        setShowArchiv(false)
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }
    } else {
      setShowArchiv(false)
    }
  }
  // handle allow login 
  const handleAllowLogin = async (event, data) => {
    const { checked } = event.target
    let payload = {
      allowLogin: checked,
      staffId: data.userId,
    }
    try {
      let response = await UPDATE_STAFF_STATUS(payload)
      toast.success(response.message)
      getliststaff()
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }
  // get list staff
  const getliststaff = async () => {
    setLoader(true)
    const { page, rowsPerPage, searchPage } = controller;
    const { archive, team, role } = Filter;
    const keyword = team.trim();
    const payload = {
      getStaffList: true,
      keyword: (searchPage && keyword) ? keyword : undefined,
      activeStatus: archive,
      providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      page,
      count: rowsPerPage,
      filter: role,
    };
    try {
      let response = await GET_BASIC_DETAILS(payload)
      setListTeams(response.data)
      setPaginationTotalCount(response?.totalCount)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setListTeams([])
      setPaginationTotalCount(0)
    }
  }
  const getRoleList = async () => {
    setLoader(true)
    const payload = {
      providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
    }
    try {
      let response = await ROLE_LIST_STAFF(payload)
      setRoleList([{ value: "ALL", label: "All" }, ...response?.data?.map((cur) => ({ value: cur?.dynamicRoleId ? cur?.dynamicRoleId : cur?.roleTitle, label: cur?.dynamicRoleName }))])
      setLoader(false)
    } catch (error) {
      setRoleList([])
    }
  }
  const resendInvitationFun = useFormik({
    initialValues: {
      oldEmail: "",
      newEmail: "",
      firstName: "",
      staffId: "",
      mobile: ""
    },
    validateOnChange: true,
    validate: (values) => {
      const errors = {};
      if (changeEmail) {
        if (!values?.newEmail) {
          errors.newEmail = "Please enter new email address ";
        } else if (!values?.newEmail?.match(pattern?.EMAIL)) {
          errors.newEmail = "Please enter correct email ";
        }
        else {
          delete errors.newEmail
        }
      }
      return errors;
    },
    onSubmit: async (value, { resetForm }) => {
      let { oldEmail, newEmail, mobile, ...restValue } = value
      let payload = { ...restValue, roleTitle: userdata?.roleTitle, newEmail: changeEmail ? newEmail : oldEmail }
      setLoader(true)
      try {
        let response = await RESEND_INVITATION_EMAIL(payload)
        toast.success(response.message);
        getliststaff()
        setResendInvitation(false)
        resetForm()
        setChangeEmail(false)
        setLoader(false)
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }
    }
  })

  const handleChangeFilter = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
  }

  const reset = () => {
    setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    setFilter({ archive: true, team: "", role: "ALL", disable_button: true })
  }
  const HandleSearchList = () => {
    setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
  }
  return {
    roleList,
    headCells,
    loader,
    listTeams,
    paginationTotalCount,
    controller,
    showArchiv,
    archivedata,
    Filter,
    resendInvitation,
    resendInvitationFun,
    changeEmail,
    setChangeEmail,
    handleAllowLogin,
    setController,
    setShowArchiv,
    navigate,
    archiveUnarchive,
    dispatch,
    handleChangeFilter,
    HandleSearchList,
    setArchiveData,
    reset,
    setResendInvitation,
  }
}

export default useTeamsList
