import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useReduxData from "../../../Hooks/useReduxData";
import { ADD_CATEGORY, CATEGORY_LIST, CATEGORY_TOGGLE_STATUS, DELETE_CATEGORY, UPDATE_CATEGORY } from "../../Repository/TemplateLibraryRepo";
import { toast } from "react-toastify";
import { RoleTitle } from "../../../Context/constants";
import { useFormik } from "formik";
import { CreateCategoryValidation } from "../../../Context/Validation";

const useCategory = () => {
  const [loader, setLoader] = useState(false)
  const [TotalCount, setTotalCount] = useState(0)
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
  const [categoryList, setCategoryList] = useState([])
  const [Filter, setFilter] = useState({
    archive: "ALL",
    category: "",
    disable_button: true
  })
  const [openModal, setOpenModal] = useState({
    insertCategory: false,
    deleteCategory: false,
    data: null
  })
  const navigate = useNavigate()
  const { reduxData: userdata } = useReduxData("userDataRDX")

  const headCells = [
    { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
    { id: "name", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
    { id: "status", numeric: false, disablePadding: false, showLeft: true, label: "Status", isShorted: false },
    { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
  ];


  //  category list
  async function getCategoryList() {
    setLoader(true)
    const { page, rowsPerPage, searchPage } = controller;
    const { archive, category } = Filter;
    const keyword = category.trim();
    const payload = {
      searchKeyword: (searchPage && keyword) ? keyword : undefined,
      page: page,
      count: rowsPerPage,
      deletedStatus: false,
      activeStatus: archive === "ALL" ? undefined : archive,
      providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
    };
    try {
      let response = await CATEGORY_LIST(payload)
      setCategoryList(response?.data)
      setTotalCount(response?.count)
      setLoader(false)
    } catch (error) {
      if (error.message !== "No details found") toast.error(error.message)
      setCategoryList([])
      setTotalCount(0)
      setLoader(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      category: ""
    },
    validationSchema: CreateCategoryValidation,
    onSubmit: async (values) => {
      setLoader(true)
      let payload = {
        name: values?.category,
        providerId: openModal?.data ? undefined : (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
        frontdeskId: openModal?.data ? undefined : userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        formCategoryId: openModal?.data ? openModal?.data?.formCategoryId : undefined,
        roleTitle: openModal?.data ? undefined : userdata.roleTitle
      }
      try {
        let response = openModal?.data ? await UPDATE_CATEGORY(payload) : await ADD_CATEGORY(payload)
        toast.success(response.message)
        setLoader(false)
        getCategoryList()
        handleClosedModal("insertCategory")
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }
    }
  })
  const handleOpenModal = (type, data = null) => {
    if (type === "insertCategory" && data) formik.setFieldValue("category", data?.name)
    setOpenModal((prev) => ({
      ...prev,
      data: data,
      [type]: true,
    }));
  };
  const handleClosedModal = (type) => {
    if (type === "insertCategory") formik.resetForm()
    setOpenModal((pre) => ({
      ...pre,
      data: null,
      [type]: false
    }))
  }

  const handleDeleteCategory = async (event) => {
    if (event === "YES") {
      setLoader(true)
      const payload = {
        formCategoryId: openModal.data?.formCategoryId,
        deletedStatus: !openModal.data?.deletedStatus
      }
      setLoader(true)
      try {
        let response = await DELETE_CATEGORY(payload)
        toast.success(response.message)
        if (categoryList?.length === 1 && controller.page === 1) {
          getCategoryList()
        }
        else if (categoryList?.length === 1) {
          setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
        } else {
          getCategoryList()
        }
        setLoader(false)
        handleClosedModal("deleteCategory")
      } catch (error) {
        toast.error(error.message);
        setLoader(false)
      }

    } else {
      handleClosedModal("deleteCategory")
    }
  }

  const handleToggleStatus = async (event, data) => {
    const { checked } = event.target
    let payload = {
      formCategoryId: data?.formCategoryId,
      activeStatus: checked
    }
    try {
      let response = await CATEGORY_TOGGLE_STATUS(payload)
      toast.success(response.message)
      getCategoryList()
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }

  const handleChangeFilter = (event) => {
    const { name, value } = event.target
    setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
  }
  const HandleSearchList = () => {
    setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
  }
  const reset = () => {
    setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    setFilter({ archive: "ALL", category: "", disable_button: true })
  }

  useEffect(() => {
    getCategoryList()
    navigate({ replace: true, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller])
  return {
    handleChangeFilter,
    HandleSearchList,
    handleOpenModal,
    handleClosedModal,
    setController,
    reset,
    handleDeleteCategory,
    handleToggleStatus,
    headCells,
    controller,
    Filter,
    TotalCount,
    categoryList,
    loader,
    openModal,
    formik,
  }
}

export default useCategory
