import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { featurePermission } from "../Utils/Utils";
import useReduxData from "../Hooks/useReduxData";
import { RoleTitle } from "../Context/constants";
import Appointments from "../Pages/Appointments/Appointments"
import Availability from "../Pages/Availability/Availability"
import Insights from "../Pages/Insights/Insights"
import Notifications from "../Pages/Notifications/Notifications"
import Offers from "../Pages/Offers/Offers"
import Patients from "../Pages/Patients/Patients"
import Services from "../Pages/Expertise/Services"
import Teams from "../Pages/Teams/Teams"
import WaitingList from "../Pages/WaitingList/WaitingList"
import ChangePin from "../Pages/Settings/ChangePin"
import FAQ from "../Pages/Settings/FAQ"
import HelpSupport from "../Pages/Settings/HelpSupport"
import Policies from "../Pages/Settings/Policies"
import SetAvailbility from "../Pages/Availability/SetAvailbility"
import UpDateAvailbility from "../Pages/Availability/UpdateAvailbility"
import Notification from "../Pages/Settings/Notification"
import SYF from "../Pages/Settings/SYF"
// profile
import Profile from "../Pages/Profile/Profile"
import EditClinic from "../Pages/Profile/EditClinic"
import AddTeams from "../Pages/Teams/AddTeams"
import TeamDtaeils from "../Pages/Teams/TeamDtaeils"
import EditTeameProfile from "../Pages/Teams/EditTeameProfile"
import TeamsAvailability from "../Pages/Teams/TeamsAvailability"
import TeamsUnAvailability from "../Pages/Teams/TeamsUnAvailability"
import TeamsAddAvailability from "../Pages/Teams/TeamsAddAvailability"
import TeamsAvailabilityUpdate from "../Pages/Teams/TeamsAvailabilityUpdate"
import AddPatients from "../Pages/Patients/AddPatients"
import AddPet from "../Pages/Patients/AddPet"
import History from "../Pages/Patients/History"
import SecviceSEction from "../Pages/Expertise/Expertise"
import Specialization from "../Pages/Expertise/Specialization"
import AddOffers from "../Pages/Offers/AddOffers"
import EditOffers from "../Pages/Offers/EditOffers"
import AllDetails from "../Pages/Credential/UserDetails/AllDetails"
import BasicDetails from "../Pages/Credential/UserDetails/BasicDetails"
import RejectPage from "../Pages/RejectInvitation/rejectPage"
import Private from "../Layout/Private"
import CreatePassword from "../Pages/AcceptInvitation/createPin"
import Public from "../Layout/Public"
import PrivateErrorPage from "../Pages/ErrorPgae.jsx/PrivateErrorPage"
import TeamLocation from "../Pages/Teams/TeamLocation"
import TeamSpecialization from "../Pages/Teams/TeamSpecialization"
import UpdatePet from "../Pages/Patients/UpdatePet"
import VaccinationCalendar from "../Pages/VaccinationLibrary/VaccinationCalendar"
import TodayAgenda from "../Pages/Appointments/TodayAgenda"
import AppointmentDetails from "../Pages/Appointments/AppointmentDetails"
import VaccineCalender from "../Pages/Patients/VaccineCalender"
import VaccineList from "../Pages/Patients/VaccineFormUpdate"
import EprescriptionHistory from "../Pages/Patients/E-prescriptionHistory"
import TestReport from "../Pages/Patients/TestReport"
import UpdatePatient from "../Pages/Patients/UpdatePatient"
import EPrescription from "../Pages/Patients/E-prescription"
import BookingDetails from "../Pages/Insights/BookingDetails"
import PatientReport from "../Pages/Insights/PatientReport"
import TeamReport from "../Pages/Insights/TeamReport"
import ServiceReports from "../Pages/Insights/ServiceReports"
import RushHours from "../Pages/Insights/RushHours"
import Demographics from "../Pages/Insights/Demographics"
import Feedback from "../Pages/Insights/Feedback"
import AddSpecialization from "../Pages/Profile/AddSpecialization"
import LifeStyle from "../Pages/Settings/LifeStyle"
import AdditionalDetails from "../Pages/Patients/AdditionalDetails"
import Forgot from "../Pages/Credential/Forgot"
import Login from "../Pages/Credential/Login"
import SignUp from "../Pages/Credential/SignUp"
import BusinessDetails from "../Pages/Credential/UserDetails/BusinessDetails"
import AddClinic from "../Pages/Profile/AddClinic"
import VaccinationForms from "../Pages/VaccinationLibrary/VaccinationForms"
import CheckOut from "../Pages/Appointments/CheckOut"
import AppointmentCalendar from "../Pages/Appointments/AppointmentCalendar"
import TicketList from "../Pages/Settings/Ticket/TicketList"
import TicketDetails from "../Pages/Settings/Ticket/TicketDetails"
import Dashboard from "../Pages/dashboard/Dashboard"
import Mail from "../Layout/Mail"
// const Dashboard =React.lazy(()=>import("../Pages/dashboard/Dashboard"))

import EditProfile from "../Pages/Profile/EditProfile"
import Overview from "../Pages/Appointments/Overview"
import SetUnavailability from "../Pages/Availability/SetUnavailability"
import Submember from "../Pages/Patients/Submember"
import SubPatientDetails from "../Pages/Patients/SubPatientDetails"
import OfferView from "../Pages/Offers/OfferView"
import Index from "../Pages/Settings/Index"
import AddTicket from "../Pages/Settings/Ticket/CreateTicket"
import Other from "../Pages/Settings/Other"
import AddDegree from "../Pages/Teams/AddDegree"
import TeamSetUnavailability from "../Pages/Teams/TeamSetUnavailability"
import AddServices from "../Pages/Expertise/AddServices"
import EditServices from "../Pages/Expertise/EditServices"
import VaccineFormUpdate from "../Pages/Patients/VaccineFormUpdate"
import UnAvailabilityAppointment from "../Pages/Appointments/Un-Availability"
import VaccinationCategory from "../Pages/VaccinationLibrary/VaccinationCategory"
import VaccinationCalendarOverview from "../Pages/Appointments/VaccinationCalendarOverview"
import UpdateCalendarOverview from "../Pages/Appointments/UpdateCalendarOverview"
import UpdateVaccinationForm from "../Pages/VaccinationLibrary/UpdateVaccinationForm"
import TeamProfile from "../Pages/Profile/TeamProfile/TeamProfile"

import CheckoutAppointment from "../Pages/Patients/CheckoutAppointment"
import CheckoutSlip from "../Pages/Patients/CheckoutSlip"
import PatientDetails from "../Pages/Patients/PatientDetails"
import PaymentSuccessfull from "../Pages/AcceptInvitation/PaymentSuccessfull"
import MyPlan from "../Pages/Settings/MyPlan/MyPlan"
import BillingHistory from "../Pages/Settings/MyPlan/BillingHistory"
import PlanHistory from "../Pages/Settings/MyPlan/PlanHistory"
import PaymentHistory from "../Pages/Settings/MyPlan/paymentHistory"
import Chat from "../Pages/Chat"
import PaymentSetting from "../Pages/Settings/PaymentSettting"


import TemplateLibrary from "../Pages/TemplateLibrary";
import TemlpateList from "../Pages/TemplateLibrary/TemlpateList";
import TemplateCategory from "../Pages/TemplateLibrary/Category";
import CreateTemplateForm from "../Pages/TemplateLibrary/CreateTemplateForm";
import PatientSharedForm from "../Pages/Patients/PatientSharedForm";
import Roles from "../Pages/Settings/Roles";


const AllRoutes = (props) => {
  const { reduxData: userdata } = useReduxData("userDataRDX")
  const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
  const isStaffPermission = userdata?.roleTitle === RoleTitle?.STAFF
  const isProviderPermission = userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setAuthenticated(userdata?.userId ? true : false);
    });
  }, [userdata?.userId]);

  if (loading) {
    return <h1 style={{ opacity: 0 }} >Loading...</h1>
  }

  return (
    <>

      <Routes>
        {/* email template routes */}
        <Route path="/mail" element={<Mail />}  >
          <Route path="acceptInvitation/:token" element={<CreatePassword />} />
          <Route path="acceptInvitation/payment/:token" element={<PaymentSuccessfull />} />
          <Route path="declineInvitation/:token" element={<RejectPage />} />
          <Route path="acceptInvitation/payment/:token/declineInvitation" element={<RejectPage />} />
          <Route path="acceptInvitation/:token/declineInvitation" element={<RejectPage />} />
        </Route>
        {/* Credentials routes */}
        {
          !authenticated ? <Route path="/" element={< Public />}>
            <Route path='/' element={<Navigate replace to={"/login"} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-pin" element={<Forgot />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/alldetails" element={<AllDetails />}>
              <Route path="/alldetails" element={<Navigate replace to="/alldetails/basic-details" />} />
              <Route path="/alldetails/basic-details" element={<BasicDetails />} />
              <Route path="/alldetails/business-details" element={<BusinessDetails />} />
              <Route path="/alldetails/business-details/addClinic" element={<AddClinic type={'LOGIN'} />} />
              <Route path="/alldetails/business-details/addClinic/addSpecialization" element={<AddSpecialization type={'LOGIN'} />} />
            </Route>
          </Route> :
            <Route path="/" element={<Private />} >
              {/* Dashboard routes */}
              <Route path='/' element={<Navigate replace to={"/dashboard"} />} />
              <Route path="/dashboard" element={<Dashboard />} />
              {/* Appointment routes */}
              <Route path="/appointment/list" element={<Appointments />} />
              <Route path="/appointment/details/:apptId" element={<AppointmentDetails />} />
              <Route path="/appointment/calendar" element={<AppointmentCalendar />} />
              <Route path="/appointment/calendar/set-unavailability"
                element={
                  <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                    <UnAvailabilityAppointment type={"CALENDER"} />
                  </ProtectedRoute>
                }
              />
              <Route path="/appointment/today-agenda" element={<TodayAgenda />} />
              <Route path="/appointment/today-agenda/overview" element={<Overview />} />
              <Route path="/appointment/today-agenda/overview/:vaccinationCategoryId" element={<VaccinationCalendarOverview />} />
              <Route path="/appointment/today-agenda/overview/:vaccinationCategoryId/:vaccinationFormId" element={<UpdateCalendarOverview />} />
              <Route path="/appointment/today-agenda/:id" element={<CheckOut type={"CALENDER"} />} />
              {/* Availability routes */}
              <Route path="/availability" element={<Availability />} />
              <Route path="/availability/set-availability"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <SetAvailbility />
                  </ProtectedRoute>
                }
              />
              <Route path="/availability/set-unavailability"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <SetUnavailability />
                  </ProtectedRoute>
                }
              />
              <Route path="/availability/:id"
                element={<ProtectedRoute RolePermission={true} FeaturePermission={null} >
                  <UpDateAvailbility />
                </ProtectedRoute>}

              />
              {/* Waiting List routes */}
              <Route path="/waiting" element={<WaitingList />} />

              {/* Patients  routes */}
              <Route path="/patients" element={<Patients />} />
              <Route path="/patients/add" element={<AddPatients />} />
              <Route path="/patients/:id/sub-members/add-patient" element={<AddPatients />} />
              <Route path="/patients/:id/sub-members/add-pet" element={<AddPet />} />
              <Route path="/patients/:id" element={<PatientDetails type={"Patients"} />} >
                <Route path="/patients/:id" element={<Navigate replace to="details" />} />
                <Route index path="details" element={patientdetails.type === "PET" ? <UpdatePet type={"Patients"} /> : <UpdatePatient type={"Patients"} />} />
                <Route path="appointments-history" element={<History type={"Patients"} />} />
                <Route path="shared-form" element={<PatientSharedForm type={"Patients"} />} />
                <Route path="e-prescription" element={<EprescriptionHistory type={"Patients"} />} />
                <Route path="test-report" element={<TestReport type={"Patients"} />} />
                <Route path="vaccine-calendar" element={<VaccineCalender type={"Patients"} />} />
                <Route path="vitals" element={<AdditionalDetails type={"Patients"} />} />
                <Route path="checkout-slip" element={<CheckoutAppointment type={"Patients"} />} />
                <Route path="sub-members" element={<Submember />} />
              </Route>
              <Route path="/patients/:id/sub-members/:subpatientId" element={<SubPatientDetails type={"subPatients"} />} >
                <Route path="/patients/:id/sub-members/:subpatientId" element={<Navigate replace to="details" />} />
                <Route index path="details" element={<UpdatePatient type={"subPatients"} />} />
                <Route path="detail" element={<UpdatePet type={"subPatients"} />} />
                <Route path="appointments-history" element={<History type={"subPatients"} />} />
                <Route path="shared-form" element={<PatientSharedForm type={"subPatients"} />} />
                <Route path="e-prescription" element={<EprescriptionHistory type={"subPatients"} />} />
                <Route path="test-report" element={<TestReport type={"subPatients"} />} />
                <Route path="vaccine-calendar" element={<VaccineCalender type={"subPatients"} />} />
                <Route path="checkout-slip" element={<CheckoutAppointment type={"subPatients"} />} />
                <Route path="vitals" element={<AdditionalDetails type={"subPatients"} />} />
              </Route>
              <Route path="/patients/:id/checkout-slip/:ApptId" element={<CheckoutSlip type={"Patients"} />} />
              <Route path="/patients/:id/sub-members/:subpatientId/checkout-slip/:ApptId" element={<CheckoutSlip type={"subPatients"} />} />
              <Route path="/patients/:id/vaccine-calendar/edit/:formId" element={<VaccineFormUpdate type={"Patients"} />} />
              <Route path="/patients/:id/sub-members/:subpatientId/vaccine-calendar/edit/:formId" element={<VaccineFormUpdate type={"subPatients"} />} />
              <Route path="/patients/:id/e-prescription/:prescriptionNumber" element={<EPrescription type={"Patients"} />} />
              <Route path="/patients/:id/sub-members/:subpatientId/e-prescription/:prescriptionNumber" element={<EPrescription type={"subPatients"} />} />
              <Route path="/patients/:id/vaccine-history/vaccine-calendar" element={<VaccineCalender type={"Patients"} />} />
              <Route path="/patients/:id/sub-members/:subpatientId/vaccine-history/vaccine-calendar" element={<VaccineCalender type={"subPatients"} />} />
              <Route path="/patients/:id/vaccine-history/vaccine-calendar/vaccine-list" element={<VaccineList type={"Patients"} />} />
              <Route path="/patients/:id/sub-members/:subpatientId/vaccine-history/vaccine-calendar/vaccine-list" element={<VaccineList type={"subPatients"} />} />
              {/* Vaccination  routes */}
              <Route path="/vaccination"
                element={
                  <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                    <VaccinationCategory />
                  </ProtectedRoute>
                }
              />
              <Route path="/vaccination/:id"
                element={
                  <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                    <VaccinationCalendar />
                  </ProtectedRoute>
                }
              />
              <Route path="/vaccination/:id/add-form"
                element={
                  <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                    <VaccinationForms />
                  </ProtectedRoute>
                }
              />
              <Route path="/vaccination/:id/update-form/:formId"
                element={
                  <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                    <UpdateVaccinationForm />
                  </ProtectedRoute>
                }
              />
              <Route path="/vaccination/:id/clone/:formId"
                element={
                  <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" })} >
                    <VaccinationForms />
                  </ProtectedRoute>
                }
              />
              {/* Teams  routes */}
              <Route path="/team" element={<Teams />} />
              <Route path="/team/add"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <AddTeams />
                  </ProtectedRoute>
                }
              />
              <Route path="/team/details" element={<TeamDtaeils />} >
                <Route path="/team/details" element={<Navigate replace to="profile" />} />
                <Route path="profile" element={<EditTeameProfile />} />
                <Route path="availability" element={<TeamsAvailability />} />
                <Route path="unavailability" element={<TeamsUnAvailability />} />
                <Route path="location" element={<TeamLocation />} />
              </Route>
              <Route path="/team/specialization/:id" element={<TeamSpecialization />} />
              <Route path="/user/profile" element={<EditTeameProfile />} />
              <Route path="/team/availability/set-availability"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <TeamsAddAvailability />
                  </ProtectedRoute>
                }
              />
              <Route path="/team/details/unavailability/set-unavailability"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <TeamSetUnavailability />
                  </ProtectedRoute>
                }
              />
              <Route path="/team/availability/:id"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <TeamsAvailabilityUpdate />
                  </ProtectedRoute>
                }
              />
              <Route path="/team/details/profile/add-degree"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <AddDegree type="team" />
                  </ProtectedRoute>
                }
              />
              <Route path="/team/details/profile/edit-degree/:id"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <AddDegree type="team" />
                  </ProtectedRoute>
                } />
              {/* expertise  routes */}
              <Route path="/expertise" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={null} >
                  <SecviceSEction />
                </ProtectedRoute>
              } >
                <Route path="/expertise" element={<Navigate replace to="specialization" />} />
                <Route path="specialization" element={<Specialization />} />
                <Route path="services" element={<Services />} />
              </Route>
              <Route path="/expertise/services/add" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={null} >
                  <AddServices />
                </ProtectedRoute>
              } />
              <Route path="/expertise/services/edit/:id" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={null} >
                  <EditServices />
                </ProtectedRoute>
              } />
              {/* Insights  routes */}
              <Route path="/insights" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" })} >
                  <Insights />
                </ProtectedRoute>
              } >
                <Route path="/insights" element={<Navigate replace to="bookig-details" />} />
                <Route path="bookig-details" element={<BookingDetails />} />
                <Route path="patient-report" element={<PatientReport />} />
                <Route path="staff-report" element={<TeamReport />} />
                <Route path="service-report" element={<ServiceReports />} />
                <Route path="rush-hours" element={<RushHours />} />
                <Route path="demographics" element={<Demographics />} />
                <Route path="feedback" element={<Feedback />} />
              </Route>
              {/* Notifications  routes */}
              <Route path="/notifications" element={<Notifications />} />
              {/* Offers  routes */}
              <Route path="/offers" element={
                <ProtectedRoute RolePermission={false} FeaturePermission={null} >
                  <Offers />
                </ProtectedRoute>
              } />
              <Route path="/offers/add" element={
                <ProtectedRoute RolePermission={false} FeaturePermission={null} >
                  <AddOffers />
                </ProtectedRoute>
              } />
              <Route path="/offers/:id" element={
                <ProtectedRoute RolePermission={false} FeaturePermission={null} >
                  <EditOffers />
                </ProtectedRoute>
              } />
              <Route path="/offers/view/:id" element={
                <ProtectedRoute RolePermission={false} FeaturePermission={null} >
                  <OfferView />
                </ProtectedRoute>
              } />
              {/* setting  routes */}
              <Route path="/setting" element={<Index />}>
                <Route path="/setting" element={<Navigate replace to="change-pin" />} />
                <Route index path="change-pin" element={<ChangePin />} />
                <Route path="policies" element={<Policies />} />
                <Route path="life-style" element={<LifeStyle />} />
                <Route path="notifications" element={<Notification />} />
                <Route path="roles" element={
                  <ProtectedRoute RolePermission={isProviderPermission} FeaturePermission={null} >
                    <Roles />
                  </ProtectedRoute>
                } />
                <Route path="policies" element={<Policies />} />
                <Route path="help-desk"
                  element={
                    <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "SupportTicket" })} >
                      <TicketList />
                    </ProtectedRoute>
                  }
                />
                <Route path="other" element={<Other />} />
                <Route path="my-plan"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <MyPlan />
                    </ProtectedRoute>
                  }
                />
                <Route path="payment-setting"
                  element={
                    <ProtectedRoute RolePermission={!isFrontDeskPermission} FeaturePermission={null} >
                      <PaymentSetting />
                    </ProtectedRoute>
                  }
                />
                <Route path="help-support" element={<HelpSupport />} >
                  <Route path="/setting/help-support" element={<Navigate replace to="feedback" />} />
                  <Route index path="feedback" element={<SYF />} />
                  <Route path="faq" element={<FAQ />} />
                </Route>
              </Route>
              <Route path="/setting/my-plan/history" element={<BillingHistory />}>
                <Route path="/setting/my-plan/history" element={<Navigate replace to="plan-history" />} />
                <Route path="plan-history" element={<PlanHistory />} />
                <Route path="payment-history" element={<PaymentHistory />} />
              </Route>
              <Route path="/setting/help-desk/:id"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "SupportTicket" })} >
                    <TicketDetails />
                  </ProtectedRoute>
                }
              />
              <Route path="/setting/help-desk/create-ticket"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "SupportTicket" })} >
                    <AddTicket />
                  </ProtectedRoute>
                }
              />
              {/* Profile  routes */}
              <Route path="/Profile" element={userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? <Profile /> : <TeamProfile />} />
              <Route path="/Profile/add-degree"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <AddDegree type="profile" />
                  </ProtectedRoute>}
              />
              <Route path="/Profile/edit-degree/:id"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={null} >
                    <AddDegree type="profile" />
                  </ProtectedRoute>
                } />
              <Route path="/Profile/edit" element={<EditProfile />} />
              <Route path="/profile/addClinic" element={<AddClinic />} />
              <Route path="/profile/clinic/Edit/:id" element={<EditClinic />} />
              <Route path="/profile/clinic/Edit/:id/editSpecialization" element={<AddSpecialization />} />
              <Route path="/profile/addClinic/addSpecialization" element={<AddSpecialization />} />
              <Route path="/chat"
                element={
                  <ProtectedRoute RolePermission={true} FeaturePermission={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Chat" })} >
                    <Chat />
                  </ProtectedRoute>
                }
              />
              <Route path="/template-library" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={null} >
                  <TemplateLibrary />
                </ProtectedRoute>
              } >
                <Route path="/template-library" element={<Navigate replace to="/template-library/template-list" />} />
                <Route path="/template-library/template-list" element={<TemlpateList />} />
                <Route path="/template-library/template-category" element={<TemplateCategory />} />

              </Route>
              <Route path="/template-library/template-list/create-form" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={null} >
                  <CreateTemplateForm />
                </ProtectedRoute>
              } />
              <Route path="/template-library/template-list/:id" element={
                <ProtectedRoute RolePermission={!isStaffPermission} FeaturePermission={null} >
                  <CreateTemplateForm />
                </ProtectedRoute>
              } />
            </Route>
        }
        <Route path="/*" element={<PrivateErrorPage />}></Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
