import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useReduxData from '../../Hooks/useReduxData'
import { RoleTitle } from '../../Context/constants'
import { featurePermission, } from '../../Utils/Utils';
const Index = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const splitPath = pathname.split("/")
    const ActiveTab = splitPath[splitPath.length - 1]
    const { reduxData: userData } = useReduxData("userDataRDX")
    return <>
        <div className="common-component-box">
            <header className="body_header">
                <div className="d-flex justify-content-start align-items-center">
                    <h2 className='page_name'>Settings</h2>
                </div>
            </header>
            <div className="common-white-bg-box mx-0 shadow  position-relative pt-0 ">
                <div className="col-12 pb-2  position-sticky top-0 z-3 pt-3 tab_scroll" style={{ borderBottom: "2px solid #ededed", background: "#fff" }}>
                    <ul className="nav  mb-0  pb-1 px-2 " style={{ width: "890px" }}>
                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "change-pin" && 'active_teams_frofile'}`} type="button" onClick={() => navigate(`change-pin`)}>Change Pin</button>
                        </li>
                        {
                            userData.roleTitle !== RoleTitle.STAFF && <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${splitPath[2] === "help-support" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`help-support`)} >Help & Support</button>
                            </li>
                        }

                        {
                            userData.roleTitle !== RoleTitle.STAFF && <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "life-style" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`life-style`)} >Lifestyle</button>
                            </li>
                        }
                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "notifications" && "active_teams_frofile"}`} type="button" onClick={() => navigate("notifications")} >Notifications</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "policies" && "active_teams_frofile"}`} type="button" onClick={() => navigate("policies")} >Policies</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "help-desk" && "active_teams_frofile"}`} type="button"
                                onClick={() => {
                                    if (featurePermission({ permission: userData?.planDetails?.addOnArray, userdata: userData, section: "SupportTicket" })) {
                                        navigate(`help-desk`)
                                    } else {
                                        navigate(pathname)
                                    }
                                }}
                            >Help Desk</button>
                        </li>
                        {
                            userData.roleTitle === RoleTitle.SERVICE_PROVIDER && <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "roles" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`roles`)} >Roles</button>
                            </li>
                        }

                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "other" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`other`)} >Other Settings</button>
                        </li>
                        {
                            userData.roleTitle === RoleTitle.SERVICE_PROVIDER && <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "my-plan" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`my-plan`)} >My Plan</button>
                            </li>
                        }
                        {
                            (userData?.planDetails?.planRegion && userData?.planDetails?.planRegion !== "India") && userData.roleTitle === RoleTitle.SERVICE_PROVIDER && <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "payment-setting" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`payment-setting`)} >Payment Setting</button>
                            </li>
                        }

                    </ul>
                </div>
                <div className='px-2 SETTING-OUTLET-HIEGHT'>
                    <Outlet />
                </div>
            </div>
        </div>
    </>
}

export default Index