import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { listPatient } from "../../../Context/api";
import { RoleTitle } from "../../../Context/constants";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { addSubMemberType } from "../../../Redux/ReducerFile/SubMemberType";
import { MERGE_ACCOUNT, PATIENT_LIST, PET_LIST } from "../../Repository/PatientRepo";
import { DELETE_ACCOUNT } from "../../Repository/ProfileRepo";
import { ASSIGNED_TEMPLATE_FORM, CATEGORY_LIST, TEMPLATE_FORM_LIST } from "../../Repository/TemplateLibraryRepo";
const usePatients = () => {
    const [listpatients, setListpatients] = useState([])
    const [margePatientList, MargePatientList] = useState([])
    const [petList, setPetList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [templateFomrList, setTemplateFomrList] = useState([])
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loader, setloader] = useState(false)
    const [filter, setFilter] = useState(true)
    const [searchvalue, setSerchVlaue] = useState({
        patient: "",
        personal: "PERSONAL"
    })

    const [disable, setdisable] = useState(true)
    const [tab, setTab] = useState(1)
    const [mergeAccountId, setMergeAccountId] = useState(null)
    const [mergeAccountType, setMergeAccountType] = useState("DUPLICATE")
    const [OpenModal, setOpenModal] = useState({
        deletePatient: false,
        mergeCount: false,
        mergeCountPopup1: false,
        data: null
    })
    const [openCategoryListModal, setOpenCategoryListModal] = useState({
        open: false,
        data: null
    })
    const [openTemplateListModal, setOpenTemplateListModal] = useState({
        open: false,
        data: []
    })
    const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
    const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const navigate = useNavigate()
    let { id } = useParams()
    const FileRef = useRef()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const HeadCells = [
        { id: "image", numeric: false, disablePadding: true, label: "Image" },
        { id: "firstName", numeric: true, disablePadding: false, label: "Patient", showIcon: true },
        { id: "gender", numeric: true, disablePadding: false, label: "Gender", showIcon: true },
        { id: "mobile", numeric: true, disablePadding: false, label: "Mobile Number", showIcon: true },
        { id: "email", numeric: true, disablePadding: false, label: "E-mail", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action" }
    ];
    const petheadCells = [
        { id: "image", numeric: false, disablePadding: true, label: "Image" },
        { id: "firstName", numeric: true, disablePadding: false, label: "Pet Name", showIcon: true },
        { id: "gender", numeric: true, disablePadding: false, label: "Gender", showIcon: true },
        { id: "petType", numeric: true, disablePadding: false, label: "Pet Type", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action" }
    ];
    const MerageCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#" },
        { id: "firstName", numeric: true, disablePadding: false, label: "Patient", showIcon: true },
        { id: "gender", numeric: true, disablePadding: false, label: "Gender", showIcon: true },
        { id: "mobile", numeric: true, disablePadding: false, label: "Mobile Number", showIcon: true },
        { id: "email", numeric: true, disablePadding: false, label: "E-mail", showIcon: true },
    ];
    const headCellsTemplateCategory = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
    ];
    const headCellsTemplateForm = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "name", numeric: true, disablePadding: false, label: "Form Name", isShorted: true },
        { id: "formCategoryName", numeric: true, disablePadding: false, label: "Category Name", isShorted: true },
    ];

    console.log(openCategoryListModal, "openCategoryListModal")

    useEffect(() => {
        document.title = "Patient"
        if (filter && splitLocation[1] === "patients") {
            getlistPatient()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, filter, id, patientdetails?.authId])
    useEffect(() => {
        if (filter === false) {
            getlistPet()
        }
        dispatch(addSubMemberType(filter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])
    const closeModal = () => {
        setOpenTemplateListModal(() => ({ data: [], open: false }));
        setOpenCategoryListModal(() => ({ data: null, open: false }));
    }
    // list patients
    const getlistPatient = async () => {
        let payload = {
            providerId: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata?.userId : userdata?.providerId,
            authId: id ? patientdetails?.authId : undefined,
            page: controller.page,
            count: controller.rowsPerPage,
            roleTitle: userdata?.roleTitle,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            patientFilter: (!id && userdata.roleTitle === RoleTitle.STAFF) ? searchvalue?.personal : undefined, // add filter in staff  //ALL 
            searchKeyword: searchvalue?.patient.trim() ? searchvalue?.patient.trim() : undefined,
            filter: id ? "MEMBER" : undefined,

        }
        setloader(true)
        try {
            let response = await PATIENT_LIST(payload)
            setListpatients(response.data)
            setPaginationTotalCount(response?.totalCount)
            setloader(false)
        } catch (error) {
            setloader(false)
            setListpatients([])
            setPaginationTotalCount(0)
        }
    }
    // get Merge Patient List 
    const getMergePatientList = async () => {
        let payload = {
            providerId: !id ? ((userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId) : undefined,
            authId: id ? patientdetails?.authId : undefined,
            roleTitle: userdata?.roleTitle,
        }
        setloader(true)
        try {
            let response = await PATIENT_LIST(payload)
            MargePatientList(response.data)
            setloader(false)
        } catch (error) {
            setloader(false)
            MargePatientList([])
        }
    }
    // pet list
    const getlistPet = async () => {
        let payload = {
            authId: id ? patientdetails?.authId : undefined,
            providerId: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata?.userId : userdata?.providerId,
            page: controller.page,
            count: controller.rowsPerPage,
            roleTitle: userdata?.roleTitle,
            searchKeyword: searchvalue?.patient.trim() ? searchvalue?.patient.trim() : undefined
        }
        setloader(true)
        try {
            let response = await PET_LIST(payload)
            setPetList(response.data)
            setPaginationTotalCount(response?.totalCount)
            setloader(false)
        } catch (error) {
            setloader(false)
            setPetList([])
            setPaginationTotalCount(0)
        }
    }


    async function getCategoryList() {
        setloader(true)
        const payload = {
            deletedStatus: false,
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK||userdata.roleTitle === RoleTitle.STAFF) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        };
        try {
            let response = await CATEGORY_LIST(payload)
            setCategoryList(response?.data)
            setloader(false)
        } catch (error) {
            setCategoryList([])
            setloader(false)
        }
    }

    async function getTemplateList() {
        setloader(true)
        const payload = {
            deletedStatus: false,
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK||userdata.roleTitle === RoleTitle.STAFF) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            formCategoryId: openCategoryListModal?.data?.formCategoryId,
            
        };
        try {
            let response = await TEMPLATE_FORM_LIST(payload)
            setTemplateFomrList(response?.data)
            setloader(false)
        } catch (error) {
            toast.error(error.message)
            setTemplateFomrList([])
            setloader(false)
        }
    }

    async function handleAssignForm() {
        if (openTemplateListModal?.data?.length === 0) return toast.warn('Please choose atleast 1 form')
        setloader(true)
        const payload = {
            providerId: ((userdata.roleTitle === RoleTitle.FRONT_DESK||userdata.roleTitle === RoleTitle.STAFF) ? userdata?.providerId : userdata?.userId),
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            formCategoryId: openCategoryListModal?.data?.formCategoryId,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            roleTitle: userdata.roleTitle,
            patientId: openCategoryListModal?.data?.patientId,
            formIds: openTemplateListModal?.data,
             filter: "SHARED"
        };
        try {
            let response = await ASSIGNED_TEMPLATE_FORM(payload)
            closeModal()
            toast.success(response?.message)
            setloader(false)
            if (filter === false) getlistPet()
            else getlistPatient()
        } catch (error) {
            toast.error(error.message)
            setloader(false)
        }
    }

    const handleSelectForm = (formId) => {
        setOpenTemplateListModal((prev) => {
            let temp = { ...prev };
            let newArray = temp?.data?.length > 0 ? [...temp.data] : [];
            if (newArray.includes(formId)) {
                newArray = newArray.filter((cur) => cur !== formId);
            } else {
                newArray.push(formId);
            }
            temp.data = newArray;
            return temp;
        });
    };

    //delete patient 
    const handleDeletePatient = async (event) => {
        if (event === "YES") {
            setloader(true)
            const payload = {
                patientId: OpenModal?.data?.id,
                filter: RoleTitle.PATIENT,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                roleTitle: userdata.roleTitle,
            }
            setloader(true)
            try {
                let response = await DELETE_ACCOUNT(payload)
                toast.success(response.message)
                if (listpatients?.length === 1 && controller.page === 1) {
                    if (filter === false) {
                        getlistPet()
                    } else getlistPatient()
                }
                else if (listpatients?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else {
                    if (filter === false) {
                        getlistPet()
                    } else getlistPatient()
                }
                setloader(false)
                handleClosedModal("deletePatient")
            } catch (error) {
                toast.error(error.message);
                setloader(false)
            }

        } else {
            handleClosedModal("deletePatient")
        }
    }

    const handleChooseDuplicate = async (event) => {
        if (event === "YES") {
            handleOpenModal("mergeCount", OpenModal.data)
        } else {
            handleClosedModal("mergeCountPopup1")
        }
    }
    // Merge Account

    const handleMergeAccount = async () => {
        setloader(true)
        let payload = {
            primaryAccountId: mergeAccountId,
            duplicateAccountId: OpenModal?.data?.id,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            filter: mergeAccountType
        }
        try {
            let response = await MERGE_ACCOUNT(payload)
            setloader(false)
            toast.success(response.message)
            getlistPatient()
            handleClosedModal("mergeCount")
        } catch (error) {
            setloader(false)
            toast.error(error.message)
        }
    }
    // search box 
    const handlSearchbox = (event) => {
        const { name, value } = event.target
        setSerchVlaue((pre) => ({ ...pre, [name]: value }))
        setdisable(false)
    }
    const search = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    const reset = () => {
        setdisable(true)
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setSerchVlaue({ patient: "", personal: "PERSONAL" })
    }
    const ChangeTab = (Type) => {
        setSerchVlaue({
            patient: "",
            personal: "PERSONAL"
        })
        switch (Type) {
            case "Member":
                setTab(1)
                setFilter(true)
                break;
            case "Pet":
                setTab(2)
                setFilter(false)
                break;
            default: setTab(1)
                setFilter(true)
        }
    }
    const handleOpenModal = (type, data = null) => {
        if (type === "mergeCount") {
            getMergePatientList()
        }
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        setMergeAccountType("DUPLICATE")
        setMergeAccountId(null)
        if (type === "mergeCount") {
            handleClosedModal("mergeCountPopup1")
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }


    return {
        headCellsTemplateForm,
        headCellsTemplateCategory,
        openCategoryListModal,
        templateFomrList,
        getTemplateList,
        getCategoryList,
        closeModal,
        categoryList,
        setOpenCategoryListModal,
        openTemplateListModal,
        setOpenTemplateListModal,
        handleSelectForm,
        handleAssignForm,

        id,
        headCells: !id ? HeadCells : filter ? HeadCells : petheadCells,
        loader,
        controller,
        paginationTotalCount,
        open,
        anchorEl,
        listpatients,
        filter, setFilter,
        navigate,
        handleClick,
        handleClose,
        listPatient,
        dispatch,
        setController,
        handleMergeAccount,
        petList,
        setPetList, margePatientList, mergeAccountId, setMergeAccountId, handleChooseDuplicate, mergeAccountType, setMergeAccountType,
        userdata, handlSearchbox, search, reset, searchvalue, disable, FileRef, tab, setTab, ChangeTab, handleOpenModal, handleClosedModal, OpenModal, handleDeletePatient, MerageCells
    }
}

export default usePatients



