/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { offersListForApptApi, } from '../../../Context/api';
import { createAppointmentValidation, cancelApptValidation, createAppointmentStaffValidation } from '../../../Context/Validation';
import { AppointmentStatus, RoleTitle, commonMessages } from "../../../Context/constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { axiosInstance } from "../../../Context/CraeteAxios";
import useReduxData from "../../../Hooks/useReduxData";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { useNavigate } from "react-router-dom";
import { APPOINTMENT_FILTER_LIST, APPOINTMENT_LIST, CHANGE_APPOINTMENT_STATUS, INSERT_APPOINTMENT, TIME_SLOT_LIST } from "../../Repository/AppointmentsRepo";
import { CLINIC_LIST, GET_BASIC_DETAILS } from "../../Repository/ProfileRepo";
import { PATIENT_LIST } from "../../Repository/PatientRepo";
import { SERVICE_LIST } from "../../Repository/ExpertiseRepo";
const useAppointment = () => {
    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [count, setCount] = useState(0)
    const [modalCancel, setModalCancel] = useState(false);
    const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
    const [modalReschdule, setModalReschdule] = useState(false);
    const [filtershow, setFiltershow] = useState(false)
    const [loader, setLoader] = useState(false)
    const [SlotLodaer, setSlotLodaer] = useState(false)
    const [clinicList, setClinicList] = useState([])
    const [teamList, setTeam] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [apptList, setApptList] = useState([])
    const [apptDetails, setApptDetails] = useState({})
    const [apptData, setAppData] = useState({})
    const [check, setCheck] = useState(false)
    const [patientFilterList, setPatientFilterList] = useState([])
    const [statusSelected, setStatusSelected] = useState([
        { label: "Booked", value: "BOOKED" },
        { label: "Rescheduled", value: "RESCHEDULED" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "CheckedOut", value: "CHECKOUT" },]);
    const [clinicSelected, setClinicSelected] = useState([]);
    const [teamSelected, setTeamSelected] = useState([]);
    const [patientSelected, setPatientSelected] = useState([]);
    const [serviceSelected, setServiceSelected] = useState([]);
    const [TimeslotList, setTimeSlotList] = useState([])
    const [dateFilter, setDateFilter] = useState("")
    const [isActiveSlot, setIsactiveSlot] = useState(null)
    const [date, setDate] = useState({ startDate: '', endDate: '', })
    const [clearAllAction, setAclearAllAction] = useState(false)
    const [offersList, setOffersList] = useState([])
    const [submitAppointment, setSubmitAppointment] = useState(false)
    const [appointmentFilterData, setAppointmentFilterData] = useState({
        clinicList: [],
        patientList: [],
        serviceList: [],
        staffList: [],
    })
    const curDateTime = moment(new Date()).utc()
    const headCells = [
        { id: "appointmentNumber", numeric: false, disablePadding: true, label: "Appointment Number", showIcon: true },
        { id: "startDate", numeric: false, disablePadding: true, label: "Appointment Date", showIcon: true },
        { id: "Time", numeric: true, disablePadding: false, label: "Appointment Time" },
        { id: "patientFirstName", numeric: true, disablePadding: false, label: "Patient Name", showIcon: true },
        { id: "specialisationName", numeric: true, disablePadding: false, label: "Specialization", showIcon: true },
        { id: "serviceName", numeric: true, disablePadding: false, label: "Services", showIcon: true },
        { id: "Status", numeric: true, disablePadding: false, label: "Status", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const ITEM_HEIGHT = 48;
    const { CustomButton } = useMuiCompontent()
    const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
    const navigate = useNavigate()
    // appointment list 
    const listAppointment = async () => {
        const payload = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            clinics: userdata?.location?.clinicId ? [userdata?.location?.clinicId] : [],
            teams: teamSelected?.map((item) => item.userId),
            patients: patientSelected?.map((item) => item.id),
            services: serviceSelected?.map((item) => item.serviceId),
            status: statusSelected?.map((item) => item.value),
            roleTitle: userdata.roleTitle,
            page: controller.page,
            count: controller.rowsPerPage
        }
        if (dateFilter === 'TODAY') {
            payload.startDate = moment().startOf('day')
            payload.endDate = moment().endOf('day')
        } else if (dateFilter === 'LAST7') {
            payload.startDate = moment().subtract(7, 'days').startOf('day')
            payload.endDate = moment().endOf('day')
        } else if (dateFilter === 'LAST30') {
            payload.startDate = moment().subtract(30, 'days').startOf('day')
            payload.endDate = moment().endOf('day')
        } else {
            // payload.startDate = moment().startOf('day')
            // payload.endDate = moment().add(1, 'year')

            payload.startDate = moment().startOf('day')
            payload.endDate = null
        }
        if (dateFilter === 'CUSTOM' && date.startDate === '' && date.endDate === '') return toast.warn('Please select start date and end date ')
        if (dateFilter === 'CUSTOM' && date.startDate === '') return toast.warn('Please select start date')
        if (dateFilter === 'CUSTOM' && date.endDate === '') return toast.warn('Please select end date')
        if (date.startDate > date.endDate) return toast.warn('Start date can not be greater than end date ')
        else if (dateFilter === 'CUSTOM') {
            payload.startDate = moment(date.startDate)
            payload.endDate = moment(date.endDate).endOf('day')
        }
        setLoader(true)
        try {
            let response = await APPOINTMENT_LIST(payload)
            setApptList(response?.data)
            setCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setApptList([])
            setCount(0)
            setLoader(false)
        }
    }
    // appointment filter list servic clinic patient staff
    const AppointmentFilters = async () => {
        setLoader(true)
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            roleTitle: userdata.roleTitle
        }
        try {
            let response = await APPOINTMENT_FILTER_LIST(payload)
            setAppointmentFilterData({
                clinicList: response?.data?.clinicList,
                patientList: response?.data?.patientList,
                serviceList: response?.data?.serviceList,
                staffList: response?.data?.staffList,
            })
            setLoader(false)
        } catch (error) {
            setAppointmentFilterData({})
            setLoader(false)
        }
    }
    // appoinment cancel
    const cancelAppointment = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: cancelApptValidation,
        onSubmit: async (values) => {
            setLoader(true)
            const payload = {
                changeType: AppointmentStatus.CANCELLED,
                cancellationReason: values.reason,
                roleTitle: userdata.roleTitle,
                appointmentId: apptData?.appointmentId,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            }
            try {
                let response = await CHANGE_APPOINTMENT_STATUS(payload)
                cancelAppointment.resetForm()
                toast.success(response.message)
                setModalCancel(false)
                listAppointment()
                setAppData({})
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    // patient list
    const getlistPatient = async () => {
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            patientFilter: "ALL",
            roleTitle: userdata?.roleTitle,
        }
        setLoader(true)
        try {
            let response = await PATIENT_LIST(payload)
            setPatientFilterList(response.data)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            setPatientFilterList([])
        }
    }
    //clinic list
    const getListproviderclinics = async () => {
        let payload = {
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            roleTitle: userdata.roleTitle,
            activeStatus: true,
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            setLoader(false)
        } catch (error) {
            setClinicList([])
            toast.error(error?.message)
            setLoader(false)
        }
    }
    // staff list
    const getliststaff = async (clinicId, serviceId) => {
        setLoader(true)
        const payload = {
            getStaffList: true,
            activeStatus: true,
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            clinicId,
            serviceId,
            invitationStatus: "ACCEPTED"
        };
        try {
            let response = await GET_BASIC_DETAILS(payload)
            setTeam(response.data)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            setTeam([])
        }
    }
    // service list
    const getservicesList = async (clinicId) => {
        setLoader(true)
        const payload = {
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            roleTitle: userdata.roleTitle === RoleTitle.STAFF ? RoleTitle.STAFF : undefined,
            clinicId: clinicId
        };

        try {
            let response = await SERVICE_LIST(payload)
            setServicesList(response?.data?.map((cur) => ({ ...cur, specializationName: cur?.specialization?.specializationName })))
            setLoader(false)
        } catch (error) {
            setServicesList([])
            setLoader(false)
        }

    }
    // appointment create
    const createAppointment = useFormik({
        initialValues: {
            patientName: [],
            service: [],
            clinic: [],
            date: '',
            startTime: '',
            endTime: '',
            teamMember: [],
            visitType: '',
            couponCode: "",
        },
        validationSchema: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? createAppointmentValidation : createAppointmentStaffValidation,
        onSubmit: async (values) => {
            if (values.patientName?.length > SERVICES_DETAILS?.noOfPatients) {
                return toast.warn(`You can select ${SERVICES_DETAILS?.noOfPatients} patient `)
            }
            if (!values.startTime) {
                return toast.warn(`Please select time slot`)
            }
            const data = {
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                staffId: (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? Staff_DETAILS?.userId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                clinicId: userdata?.location?.clinicId,
                serviceDetails: values.service[0],
                visitType: values.visitType,
                clinicName: userdata?.location?.clinicName,
                startDate: values.startTime,
                endDate: values.endTime,
                status: check ? AppointmentStatus.WAITING : AppointmentStatus.BOOKED,
                currrentDateTime: curDateTime,
                totalPrice: OFERS_DETAILS?.discountPrice || SERVICES_DETAILS?.fixedPrice,
                roleTitle: userdata.roleTitle,
                timezoneName: userdata?.timezoneName,
                offerId: OFERS_DETAILS?.offerId,
                patientDetails: values?.patientName?.map((cur) => ({ patientId: cur?.id, firstName: cur?.firstName, lastName: cur?.lastName })),
            }
            setLoader(true)
            try {
                let response = await INSERT_APPOINTMENT(data)
                toast.success(response.message)
                createAppointment.resetForm()
                setTimeSlotList([])
                setBookAppointmentModal(false)
                listAppointment()
                setIsactiveSlot(null)
                createAppointment.resetForm()
                setCheck(false)
                setOffersList([])
                setSubmitAppointment(true)
                setLoader(false)
            } catch (error) {
                toast.error(error?.message)
                setLoader(false)
            }
        }

    })
    // all details of selectd offers patient services staff clinic
    const OFERS_DETAILS = offersList?.find((cur,) => cur?.discountPrice && cur?.discountPrice)
    const PATIENT_DETAILS = createAppointment.values?.patientName[0]
    const SERVICES_DETAILS = createAppointment.values?.service[0]
    const Staff_DETAILS = createAppointment.values.teamMember[0]
    // this is funcation for set Field Value in reshedule all inputs
    const reschedulefun = (apptDetailsData) => {
        setModalReschdule(apptDetailsData?.Status === AppointmentStatus.CANCELLED || apptDetailsData?.Status === AppointmentStatus.RESCHEDULED || apptDetailsData?.Status === AppointmentStatus.CHECKEDOUT ? false : true)
        if ((apptDetailsData?.Status === AppointmentStatus.CANCELLED || apptDetailsData?.Status === AppointmentStatus.RESCHEDULED || apptDetailsData?.Status === AppointmentStatus.CHECKEDOUT) === false) {
            appointmentReschedule?.setFieldValue("clinicId", apptDetailsData.clinicId)
            appointmentReschedule?.setFieldValue("serviceId", apptDetailsData.serviceId)
            appointmentReschedule?.setFieldValue("apptDate", apptDetailsData.startDate)
            // appointmentReschedule?.setFieldValue("apptTimeIn", apptDetails.startDate)
            // appointmentReschedule?.setFieldValue("apptDate", apptDetailsData.startDate)
            appointmentReschedule?.setFieldValue("teamMemberId", apptDetailsData.teamMemberId)
            appointmentReschedule?.setFieldValue("visitType", apptDetailsData.visitType)
        }
    }
    // offers list
    const getOffersList = () => {
        let data = {
            userId: userdata.userId,
            serviceId: SERVICES_DETAILS?.serviceId,
            noOfPatients: SERVICES_DETAILS?.noOfPatients,
            patientAge: SERVICES_DETAILS?.noOfPatients === 1 ? PATIENT_DETAILS?.patientage : null,
            patientGender: SERVICES_DETAILS?.noOfPatients === 1 ? PATIENT_DETAILS?.patientGender : null,
            appointmentDate: new Date(createAppointment.values.date)
        }
        axiosInstance.post(offersListForApptApi(), { ...data }).then((res) => {
            if (res.data.status === "200") {
                setOffersList(res?.data?.data)
            } else if (res.data.status === "500") {
                toast.error(commonMessages.NetworkError);
            } else {
                setOffersList([])
            }
        }).catch(() => {
            toast.error(commonMessages.NetworkError)
        })
    }
    //appointment Reschedule
    const appointmentReschedule = useFormik({
        initialValues: {
            serviceId: '',
            clinicId: '',
            teamMemberId: '',
            apptDate: '',
            apptTimeIn: '',
            apptTimeOut: '',
            visitType: '',
        },
        validationSchema: '',
        onSubmit: async (values) => {
            if (!values.apptTimeIn) {
                return toast.warn(`Please select time slot`)
            }
            const data = {
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                startDate: values.apptTimeIn,
                endDate: values.apptTimeOut,
                appointmentId: apptDetails.appointmentId,
                changeType: AppointmentStatus?.RESCHEDULED,
                roleTitle: userdata.roleTitle,
            }
            setLoader(true)
            try {
                let response = await CHANGE_APPOINTMENT_STATUS(data)
                toast.success(response.message)
                listAppointment()
                setModalReschdule(false)
                appointmentReschedule.resetForm()
                setTimeSlotList([])
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }

    })
    // apply offer
    const ApplyOffer = (data) => {
        const temp = [...offersList]
        let offersIndex = temp.findIndex(x => x.offerId === data?.offerId);
        if (data?.offerType === "FLAT") {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than  ${data?.minimumCartValue}`)
            } else {
                temp.forEach((item, itemindex) => {
                    if (item.offerId === data?.offerId) {
                        item.discountPrice = data?.fixedPrice - data?.discountAmount
                    } else {
                        item.discountPrice = null
                    }
                })
                toast.success(`Apply offer successful`)
            }
        } else {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than ${data?.minimumCartValue}`)
            } else {
                temp[offersIndex].discountPrice = data?.fixedPrice - data?.discountAmount
                let price = ((data?.fixedPrice * data?.percentage) / 100).toFixed(2);
                let result = data?.fixedPrice - price
                let newPrice = result.toFixed(2)
                if (newPrice > data?.maxDiscountUpto) {
                    temp.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = data?.maxDiscountUpto
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                } else {
                    temp.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = newPrice
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                }
            }
        }
        setOffersList(temp)
    }
    // apply offer when enter coupon code in input   
    const applyByCouponCodeOffers = () => {
        const offersData = [...offersList]
        let index = offersData.findIndex(x => x.couponCode === createAppointment?.values?.couponCode);
        if (index === -1) {
            return toast.warn("your coupon code in not matching")
        }
        let data = offersData.find(x => x.couponCode === createAppointment?.values?.couponCode);
        if (data.discountPrice) {
            return toast.warn("your coupon already apply")
        }
        if (data?.offerType === "FLAT") {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than  ${data?.minimumCartValue}`)
            } else {
                offersData.forEach((item, itemindex) => {
                    if (item.offerId === data?.offerId) {
                        item.discountPrice = data?.fixedPrice - data?.discountAmount
                    } else {
                        item.discountPrice = null
                    }
                })
                toast.success(`Apply offer successful`)
                createAppointment?.setFieldValue("couponCode", "")
            }
        } else {
            if (data?.minimumCartValue > data?.fixedPrice) {
                return toast.warn(`Service price must be greater than ${data?.minimumCartValue}`)
            } else {
                offersData[index].discountPrice = data?.fixedPrice - data?.discountAmount
                let price = ((data?.fixedPrice * data?.percentage) / 100).toFixed(2);
                let result = data?.fixedPrice - price
                let newPrice = result.toFixed(2)
                if (newPrice > data?.maxDiscountUpto) {
                    offersData.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = data?.maxDiscountUpto
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                    createAppointment?.setFieldValue("couponCode", "")
                } else {
                    offersData.forEach((item, itemindex) => {
                        if (item.offerId === data?.offerId) {
                            item.discountPrice = newPrice
                        } else {
                            item.discountPrice = null
                        }
                    })
                    toast.success(`Apply offer successful`)
                    createAppointment?.setFieldValue("couponCode", "")
                }
            }
        }
        setOffersList(offersData)
    }
    //  cancel offers
    const cancelOffer = (data) => {
        const temp2 = [...offersList]
        let offersIndex2 = temp2.findIndex(x => x.offerId === data?.offerId);
        temp2[offersIndex2].discountPrice = null
        setOffersList(temp2)
        toast.success(`Cancel offer successful`)
    }
    // filter all  clear
    const clearAll = () => {
        setClinicSelected([])
        setStatusSelected([
            { label: "Booked", value: "BOOKED" },
            { label: "Cancelled", value: "CANCELLED" },
            { label: "Rescheduled", value: "RESCHEDULED" },
            { label: "CheckedOut", value: "CHECKOUT" },
        ])
        setTeamSelected([])
        setPatientSelected([])
        setServiceSelected([])
        setDateFilter("")
        setAclearAllAction((pre) => !pre)
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    // slot list
    const getSlotfun = async (date, teamMember, clinic, serviceId) => {
        if (clinic && serviceId && (teamMember || userdata.userId) && date) {
            // const data = {
            //     staffId: userdata?.roleTitle === "STAFF" ? userdata.userId : teamMember,
            //     serviceId: serviceId,
            //     date: new Date(date),
            //     // date: moment(date).startOf("day").utc(),
            //     timezoneName: userdata?.timezoneName,
            //     day: moment(date).format('dddd'),
            //     clinicId: clinic,
            // }
            // setSlotLodaer(true)
            // setTimeSlotList([])
            // axiosInstance.post(getTimeSlot(), data).then((res) => {
            //     if (res.data.statusCode === "200") {
            //         setTimeSlotList(res.data.data)
            //     } else if (res.data.status === "500") {
            //         toast.error(commonMessages.NetworkError);
            //     } else {
            //         setTimeSlotList([])
            //     }
            //     setSlotLodaer(false)
            // }).catch(() => {
            //     setSlotLodaer(false)
            //     setTimeSlotList([])
            //     toast.error(commonMessages.NetworkError);
            // })
            let payload = {
                staffId: userdata?.roleTitle === RoleTitle.STAFF ? userdata.userId : teamMember,
                serviceId: serviceId,
                date: moment.tz(date, userdata.timezoneName).startOf('day'),
                // date: moment(date).startOf("day").utc(),
                timezoneName: userdata?.timezoneName,
                day: moment(date).format('dddd'),
                clinicId: clinic,
            }
            setSlotLodaer(true)
            setTimeSlotList([])
            try {
                let response = await TIME_SLOT_LIST(payload)
                setTimeSlotList(response.data)
                setSlotLodaer(false)
            } catch (error) {
                setTimeSlotList([])
                setSlotLodaer(false)
            }
        }
    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "Clinic_filter") setClinicSelected(newValue)
        if (name === "Staff_filter") setTeamSelected(newValue)
        if (name === "Patient_filter") setPatientSelected(newValue)
        if (name === "Services_filter") setServiceSelected(newValue)
        if (name === "Status_filter") setStatusSelected(newValue)
        if (name === "service") {
            createAppointment.setFieldValue('teamMember', []);
            createAppointment.setFieldValue('date', "");
            createAppointment.setFieldValue('service', newValue);
            getliststaff(userdata?.location?.clinicId, newValue?.[0]?.serviceId)
        }
        if (name === "patient") createAppointment.setFieldValue('patientName', newValue)
        if (name === "team") {
            createAppointment.setFieldValue('date', "");
            createAppointment.setFieldValue('teamMember', newValue);
        }
    }
    const clossOffcanvas = (type) => {
        if (type === "BOOK_APPOINTMENT") {
            setBookAppointmentModal(false)
            setIsactiveSlot(null)
            createAppointment.resetForm()
            setCheck(false)
            setOffersList([])
            setTimeSlotList([])
        }
        if (type === "RESCHEDULE_APPOINTMENT") {
            setModalReschdule(false)
            setTimeSlotList([])
        }
        if (type === "CANCEL_APPOINTMENT") {
            setModalCancel(false)
            cancelAppointment.resetForm()
        }

    }
    useEffect(() => {
        if (createAppointment.values.date) {
            getOffersList()
            getSlotfun(createAppointment.values.date, Staff_DETAILS?.userId, userdata?.location?.clinicId, SERVICES_DETAILS?.serviceId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAppointment.values.date])
    useEffect(() => {
        if (appointmentReschedule.values.apptDate && modalReschdule) {
            getSlotfun(appointmentReschedule.values.apptDate, appointmentReschedule.values.teamMemberId, userdata?.location?.clinicId, appointmentReschedule.values.serviceId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentReschedule.values.apptDate, modalReschdule])
    useEffect(() => {
        listAppointment()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, clearAllAction])
    useEffect(() => {
        if (filtershow == true) AppointmentFilters()
        window.addEventListener("click", () => setDateRangeDropdown(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtershow])
    return {
        getlistPatient,
        getListproviderclinics,
        setFiltershow,
        dispatch,
        setAppData,
        setModalCancel,
        reschedulefun,
        setApptDetails,
        setController,
        setBookAppointmentModal,
        setIsactiveSlot,
        setCheck,
        ApplyOffer,
        navigate,
        setTimeSlotList,
        applyByCouponCodeOffers,
        cancelOffer,
        setModalReschdule,
        setDateFilter,
        setDateRangeDropdown,
        setDate,
        clearAll,
        HandleSearchList,
        listAppointment,
        handalSearchAbleSelectBox,
        clossOffcanvas,
        setSubmitAppointment,
        getservicesList,
        ITEM_HEIGHT,
        Staff_DETAILS,
        CustomButton,
        filtershow,
        apptList,
        loader,
        userdata,
        count,
        controller,
        modalCancel,
        cancelAppointment,
        bookAppointmentModal,
        createAppointment,
        clinicList,
        servicesList,
        teamList,
        TimeslotList,
        SlotLodaer,
        isActiveSlot,
        OFERS_DETAILS,
        SERVICES_DETAILS,
        check,
        appointmentReschedule,
        headCells,
        offersList,
        modalReschdule,
        apptDetails,
        dateFilter,
        dateRangeDropdown,
        date,
        clinicSelected,
        teamSelected,
        patientSelected,
        appointmentFilterData,
        serviceSelected,
        statusSelected,
        patientFilterList,
        submitAppointment,

    }
}

export default useAppointment
