import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import { TableContainer } from '@mui/material';
import { Peginationcomponent } from '../../Component/Peginationcomponent';
import Loading from '../../Component/Loading';
import useTableSorting from '../../Hooks/useTableSorting';
import useReduxData from '../../Hooks/useReduxData';
import { NoRecordFound } from '../../Component/NoRecordFound';
import { APPOINTMENT_HISTORY } from '../Repository/PatientRepo';
import { RoleTitle } from '../../Context/constants';
const History = ({ type }) => {
    const [appointmentData, setAppointmentData] = useState([])
    const [count, setCount] = useState(0)
    const [apptDetails, setApptDetails] = useState(null)
    const [modalView, setModalView] = useState(false);
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const { id, subpatientId } = useParams()
    const [loader, setloader] = useState(false)
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    useEffect(() => {
        appointments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    const appointments = async () => {
        let payload = {
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
            frontdeskId: userData.roleTitle === RoleTitle.FRONT_DESK ? userData?.userId : undefined,
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            page: controller.page,
            roleTitle: userData.roleTitle,
            count: controller.rowsPerPage
        }
        setloader(true)
        try {
            let response = await APPOINTMENT_HISTORY(payload)
            setAppointmentData(response?.data?.map((cur) => {
                return { ...cur, doctorName: `${cur?.doctor} ${cur?.doctorLastName}` }
            }))
            setloader(false)
            setCount(response.totalCount)
        } catch (error) {
            setAppointmentData([])
            setloader(false)
            setCount(0)
        }

    }
    const apptView = (data) => {
        setModalView(true)
        setApptDetails(data)
    }
    const headCells = [
        { id: "apptNumber", numeric: false, disablePadding: true, label: "Appointment Number", showIcon: true },
        { id: "apptStartDate", numeric: false, disablePadding: true, label: "Date", showIcon: true },
        { id: "time", numeric: true, disablePadding: false, label: "Time" },
        { id: "doctorName", numeric: true, disablePadding: false, label: "Doctor", showIcon: true },
        { id: "visitType", numeric: true, disablePadding: false, label: "Visit Type", showIcon: true },
        { id: "Status", numeric: true, disablePadding: false, label: "Status", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between " style={{ height: "100%" }}>
                <div>
                    {/* <header className="body_header mb-0" >
                        <div className="d-flex justify-content-end align-items-center">
                            <div>
                                <ul className="headerlist">
                                    <li>
                                        <div className="dropdown-center">
                                            <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <CustomButton variant="contained" className='mt-2'     startIcon={<IoFilterSharp />} style={{ background: "#f5f5f5", color: "black" }}  >Filters</CustomButton>
                                            </div>
                                            <ul className="dropdown-menu dropdown-menu-end shadow">
                                                <li><button className="dropdown-item " style={{ cursor: "pointer" }} onClick={() => setStatus('')}>All</button></li>
                                                <li><button className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setStatus('Booked')}>Booked Appointments</button></li>
                                                <li><button className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setStatus('Cancelled')}>Cancelled Appointments</button></li>
                                                <li><button className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setStatus('Rescheduled')}>Rescheduled  Appointments</button></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </header> */}
                    <TableContainer >
                        {appointmentData?.length === 0 &&
                            <div className="col-sm-12 mt-3 text-center " >
                                <NoRecordFound />
                            </div>
                        }
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                appointmentData.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={appointmentData.length} />
                            }
                            <TableBody>

                                {
                                    stableSort(appointmentData, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow hover id="hover_tr" >
                                                <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                    {row?.apptNumber}
                                                </TableCell>
                                                <TableCell id={labelId}  >
                                                    {moment(row.startDate).tz(userData?.timezoneName).format('ll')}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{moment(row.startDate).tz(userData?.timezoneName).format('LT')} - {moment(row.endDate).tz(userData?.timezoneName).format('LT')}</TableCell>
                                                <TableCell id={labelId} align="left">{row.doctorName}</TableCell>
                                                <TableCell id={labelId} align="left">{row.visitType}</TableCell>
                                                <TableCell id={labelId} align="left">{row.Status}</TableCell>
                                                <TableCell id={labelId} align="right" className=''>
                                                    <i className="fa-solid fa-eye me-2 all_icons_color" onClick={() => { apptView(row); }}></i>
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='w-100 3'>
                    {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
                </div>
            </div>

            {/* ModalView  */}
            <Modal show={modalView} onHide={() => { setModalView(false); setApptDetails(null) }} centered>
                <Modal.Body>
                    <div className="modal-body viewdetail">
                        <div className="text-center">
                            <h4 className="modal-title mb-3" >Appointment Summary</h4>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label className="form-label mb-0">Appointment Number</label>
                                <p>{apptDetails?.apptNumber}</p>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label mb-0">Clinic</label>
                                <p>{apptDetails?.clinicName}</p>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label mb-0">Service Name</label>
                                <p>{apptDetails?.serviceName}</p>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label mb-0">Patient Name</label>
                                <p>{apptDetails?.patientName}</p>
                            </div>

                            <div className="col-sm-6">
                                <label className="form-label mb-0">Doctor</label>
                                <p>{apptDetails?.doctorName}</p>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label mb-0">Appointment Date</label>
                                <p>{moment(apptDetails?.startDate).tz(userData?.timezoneName).format('ll')}</p>
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label mb-0">Appointment Time</label>
                                <p>{moment(apptDetails?.startDate).tz(userData?.timezoneName).format('LT')} - {moment(apptDetails?.endDate).tz(userData?.timezoneName).format('LT')}</p>
                            </div>

                            <div className="col-sm-6">
                                <label className="form-label mb-0">Status</label>
                                <p>{apptDetails?.Status}</p>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default History
